import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/ds/get_all_data_sources/';

function getConnectedDataSources(handler) {
  // store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    // const objectTypeData = await response.json();
    // store.commit('set_full_screen_loading', false);
    if (response.status == 200) {
      handler(await response.json());
    }
  }).catch((errData) => {
    // handler(false);
  });
}

export default getConnectedDataSources;
