<template>
  <div class="file-struct-editor flex-column">
    <ButtonComponent  label="Режим удаления"
                        elementType="default-bg"
                        labelType="button/small/medium 14"
                        @click_to_button="this.enterDeleteMode"/>

      <div class="flex-row">
        <InputField ref="upload_files"
                    type="file"
                    :is_all_time_open="true"
                    label_text="Загружаемые файлы"
                    @input_change="this.fileFieldChanged"/>

        <ButtonComponent  label="Добавить файл"
                          :elementStatusModifier="!this.sendFile ? 'disabled' : undefined"
                          elementType="default-bg"
                          labelType="button/small/medium 14"
                          @click_to_button="this.sendFiles"/>
      </div>

      <div class="flex-row">
        <InputField ref="new_folder_name"
                    type="text"
                    set_values="Новая папка"
                    :is_all_time_open="true"
                    label_text="Наименование папки"/>

        <ButtonComponent  label="Добавить папку"
                          elementType="default-bg"
                          labelType="button/small/medium 14"
                          @click_to_button="this.createFolder"/>
      </div>
  </div>
</template>

<script type="text/javascript">
import InputField from '@/units/forms/InputField.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';


import uploadFile from '@/assets/dataSources/fileStorage/uploadFile.js';
import uploadFolder from '@/assets/dataSources/fileStorage/uploadFolder.js';


export default {
  components: {
    InputField,
    ButtonComponent,
  },
  data: () => ({
    sendFile: null,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    sendFiles() {
      const uploadFolder = this.curPath;

      uploadFile(this.curPageUid, uploadFolder, this.sendFile, () => this.$emit('result', 'upd_struct'));
    },
    fileFieldChanged() {
      let newFiles = this.$refs.upload_files;

      if (!newFiles) {
        return undefined;
      }

      newFiles = newFiles.get_files();
      this.sendFile = newFiles[0];
    },
    createFolder() {
      const newFolderName = this.curPath.length > 0 ? this.curPath + '/' + this.$refs.new_folder_name.data_values : this.$refs.new_folder_name.data_values;

      uploadFolder(this.curPageUid, newFolderName, () => this.$emit('result', 'upd_struct'));
    },
    enterDeleteMode() {
      this.$emit('result', 'delete_mode');
    },
  },
  computed: {
    curPath() {
      return this.params.curPath;
    },
    curPageUid() {
      return this.$store.state.current_page_info.uid;
    },
  },
};
</script>
