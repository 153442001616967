function defaultAnimator(timeFraction) {
  const y0 = 0;
  const y1 = 1.2;
  const y2 = 1;
  const y3 = 1;
  return (y0 * Math.pow(1 - timeFraction, 3)) +
      (3 * y1 * timeFraction * Math.pow(1 - timeFraction, 2)) +
      (3 * y2 * Math.pow(timeFraction, 2) * (1 - timeFraction)) +
      (y3 * Math.pow(timeFraction, 3));
}

function animate(startVal, targetVal, handler, duration=400, getOnlyTiming=false) {
  const startTime = performance.now();

  function internalAnimate(time) {
    let timeFraction = (time - startTime) / duration;
    if (timeFraction < 0) timeFraction = 0;
    if (timeFraction > 1) timeFraction = 1;

    const progress = defaultAnimator(timeFraction);

    if (getOnlyTiming) handler(progress);
    else handler(startVal + ((targetVal - startVal) * progress));


    if (timeFraction < 1) requestAnimationFrame(internalAnimate);
    if (timeFraction === 1) handler(getOnlyTiming ? 1 : targetVal);
  }

  requestAnimationFrame(internalAnimate);
}

export default animate;
