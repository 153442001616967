<template>
  <div>
    <div class="report-form-updater" v-if="!this.isPreloading" @click="this.reloadReport(true)">
      <ButtonComponent elementType="hover"
                            elementStatus="selected"
                            iconUrl="/icons/system/arrow-counter-clockwise.svg"
                            iconSize="22"/>
      <LabelComponent label_text="Обновить фильтры" :is_no_accent="true"/>
    </div>
    <SpinnerComponent v-else style="width: 50px;" :width="5" />
    <LabelComponent v-if="this.formRequestError != null" :label_text="this.formRequestError" style="color: red;"/>

    <ReportPainter
                :inputsDisabled="this.isReportDataRequesting"
                :report_form="this.curReportForm"
                :raw_report_data="this.curReportData"
                :ignore_first_diagram="this.ignoreFirstDiagram"
                @reportFormFilled="this.requestReportData"
                />

    <LabelComponent v-if="this.dataRequestError != null" :label_text="this.dataRequestError" style="color: red;"/>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';

import getReportForm from '@/assets/dataSources/reports/getReportForm.js';
import buildReport from '@/assets/dataSources/reports/buildReport.js';

import ReportPainter from './ReportPainter';

export default {
  components: {
    LabelComponent,
    ReportPainter,
    SpinnerComponent,
    ButtonComponent,
  },
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        return;
      }

      this.updateReportPath();
      this.reloadReport();
    },
    // curReportForm() {
    //   if (this.curReportForm == null) {
    //     this.pageNameChanger('Отчет');
    //     return;
    //   }

    //   this.pageNameChanger(
    //       'Отчет: ' + this.curReportForm.name,
    //   );
    // },
    // curSourceRawInfo() {
    //   if (this.curSourceRawInfo == null) return;
    //   this.pageNameChanger('Отчет');
    // },
  },
  data: ()=> ({
    formRequestError: null,
    dataRequestError: null,
    requestVersion: 0,
    isPreloading: false,
    isReportDataRequesting: false,
    // curSourceRawInfo: null,
    // curSourceUid: null,
    // curReportUid: null,
    curPageUid: null,
    curReportForm: null,
    curReportData: null,
  }),
  props: {
    pageNameChanger: null,
  },
  mounted() {
    this.updateReportPath();
    this.reloadReport();
  },
  computed: {
    ignoreFirstDiagram() {
      return this.$store.state.current_page_info?.enable_diagram_preview == true;
    },
  },
  methods: {
    updateReportPath() {
      this.curPageUid = this.$store.state.current_page_info.uid;
    },
    reloadReport(force=false) {
      this.formRequestError = null;
      this.dataRequestError = null;
      this.isReportDataRequesting = false;
      this.curReportForm = null;
      this.curReportData = null;

      this.curSourceRawInfo = null;

      this.requestVersion += 1;
      this.isPreloading = true;

      const requestReportFormVersion = this.requestVersion;

      getReportForm(this.curPageUid, force, (result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        this.isPreloading = false;

        if (result._error == true) {
          if (result.status == null) this.formRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.formRequestError = `${result.text} Статус: ${result.status}`;
          return;
        }

        this.curReportForm = result;

        if (!force) this.requestReportData({}, false);
      });
    },
    requestReportData(formData, isForce=true) {
      if (this.isReportDataRequesting) return;

      this.dataRequestError = null;

      this.curReportData = null;
      this.isReportDataRequesting = true;

      this.requestVersion += 1;
      const requestReportFormVersion = this.requestVersion;

      if (isForce) formData['__force_update_preview_cache'] = true;

      buildReport(this.curPageUid, formData, (result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        this.isReportDataRequesting = false;

        if (result._error == true) {
          if (result.status == null) {
            this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else {
            this.dataRequestError = `${result.text} Статус: ${result.status}`;
          }
          return;
        }

        this.curReportData = result;
      });
    },
  },
};
</script>

<style lang="less">

.report-form-updater {
  padding: 13px;
  padding-bottom: 0;

  .flex(row, flex-start, center);
}

</style>
