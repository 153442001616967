<template>
    <div>
      <div v-for="(item, key) in this.normalisedLevelData" :key="key">
        <div class="nav-item" @click="this.elemSelected(item)">
            <div class="nav-item-content" :style="{'margin-left': this.currentDepth*17+'px'}">
              <IconComponent size=24 :url="item.icon_url" color="black"/>
              <LabelComponent class="nav-item-text"
                              label_type="caption/small/medium 10"
                              :label_text="item.name" />
            </div>
        </div>
        <NavElemNode :levelData="item.children"
                     :currentDepth="this.currentDepth+1"
                     :exclude_uid_list="this.exclude_uid_list"
                     @select_elem="this.elemSelected"
                  />
      </div>
    </div>
</template>

<script type="text/javascript">
import IconComponent from '@/units/RichIcon.vue';
import LabelComponent from '@/units/RichLabel.vue';

export default {
  name: 'NavElemNode',
  components: {
    LabelComponent,
    IconComponent,
  },
  emits: [
    'select_elem',
  ],
  data: () => ({

  }),
  props: {
    levelData: {default: undefined},
    currentDepth: {default: 0},
    exclude_uid_list: {
      default: [],
      required: false,
    },
  },
  computed: {
    normalisedLevelData() {
      return this.levelData ? this.levelData.filter((item) => (
        !item.internal_page &&
        item.page_type == 'group' &&
        !this.exclude_uid_list.includes(item.uid)
      )) : [];
    },
  },
  methods: {
    elemSelected(elemUid) {
      this.$emit('select_elem', elemUid);
    },
  },
};
</script>

<style lang="less">

.nav-item {

  &:hover {
    background-color: @green-opacity-30;
    cursor: pointer;
  }

  &-content {
    .flex(row, flex-start, center);
    padding: 10px 10px 10px 7px;
    color: @dark;
    .set_icon_color(black);
  }

  &-text {
    margin-left: 14px;
  }
}

</style>
