<template>
    <div style="max-width: 500px;">
      <LabelComponent label_type = "h3" :label_text = "`Для получения данных требуется ввести логин и пароль от системы ${this.params.extSystemView}`"/>

      <br />

      <LabelComponent label_text = "Внимание! В соответствии с политикой безопасности, введенные данные не хранятся на серверах Системы! Подробнее о механизмах безопасности читайте ЗДЕСЬ."/>

      <br />

      <template v-if="this.errorText">
        <LabelComponent :label_text = "this.errorText" style="color: red;"/>

        <br />
      </template>

      <form class="ext-auth-modal-input-form">
        <InputField
                @input_change="(data) => this.setLogin(data)"
                name="username"
                autocomplete="on"
                label_text="Логин"/>

        <br />

        <InputField
                @input_change="(data) => this.setPassword(data)"
                set_values=""
                name="password"
                autocomplete="on"
                label_text="Пароль"
                type="password"/>
      </form>

      <br />

      <div class="flex-row">
        <ButtonComponent label="Авторизоваться" @click="this.tryAuth" :elementInLoading="this.isTryingAuth"/>
        <ButtonComponent label="Отменить" elementStatusModifier="danger" @click="this.$emit('result');"/>
      </div>
    </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import InputField from '@/units/forms/InputField.vue';
import {extAuthRequest} from '@/assets/dataSources/auth/extAuthRequest';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
  },
  data: () => ({
    login: '',
    password: '',
    errorText: null,
    isTryingAuth: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  watch: {

  },
  computed: {

  },
  methods: {
    acceptEvent() {
      this.tryAuth();
    },
    setLogin(login) {
      this.login = login;
    },
    setPassword(password) {
      this.password = password;
    },
    tryAuth() {
      if (this.isTryingAuth) return;

      this.isTryingAuth = true;
      this.errorText = null;

      extAuthRequest((data) => {
        this.isTryingAuth = false;
        if (data._error) {
          if (data.status == null) {
            this.errorText = `${data.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else {
            this.errorText = `${data.text} Статус: ${data.status}`;
          }

          return;
        }

        this.$emit('result', 'ok');
      }, this.login, this.password, this.params.extSystemUid);
    },
  },
};
</script>
