<template>
  <div class="subscription-plan-adder-wraper">
    <SpinnerComponent v-if="this.is_loading" :width="7"/>

    <LabelComponent v-if="this.error_text != null" :label_text="this.error_text" style="color: red;"/>

    <div v-for="(draw_item, index) in this.plan_list" :key="index">
      <div class="flex-row top-padding-gap" @click="this.selectNewPlan(draw_item)">
        <LabelComponent :label_text="(draw_item.price / 100)" :digits_precision="2" :is_monospace="true"/>
        <LabelComponent label_text="Монет"/>
        <ButtonComponent
              :elementType="'default'"
              :label="draw_item.view"
              class="entity"
            />
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
// import BlockSeparator from '@/units/forms/BlockSeparator.vue';
// import IconComponent from '@/units/RichIcon.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';


import getAvailableSubscriptionPlans from '@/assets/dataSources/subscription/getAvailableSubscriptionPlans';
import addSubscriptionPlan from '@/assets/dataSources/subscription/addSubscriptionPlan';


export default ({
  components: {
    ButtonComponent,
    LabelComponent,
    // BlockSeparator,
    // IconComponent,
    SpinnerComponent,
  },
  data: ()=> ({
    is_loading: true,
    plan_list: [],
    error_text: null,
  }),
  props: {
  },
  mounted() {
    getAvailableSubscriptionPlans((subscriptionData) => {
      this.is_loading = false;
      if (subscriptionData === false) {
        // this.is_subscription_exists = false;
        return;
      }

      this.plan_list = subscriptionData;
    });
  },
  computed: {

  },
  methods: {
    // completedForm(form) {
    //   console.log('COMPLETE FORM', Object.fromEntries(form));
    // },
    selectNewPlan(planName) {
      if (this.is_loading) return;

      this.is_loading = true;

      this.error_text = null;

      addSubscriptionPlan((result) => {
        this.is_loading = false;

        if (result === false) {
          this.error_text = 'Недостаточно монет для покупки либо отсутствует соединение с сервером.';
          return;
        }

        window.location.reload();
      }, planName.uid);
      // console.log('planName', planName[0].uid);
    },
  },
});
</script>
