import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
import JsEncrypt from 'jsencrypt';

const CRYPT_SESSION_ADDRESS = '/auth/get_crypt_session/';
const EXT_CRYPT_AUTH_ADDRESS = '/iapi_report/auth/';

function getCryptSession(handler) {
  fetch(ROOT_URL+CRYPT_SESSION_ADDRESS, {
    method: 'GET',
  }).then(async (result) => {
    if (result.status != 200) {
      handler({
        _error: true,
        status: result.status,
        text: 'Ошибка получения ключа шифрования: ' + (await result.text()).trimLen(MAX_ERROR_TEXT_LEN),
      });
      return;
    }
    handler({
      key_data: await result.json(),
    });
  }).catch(() => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения при получении ключа шифрования',
    });
  });
}

export function extAuthRequest(handler, login, password, sourceUid) {
  const cryptData = {
    login,
    password,
  };

  getCryptSession((cryptSession) => {
    if (cryptSession._error) {
      handler(cryptSession);
      return;
    }

    const cryptSessionKey = cryptSession.key_data.session;
    const cryptPublicKey = cryptSession.key_data.public_key;

    const en = new JsEncrypt();
    en.setPublicKey(cryptPublicKey);
    const encrypted = en.encrypt(JSON.stringify(cryptData));

    fetch(
        ROOT_URL+EXT_CRYPT_AUTH_ADDRESS+sourceUid+'/',
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'ENCRYPTED-DATA': encrypted,
            'CRYPT-SESSION': cryptSessionKey,
          },
        },
    ).then(async (authResult) => {
      if (authResult.status == 401) {
        handler({
          _error: true,
          status: authResult.status,
          text: 'Неверный логин или пароль.',
        });
        return;
      }

      if (authResult.status != 200) {
        handler({
          _error: true,
          status: authResult.status,
          text: `Ошибка авторизации. ${(await authResult.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
        });
        return;
      }

      handler({
        auth_result: await authResult.text(),
      });
    }).catch((error) => {
      handler({
        _error: true,
        status: null,
        text: 'Ошибка подключения при авторизации',
      });
    });
  });
}
