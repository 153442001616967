<template>
    <InputFieldTemplate
                        :title="this.title"
                        :iconUrl="this.iconUrl"
                        :hideData="this.isBadState"
                        :setFocus="this.fieldFocused"
                        @focusChange="this.setFieldFocused"
                        >
      <DateInput  v-if="this.drawDateInput"
                  @focusChange="this.isInputFocused = $event"
                  @dateChange="this.setNewDate"
                  :base_day="this.day"
                  :base_month="this.month"
                  :base_year="this.year"
                  />
    </InputFieldTemplate>
</template>

<script type="text/javascript">

import InputFieldTemplate from '@/units/InputFields/InputFieldTemplate.vue';
import DateInput from '@/units/InputFields/DateTimeInput/DateInput.vue';
import DateConstructor from '@/units/InputFields/DateTimeInput/DateConstructor.vue';

export default {
  components: {
    InputFieldTemplate,
    DateInput,
  },
  emits: ['input_change'],
  data: () => ({
    fieldFocused: false,
    isInputFocused: false,
    selectWinOpened: false,
    day: null,
    month: null,
    year: null,
  }),
  watch: {
    day() {
      this.callNewData();
    },
    month() {
      this.callNewData();
    },
    year() {
      this.callNewData();
    },
    set_values() {
      this.updateExtData();
    },
  },
  props: {
    type: {
      default: 'date',
      required: false,
      validator: (prop) => ['date', 'time', 'datetime'].includes(prop),
    },
    title: {
      default: '',
      required: false,
    },
    set_values: {
      default: null,
      required: false,
    },
  },
  created() {

  },
  mounted() {
    this.updateExtData();
  },
  computed: {
    isBadState() {
      return (
        this.day == null ||
        this.month == null ||
        this.year == null
      );
    },
    drawDateInput() {
      return this.type.includes('date');
    },
    iconUrl() {
      if (true || this.drawDateInput) return '/icons/system/calendar.svg';
      return null;
    },
  },
  unmounted() {

  },
  methods: {
    setFieldFocused(val) {
      this.fieldFocused = val;
      setTimeout(this.tryOpenSelectWindow, 2);
    },
    updateExtData() {
      if (this.set_values == null) {
        this.day = null;
        this.month = null;
        this.year = null;
        return;
      }

      const curDate = new FixDate(this.set_values);
      this.year = curDate.year;
      this.month = curDate.month;
      this.day = curDate.day;
    },
    callNewData() {
      if (this.isBadState) {
        this.$emit('input_change', null);
        return;
      }

      this.$emit(
          'input_change',
          `${
            String(this.year).padStart(4, '0')
          }-${
            String(this.month).padStart(2, '0')
          }-${
            String(this.day).padStart(2, '0')
          }T00:00:00.000Z`,
      );
    },
    setNewDate(event) {
      if (event == null) {
        return;
      } else {
        this.year = event.year;
        this.month = event.month;
        this.day = event.day;
      }
    },
    async tryOpenSelectWindow() {
      if (!this.fieldFocused || this.isInputFocused || this.selectWinOpened) return;

      this.selectWinOpened = true;
      const selectedDate = await window.openWindow({
        caption: this.title,
        component: DateConstructor,
        componentProps: {
          use_fixed_height: true,
          base_day: this.day,
          base_month: this.month,
          base_year: this.year,
        },
      });
      this.selectWinOpened = false;

      this.setFieldFocused(false);

      if (selectedDate == null) return;

      this.setNewDate(selectedDate);
    },
  },
};
</script>
