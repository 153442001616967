<template>
  <transition name="opacity-fade" appear>
    <div v-show="!this.isClosing" class="full-screen-block window" ref="windowRoot">
      <div class="window-view secure-content-box">
        <div class="active-header">
          <LabelComponent v-if="this.params.caption != null"
                          class="active-header-caption"
                          label_type="h3"
                          :label_text="this.params.caption"/>

          <div class="active-header-controls">
            <ButtonComponent elementType="hover"
                            @click="this.escCloseWin"
                            iconUrl="/icons/system/close.svg"
                            iconSize="22"/>
          </div>
        </div>

        <div class="content-frame">
          <div class="content-frame-wrapper">
            <component
              v-if="this.params.component != null"
              :is="this.params.component"
              :params="this.params.componentProps"
              ref="draw_component"
              @result="(result) => this.closeWindow(result)"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';

export default {
  mounted() {
    document.addEventListener('mousedown', this.startPointerEvent);
    document.addEventListener('touchstart', this.startPointerEvent);

    document.addEventListener('mouseup', this.endPointerEvent);
    document.addEventListener('touchend', this.endPointerEvent);

    window.escapeableElements.push(this.escCloseWin);
    window.acceptableElements.push(this.acceptHandler);
  },
  beforeUnmount() {
    document.removeEventListener('mousedown', this.startPointerEvent);
    document.removeEventListener('touchstart', this.startPointerEvent);

    document.removeEventListener('mouseup', this.endPointerEvent);
    document.removeEventListener('touchend', this.endPointerEvent);

    let index = window.escapeableElements.indexOf(this.escCloseWin);
    if (index > -1) window.escapeableElements.splice(index, 1);

    index = window.acceptableElements.indexOf(this.acceptHandler);
    if (index > -1) window.acceptableElements.splice(index, 1);
  },
  data: () => ({
    isClosing: false,
    closerTarget: null,
  }),
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  watch: {
    $route(to, from) {
      if (this.params.notRouteSensitive || String.comparePaths(to.path, from.path)) {
        return;
      }

      this.escCloseWin();
    },
  },
  computed: {

  },
  components: {
    ButtonComponent,
    LabelComponent,
  },
  methods: {
    startPointerEvent(event) {
      this.closerTarget = event.target;
    },
    endPointerEvent(event) {
      if (this.closerTarget != event.target) {
        this.closerTarget = null;
        return;
      }
      this.closerTarget = null;

      if (this.isClosing) return;
      if (this.$refs.windowRoot && this.$refs.windowRoot.compareDocumentPosition(event.target)) return;
      this.escCloseWin();
    },
    acceptHandler() {
      if (this.$refs.draw_component != null) {
        return this.$refs.draw_component.acceptEvent();
      }
    },
    escCloseWin() {
      if (this.isClosing) return true;
      this.closeWindow(this.params.defaultResult);
    },
    closeWindow(result) {
      if (this.isClosing) return;

      this.isClosing = true;
      setTimeout(() => this.params.winHandler(result), 200);
    },
  },
};

</script>

<style  lang="less">
.window {
  z-index: 200;
  background: @fade-content-background-color;

  .flex(row, center, center);

  &-view{
    .flex(column, flex-start, flex-start);

    background-color: @white;
    box-shadow: -2px -2px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: opacity 200ms;
    max-height: 98%;
    z-index: 130;
    max-width: 98%;
  }
}

</style>
