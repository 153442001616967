<template>
  <svg viewBox="0 0 50 50" :style="this.get_styles" class="spiner-root">
    <g class="spinner">
      <circle class="path-back" cx="25" cy="25" r="20" fill="none"
              :stroke-width="this.back_circle_width" stroke-miterlimit="10" />
      <circle class="path" cx="25" cy="25" r="20" fill="none"
              :stroke-width="this.front_circle_width" stroke-miterlimit="10" />
    </g>
  </svg>
</template>

<script>

export default {
  props: {
    width: {default: 5},
    size: {
      default: '27',
      type: String,
      required: false,
    },
  },
  data: () => ({
    front_circle_width: 2,
    back_circle_width: 3,
  }),
  mounted() {
    this.front_circle_width = this.width;
    this.back_circle_width = this.width + 1;
  },
  computed: {
    get_styles() {
      if (this.size == null) return null;

      return {
        'min-width': `${this.size}px`,
        'max-width': `${this.size}px`,
        'min-height': `${this.size}px`,
        'max-height': `${this.size}px`,
      };
    },
  },
};

</script>

<style  lang="less">
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50%
  {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  transform-origin: center;
  animation: rotate 2s linear infinite;

  & .path-back {
    stroke-linecap: round;
    stroke: @spiner-background-color;
  }

  & .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
    stroke: @spiner-foreground-color;
  }
}
</style>
