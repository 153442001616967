<template>
  <div class="search-text" :class="this.is_focused ? 'search-text-focused' : null">
    <div v-if="this.closeButton" class="close-button" @click="this.close">
      <IconComponent size=24 url="/icons/system/close.svg" color="#ee6767" style="margin: auto"/>
    </div>

    <input
            ref="input_field"
            v-model="this.search_value"
            @click="this.setFocus"
            @blur="this.outFocus"
            class="search-input"
    />

    <div class="search-button" @click="this.search">
      <IconComponent size=24 url="/icons/system/magnifying-glass.svg" color="white" style="margin: auto"/>
    </div>

  </div>
</template>

<script>
import IconComponent from '@/units/RichIcon.vue';

export default {
  components: {
    IconComponent,
  },
  emits: [
    'search',
    'close',
  ],
  data: function() {
    return {
      is_focused: false,
      search_value: '',
    };
  },
  props: {
    closeButton: {
      default: false,
      required: false,
    },
  },
  watch: {

  },
  created() {

  },
  mounted() {

  },
  unmounted() {

  },
  computed: {

  },
  methods: {
    outFocus() {
      this.is_focused = false;
      const index = window.acceptableElements.indexOf(this.search);
      if (index > -1) window.acceptableElements.splice(index, 1);
    },
    setFocus() {
      window.acceptableElements.push(this.search);
      this.is_focused = true;
      this.$nextTick(() => {
        this.$refs.input_field.focus();
      });
    },
    close() {
      this.outFocus();
      this.$emit('close');
    },
    search() {
      this.$emit('search', this.search_value);
    },
  },
};
</script>


<style lang="less">

@parent-padding: 2px;
@search-button-size: 28px;

.search-text {
  // width: fit-content;
  .flex(row, start, start);

  padding: @parent-padding;

  border: 1px solid @green;
  border-radius: 4px;
  background: linear-gradient(270deg, @green 0 @search-button-size+@parent-padding, @content-background-color 0);

  .search-input {
    border: 0 solid;
    height: @search-button-size;
    padding: 0 10px 0 10px-@parent-padding;
    flex: 1;
    color: @base-text-color;
    background-color : @content-background-color;
  }

  .close-button {
    width: @search-button-size;
    height: @search-button-size;
    align-content: center;
    cursor: pointer;
    background-color: @content-background-color;
  }

  .search-button {
    width: @search-button-size;
    height: @search-button-size;
    background-color: @green;
    align-content: center;
    cursor: pointer;
  }

  &:hover, &-focused {
    border: 1px solid @content-background-color;
    background: linear-gradient(270deg, @hover-green 0 @search-button-size+@parent-padding, @content-background-color 0);

    .search-button {
      background-color: @hover-green;
    }
  }
}
</style>
