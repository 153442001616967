import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/subscription_plan/connect_user_price/';

function connectUserPrice(handler) {
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
  }).then(async (response) => {
    if (response.status == 200) {
      handler(await response.json());
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка получения цены. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default connectUserPrice;
