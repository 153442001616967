<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <div class = "tfa_input__container">

    <div class = "changePasswordModal__container__group">
      <LabelComponent class = "changePasswordModal__container__header"
                      label_type = "button/large/medium 14"
                      label_text = "Введите код, отправленный Вам на почту!"/>

      <LabelComponent v-if="this.view_error != null"
                      class = "changePasswordModal__container__header"
                      label_type = "button/large/medium 14"
                      style="color: red;"
                      :label_text="this.view_error"/>

      <div class="tfa-input-field">
        <template v-for="symId in Object.keys(this.tfa_code_symbols)" :key="symId">
        <input class="tfa_symbol"
              :ref="('tfa_symbol_' + symId)"
              v-model="this.tfa_code_symbols[symId]"
              type="text"
              @change="this.updateSymbol(symId)"
              @keydown="this.updateSymbol(symId)"
              @paste="this.pasteInSymbol"
              @input="this.updateSymbol(symId)"
              />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';

export default ({
  emits: ['result'],
  components: {
    LabelComponent,
  },
  data: () => ({
    view_error: null,
    tfa_code_symbols: [],
  }),
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  computed: {
    error_message() {
      return this.params.error_message;
    },
    tfa_code_symbol_count() {
      return this.params.tfa_code_symbol_count;
    },
  },
  watch: {
    error_message() {
      this.view_error = this.error_message;
    },
    tfa_code_symbol_count() {
      this.initTfaSymbols();
    },
  },
  mounted() {
    window.acceptableElements.push(this.checkEditEnd);

    this.view_error = this.error_message;
    this.initTfaSymbols();
    this.setFocus();
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.checkEditEnd);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  methods: {
    initTfaSymbols() {
      this.tfa_code_symbols = Array.from({length: this.tfa_code_symbol_count}, () => '');
    },
    checkEditEnd() {
      // console.log('@@ this.tfa_code_symbols', this.tfa_code_symbols);

      let isEnd = true;

      this.tfa_code_symbols.forEach((elem) => elem.length != 1 ? isEnd = false : '');

      if (isEnd) {
        // console.log('TFA CODE: ', this.tfa_code_symbols.join(''), this.tfa_code_symbols, this.tfa_code_symbols.length);
        this.$emit('result', this.tfa_code_symbols.join(''));
      }
    },
    setFocus() {
      let filledSymbolId = 0;
      this.tfa_code_symbols.forEach((symbol, id) => (symbol != null && symbol != '' ? filledSymbolId = id : ''));

      filledSymbolId = Math.min(filledSymbolId, this.tfa_code_symbol_count - 1);
      // console.log('$refs focus:', this.$refs['tfa_symbol_' + (filledSymbolId)], 'tfa_symbol_' + (filledSymbolId));

      const reqInput = this.$refs['tfa_symbol_' + (filledSymbolId)];

      if (reqInput != null) {
        reqInput[0].focus();
        reqInput[0].selectionStart = 1;
        reqInput[0].selectionEnd = 1;
      }
    },
    pasteInSymbol(event) {
      const pasteVal = (event.clipboardData || window.clipboardData).getData('text');

      this.tfa_code_symbols[0] = pasteVal;
      this.updateSymbol(0);
    },
    updateSymbol(symId) {
      this.view_error = null;
      symId = Number.parseInt(symId);
      // console.log('symId', symId, this.tfa_code_symbols[symId], this.tfa_code_symbols[symId] == null, this.tfa_code_symbols[symId] == '', this.tfa_code_symbols[symId] == true);

      if (this.tfa_code_symbols[symId] == null || this.tfa_code_symbols[symId] == '') {
        this.tfa_code_symbols[symId] = '';

        const reqInput = this.$refs['tfa_symbol_' + (symId-1)];

        // console.log('refocus');

        if (reqInput != null) {
          reqInput[0].focus();
          reqInput[0].selectionStart = 1;
          reqInput[0].selectionEnd = 1;
        }

        return;
      }

      // if (this.tfa_code_symbols[symId].length >= this.tfa_code_symbol_count) {
      //   const fullCode = this.tfa_code_symbols[symId];
      //   this.tfa_code_symbols = Array.from(
      //       {length: this.tfa_code_symbol_count},
      //       (_, i) => (fullCode[i] != null ? fullCode[i] : ''),
      //   );
      //   this.setFocus();
      //   this.checkEditEnd();
      //   return;
      // }

      if (this.tfa_code_symbols[symId].length > 1 && symId < this.tfa_code_symbol_count - 1) {
        this.tfa_code_symbols[symId + 1] = this.tfa_code_symbols[symId].substring(1);
        this.updateSymbol(symId + 1);
      }

      this.tfa_code_symbols[symId] = this.tfa_code_symbols[symId][0];

      this.setFocus();
      this.checkEditEnd();
    },
  },
});
</script>

<style lang="less">
.tfa_input {
  &__container {
    min-width: 250px;
    width: 15vw;
    display: grid;
    grid-row-gap: 20px;

    &__group {
      display: grid;
      grid-row-gap: 10px;
    }
    &__input {
      width: 100%;
    }
  }
}

.tfa-input-field {
  .flex(row, center, center);

  .tfa_symbol {
    margin: 2px;
    width: 30px;
    height: 30px;
    padding: 6px;
    font-size: 16px;
  }
}
</style>
