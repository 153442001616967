<template>
  <div class="user-group-list-wraper flex-column">
    <SpinnerComponent v-if="this.is_loading" :width="7"/>

    <LabelComponent
                    v-else-if="this.user_group_list.length < 1"
                    label_text="Пока не добавлено ни одной группы. Для добавления воспользуйтесь кнопкой ниже."
                    label_type="body/large/regular 14"
                  />

    <div class="user-group-item content-box" v-for="elem in this.user_group_list" :key="elem">
      <div class="user-group-item-line flex-column">
        <!-- <LabelComponent label_text="Наименование:" label_type="body/large/regular 14" /> -->
        <LabelComponent :label_text="elem.view" label_type="h3"/>

        <template v-if="this.isSubscriptionEditor">
          <ButtonComponent
            elementType="default"
            iconUrl="/icons/system/gear.svg"
            label="Изменить"
            @click_to_button="'this.openAddDSModal'"
          />

          <ButtonComponent
            elementType="default"
            iconUrl="/icons/system/trash.svg"
            elementStatusModifier="danger"
            label="Удалить"
            @click_to_button="'this.openAddDSModal'"
          />
        </template>
      </div>
    </div>

    <br />

    <ButtonComponent v-if="this.isSubscriptionEditor"
      elementType="default"
      iconUrl="/icons/system/plus.svg"
      elementSize="big"
      label="Добавить группу пользователей"
      @click_to_button="this.openAddUGModal"
    />
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import UserGroupForm from './UserGroupForm.vue';

import getSubscriptionGroups from '@/assets/dataSources/subscription/getSubscriptionGroups.js';


export default ({
  components: {
    ButtonComponent,
    LabelComponent,
    SpinnerComponent,
  },
  data: ()=> ({
    is_loading: true,
    user_group_list: [],
  }),
  props: ['isSubscriptionEditor'],
  mounted() {
    this.reloadUserGroups();
  },
  computed: {

  },
  methods: {
    reloadUserGroups() {
      this.is_loading = true;
      getSubscriptionGroups((ugData) => {
        this.is_loading = false;
        if (ugData === false) {
          this.user_group_list = [];
          return;
        }
        this.user_group_list = ugData;
      });
    },
    async openAddUGModal() {
      const addResult = await window.openWindow({
        caption: 'Заполните данные группы',
        component: UserGroupForm,
      });

      if (addResult != 'ok') return;

      this.reloadUserGroups();
    },
  },
});
</script>
