export default [
  'heavy/add_tracking.svg',
  'heavy/allow_tasks.svg',
  'heavy/all_warehouses.svg',
  'heavy/change_status.svg',
  'heavy/hard_report.svg',
  'heavy/pay_graphic.svg',
  'heavy/pay_limits.svg',
  'heavy/pay_raiting.svg',
  'heavy/sales_effect.svg',
  'heavy/sale_analyse.svg',
  'heavy/self_price.svg',
  'heavy/simple_actives.svg',
  'light/actives.svg',
  'light/analysis-1.svg',
  'light/badge-002.svg',
  'light/big_bag.svg',
  'light/bug.svg',
  'light/buy_history.svg',
  'light/buy_list.svg',
  'light/clear_actives.svg',
  'light/drive_products.svg',
  'light/eye.svg',
  'light/factory.svg',
  'light/file.svg',
  'light/finres_icon.svg',
  'light/folder.svg',
  'light/for_buyers.svg',
  'light/for_directors.svg',
  'light/for_outside_directors.svg',
  'light/for_suppliers.svg',
  'light/handbook.svg',
  'light/kartli_market_outlook.svg',
  'light/law.svg',
  'light/map-trifold.svg',
  'light/people.svg',
  'light/price_analyse.svg',
  'light/products_for_outside.svg',
  'light/sale_acts.svg',
  'light/truck.svg',
  'system_like/arrow-counter-clockwise.svg',
  'system_like/arrow-filled-left.svg',
  'system_like/arrow-in-full-size.svg',
  'system_like/arrow-in-simple.svg',
  'system_like/arrow-left.svg',
  'system_like/arrow-right.svg',
  'system_like/calendar.svg',
  'system_like/cancel_round.svg',
  'system_like/check_round.svg',
  'system_like/close.svg',
  'system_like/download-button.svg',
  'system_like/edit.svg',
  'system_like/empty.svg',
  'system_like/favorite_empty.svg',
  'system_like/favorite_full.svg',
  'system_like/file-arrow-down.svg',
  'system_like/file-plus.svg',
  'system_like/funnel.svg',
  'system_like/gear.svg',
  'system_like/globe.svg',
  'system_like/help_round.svg',
  'system_like/magnifying-glass.svg',
  'system_like/more-information.svg',
  'system_like/more.svg',
  'system_like/time.svg',
  'system_like/trash.svg',
];
