<template>
  <div class="full-screen-block bottom-window" ref="windowRoot" :style="this.isClosing ? 'background: transparent;' : ''">
    <div
          class="bottom-window-view secure-content-box"
          :class="'bottom-window-view-level-' + this.myLevel"
          :style="this.isClosing ? 'top: 100%;' : (this.isFullScreen ? 'top: 0%;' : '')"
    >
      <div class="active-header">
        <LabelComponent v-if="this.params.caption != null"
                        class="active-header-caption"
                        label_type="h3"
                        :label_text="this.params.caption"/>

        <div class="active-header-controls">
          <ButtonComponent v-if="!this.isFullScreen"
                           elementType="hover"
                           elementStatus="selected"
                           @click="this.isFullScreen = !this.isFullScreen"
                           iconUrl="/icons/system/arrow-in-full-size.svg"
                           iconSize="22"/>
          <ButtonComponent v-else
                           elementType="hover"
                           @click="this.isFullScreen = !this.isFullScreen"
                           iconUrl="/icons/system/arrow-in-simple.svg"
                           iconSize="22"/>
          <ButtonComponent elementType="hover"
                          @click="this.escCloseWin"
                          iconUrl="/icons/system/close.svg"
                          iconSize="22"/>
        </div>
      </div>

      <div class="content-frame">
        <div class="content-frame-wrapper">
          <!-- <div @click="this.addTestButtomLevel"> ADD BUTTOM LEVEL </div> -->
          <component
            v-if="this.params.component != null"
            :is="this.params.component"
            :params="this.params.componentProps"
            @result="(result) => this.closeWindow(result)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';

let LAST_WIN = null;

export default {
  mounted() {
    if (LAST_WIN != null) {
      this.setBeforeWin(LAST_WIN);
      LAST_WIN.setNextWin(this);
    }

    LAST_WIN = this;

    setTimeout(() => this.isClosing = false, 1);

    document.addEventListener('mousedown', this.startPointerEvent);
    document.addEventListener('touchstart', this.startPointerEvent);

    document.addEventListener('mouseup', this.endPointerEvent);
    document.addEventListener('touchend', this.endPointerEvent);

    window.escapeableElements.push(this.escCloseWin);
    window.acceptableElements.push(this.acceptHandler);
  },
  beforeUnmount() {
    if (LAST_WIN == this) LAST_WIN = null;

    document.removeEventListener('mousedown', this.startPointerEvent);
    document.removeEventListener('touchstart', this.startPointerEvent);

    document.removeEventListener('mouseup', this.endPointerEvent);
    document.removeEventListener('touchend', this.endPointerEvent);

    let index = window.escapeableElements.indexOf(this.escCloseWin);
    if (index > -1) window.escapeableElements.splice(index, 1);

    index = window.acceptableElements.indexOf(this.acceptHandler);
    if (index > -1) window.acceptableElements.splice(index, 1);
  },
  data: () => ({
    nextWin: null,
    beforeWin: null,
    isClosing: true,
    isFullScreen: false,
    closerTarget: null,
  }),
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  watch: {
    $route(to, from) {
      if (this.params.notRouteSensitive || String.comparePaths(to.path, from.path)) {
        return;
      }

      this.escCloseWin();
    },
  },
  methods: {
    acceptHandler() {},
    // addTestButtomLevel() {
    //   window.openWindow({
    //     caption: 'TEST',
    //     type: 'bottom',
    //   });
    // },
    setNextWin(next) {
      this.nextWin = next;
    },
    setBeforeWin(before) {
      this.beforeWin = before;
    },

    startPointerEvent(event) {
      this.closerTarget = event.target;
    },
    endPointerEvent(event) {
      if (this.closerTarget != event.target) {
        this.closerTarget = null;
        return;
      }
      this.closerTarget = null;

      if (this.isClosing) return;
      if (this.$refs.windowRoot && this.$refs.windowRoot.compareDocumentPosition(event.target)) return;
      this.escCloseWin();
    },
    escCloseWin() {
      if (this.isClosing) return true;
      this.closeWindow(this.params.defaultResult);
    },
    closeWindow(result) {
      if (this.isClosing) return;

      this.isClosing = true;

      if (this.nextWin != null) this.nextWin.setBeforeWin(this.beforeWin);
      if (this.beforeWin != null) this.beforeWin.setNextWin(this.nextWin);
      if (LAST_WIN == this) LAST_WIN = this.beforeWin;

      this.nextWin = null;
      this.beforeWin = null;

      setTimeout(() => this.params.winHandler(result), 400);
    },
  },
  computed: {
    myLevel() {
      if (this.nextWin == null) return 1;
      return this.nextWin.myLevel + 1;
    },
  },
  components: {
    ButtonComponent,
    LabelComponent,
  },
};

</script>

<style  lang="less">

.bottom-window{
  z-index: 200;
  background: @fade-content-background-color;

  transition: background @slow-animation-speed;

  &-view{
    z-index: 130;

    position: absolute;
    width: 100%;
    top: 100%;
    max-width: 100%;
    bottom: 0;
    transition: top @slow-animation-speed;

    &-level-1 { top: 49% - 7% * 0 }
    &-level-2 { top: 49% - 7% * 1 }
    &-level-3 { top: 49% - 7% * 2 }
    &-level-4 { top: 49% - 7% * 3 }
    &-level-5 { top: 49% - 7% * 4 }
    &-level-6 { top: 49% - 7% * 5 }
    &-level-7 { top: 49% - 7% * 6 }
    &-level-8 { top: 49% - 7% * 7 }
  }
}
</style>
