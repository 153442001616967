import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/subscription/connect_subscription/';

function connectSubscription(subCode, handler) {
  fetch(ROOT_URL + REQUEST_LINK + subCode + '/', {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    handler(response.status);
  }).catch((errData) => {
    handler(null);
  });
}

export default connectSubscription;
