<template>
  <div v-show="this.result_struct.length > 0 || this.is_searching || this.error_message != null">
    <LabelComponent v-if="this.error_message != null" :label_text = "this.error_message" style="color: red;"/>
    <SpinnerComponent v-if="this.is_searching" style="width: 50px;" :width="5"/>

    <template v-for="request, ridx in this.result_struct" :key="ridx">
      <FoundCard v-for="foundCard, idx in request" :key="ridx+'_'+idx" :cardData="foundCard"/>
    </template>
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import FoundCard from './FoundCard.vue';

import fullSearch from '@/assets/dataSources/navigation/fullSearch.js';


export default {
  components: {
    LabelComponent,
    SpinnerComponent,
    FoundCard,
  },
  data: ()=> ({
    result_struct: [],
    error_message: null,
    is_searching: false,
    search_number: 0,
  }),
  mounted() {

  },
  watch: {
    searchRequest() {
      this.research();
    },
  },
  props: {
    searchRequest: {
      default: null,
      required: true,
    },
  },
  computed: {

  },
  methods: {
    research() {
      this.search_number += 1;

      this.error_message = null;

      if (!this.searchRequest || this.searchRequest.length < 3) {
        this.error_message = 'Поисковой запрос должен содержать не менее 3 символов!';
        return;
      }

      this.is_searching = true;

      this.result_struct = [];

      const buildsSearchCard = (rootResult, type, uid, view) => ({
        dsUid: rootResult.idx,
        dsView: rootResult.view,
        type: type,
        uid: uid,
        view: view,
      });

      fullSearch(this.search_number, this.searchRequest, (searchResult, searchNumber) => {
        if (searchNumber != this.search_number) return false;

        if (searchResult.error_status != null) {
          this.result_struct.push([
            buildsSearchCard(searchResult, 'error', '', `${searchResult.data} Статус: ${searchResult.error_status}`),
          ]);
          return true;
        }

        if (searchResult.type == 'report') {
          this.result_struct.push(searchResult.data.map((elem) => buildsSearchCard(
              searchResult,
              searchResult.type,
              elem.uid,
              elem.view,
          )));
        } else if (searchResult.type == 'entity') {
          this.result_struct.push(searchResult.data.map((elem) => buildsSearchCard(
              searchResult,
              searchResult.type,
              elem,
              elem,
          )));
        } else if (searchResult.type == 'storage') {
          this.result_struct.push(searchResult.data.map((elem) => buildsSearchCard(
              searchResult,
              searchResult.type,
              elem.uid,
              elem.view,
          )));
        }

        return true;
      }, (error, searchNumber) => {
        if (searchNumber != this.search_number) return;

        if (error.status == null) {
          this.error_message = `${error.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
        } else {
          this.error_message = `${error.text} Статус: ${error.status}`;
        }
      }, (searchNumber) => {
        if (searchNumber != this.search_number) return;

        this.is_searching = false;
      });
    },
  },
};
</script>
