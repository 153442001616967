<template>
  <div class="dropdown-selector flex-column">
    <InputField class="dropdown-selector-input"
                v-if="this.isFilterable"
                :set_values="this.curFilter"
                @input_change="this.filterChanged"
                label_text="Фильтр"
    />
    <ButtonComponent v-if="this.isMultiSelect"
                    label="Выбрать"
                    @click="this.$emit('result', this.selectedValues)"
                    />

    <div
          class="dropdown-selector-items"
          :style="this.itemsHeight == null ? '' : `height: ${this.itemsHeight}px`"
          >

      <div class="dropdown-selector-items-content" ref="items_wraper">
        <LabelComponent v-if="this.isFavoritable && Object.keys(this.favorites).length" label_text="Избранное:"/>

        <template v-for="elem, eIdx in this.unitedDrawValues" :key="eIdx">
          <SeparatorComponent
                              v-if="this.isFavoritable && eIdx == Object.keys(this.favorites).length"
                              style="margin-bottom: 24px;"/>

          <div class="dropdown-selector-item"
               @click="this.setElemSelected(elem, !elem.selected)">
            <CheckBoxComponent v-if="this.isMultiSelect"
                              :label_text_right="elem.view"
                              :init_value="elem.selected"
                              />
            <LabelComponent v-else
                            :label_text="elem.view"/>
            <Icon class="favorite-button" :url="'/icons/system/' + (elem.isFavorite ? 'favorite_full.svg' : 'favorite_empty.svg')"
                  sizeHeight="20"
                  sizeWidth="20"
                  v-if="this.isFavoritable"
                  @click.stop="this.setFavorite(elem, !elem.isFavorite)"
            />
          </div>
        </template>

        <LabelComponent v-if="!this.drawValues || this.drawValues.length < 1"
                            label_text="<элементов нет>"
                            />

        <SpinnerComponent v-if="this.isLoading" :width="7" size="35"/>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import CheckBoxComponent from '@/units/forms/CheckBox';
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import InputField from '@/units/forms/InputField.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import Icon from '@/units/RichIcon.vue';
import SeparatorComponent from '@/units/forms/BlockSeparator.vue';

// const savedFavorites = localStorage.getItem('favorites__' + this.input_info.system_name);
// this.favorites = savedFavorites == null ? {} : JSON.parse(savedFavorites);

// localStorage.setItem('favorites__' + this.input_info.system_name, JSON.stringify(this.favorites));

export default {
  components: {
    CheckBoxComponent,
    LabelComponent,
    ButtonComponent,
    InputField,
    SpinnerComponent,
    Icon,
    SeparatorComponent,
  },
  data: () => ({
    curFilter: null,
    selectedValues: [],
    isLoading: true,
    drawValues: [],
    itemsHeight: null,
    minLineHeight: 25,
    pageSize: 40,
    isListEnd: false,
    favorites: {},
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  watch: {
    rawSelection() {
      this.initValues();
    },
    paginator() {
      this.researchItems();
    },
    isFavoritable() {
      if (this.isFavoritable) this.initFavorites();
      else this.favorites = {};
    },
  },
  mounted() {
    this.initValues();
    if (this.isFavoritable) this.initFavorites();

    window.addEventListener('resize', this.calcItemListHeight);

    setTimeout(() => {
      this.$refs.items_wraper.parentNode.addEventListener('scroll', this.checkScroll);

      this.calcItemListHeight();
      this.researchItems();
    }, 1);
  },
  unmounted() {
    window.removeEventListener('resize', this.calcItemListHeight);
  },
  methods: {
    setFavorite(elem, setVal) {
      elem.isFavorite = setVal;
      if (setVal) {
        this.favorites[elem.uid] = elem;
      } else {
        delete this.favorites[elem.uid];
      }

      if (!this.$store.state.autostart_mode) {
        localStorage.setItem(this.favorName, JSON.stringify(this.favorites));
      }
    },
    initFavorites() {
      const savedFavorites = this.$store.state.autostart_mode ? null : localStorage.getItem(this.favorName);
      this.favorites = savedFavorites == null ? {} : JSON.parse(savedFavorites);
    },
    checkScroll() {
      if (this.isLoading) return;

      const bodyRect = document.getElementsByTagName('body')[0].getBoundingClientRect();
      const itemsWrapperRect = this.$refs.items_wraper.getBoundingClientRect();
      if (itemsWrapperRect.bottom - bodyRect.bottom < this.pageSize * this.minLineHeight) {
        this.updateItemsList(1);
      }
    },
    calcItemListHeight() {
      const bodyRect = document.getElementsByTagName('body')[0].getBoundingClientRect();
      const itemsWrapperRect = this.$refs.items_wraper.getBoundingClientRect();
      const itemsWrapperParentRect = this.$refs.items_wraper.parentNode.getBoundingClientRect();

      const reqSize = bodyRect.bottom - (bodyRect.height / 100) - 35 - itemsWrapperParentRect.top;

      const selfSize = itemsWrapperRect.bottom - itemsWrapperParentRect.top;

      if (selfSize < reqSize) {
        // console.log('WTF?', selfSize < reqSize, selfSize, reqSize);

        this.itemsHeight = null;
        return;
      }

      // console.log('WTF?2', selfSize < reqSize, selfSize, reqSize);

      this.itemsHeight = reqSize;
    },
    async setElemSelected(elem, isSelected) {
      if (elem.canSetSelect != null) {
        const canSet = await elem.canSetSelect(isSelected);
        if (!canSet) return;
      }

      elem.selected = isSelected;

      if (!isSelected) {
        this.selectedValues = this.selectedValues.filter((selElem) => selElem.uid != elem.uid);
      } else {
        this.selectedValues.push(elem);
      }

      if (!this.isMultiSelect) {
        this.$emit('result', [elem]);
        return;
      }
    },
    filterChanged(data) {
      // data = data == '' ? null : data;
      // console.log('datadata', this.curFilter == data);
      const dataUpdate = this.curFilter != data;
      this.curFilter = data;

      if (this.researchTimer != null) clearTimeout(this.researchTimer);
      this.researchTimer = null;

      if (!dataUpdate) return;

      this.isLoading = true;
      this.researchTimer = setTimeout(this.researchItems, 500);
    },
    initValues() {
      if (this.rawSelection == null) {
        this.selectedValues = [];
      }
      this.selectedValues = this.rawSelection.map((elem) => elem);
    },
    researchItems() {
      this.drawValues = [];
      this.isListEnd = false;
      this.updateItemsList(3);
    },
    async updateItemsList(pagesCount) {
      this.isLoading = false;
      if (this.isListEnd) {
        return;
      }

      this.isLoading = true;

      for (let i = 0; i < pagesCount; i++) {
        const reqPage = Math.floor(this.drawValues.length / this.pageSize);
        if (this.drawValues.length % this.pageSize > 0) {
          this.isListEnd = true;
          break;
        }
        const newValues = await this.paginator(reqPage, this.pageSize, this.curFilter == null ? null : [this.curFilter]);
        if (newValues.length < 1) this.isListEnd = true;

        // console.log('newValues', newValues);

        newValues.forEach((newVal) => {
          const isSelected = this.selectedValues.filter((selVal) => selVal.uid == newVal.uid).length > 0;
          const isFavorite = this.favorites[newVal.uid] != null;

          if (isFavorite) this.favorites[newVal.uid] = newVal;

          newVal.selected = isSelected;
          newVal.isFavorite = isFavorite;
        });

        this.drawValues = this.drawValues.concat(newValues);
      }

      this.isLoading = false;

      setTimeout(() => {
        this.calcItemListHeight();
      }, 1);
    },
  },
  computed: {
    unitedDrawValues() {
      return Object.values(this.favorites).concat(this.drawValues);
    },
    favorName() {
      return 'favorites__' + this.favoriteIdx;
    },
    isMultiSelect() {
      return this.params.isMultiSelect;
    },
    isFilterable() {
      return this.params.filterable;
    },
    rawSelection() {
      return this.params.selections;
    },
    paginator() {
      return this.params.paginator;
    },
    favoriteIdx() {
      return this.params.favoritableIdx;
    },
    isFavoritable() {
      return this.favoriteIdx != null;
    },
    extenderHandler() {
      return this.params.extenderHandler;
    },
    editorHandler() {
      return this.params.editorHandler;
    },
    removeHandler() {
      return this.params.removeHandler;
    },
    canAdd() {
      return this.extenderHandler != null;
    },
    canEdit() {
      return this.editorHandler != null;
    },
    canRemove() {
      return this.removeHandler != null;
    },
  },
};
</script>

<style lang="less">

.dropdown-selector {
  &-items {
    overflow: auto;
  }

  &-item {
    .flex(row, space-between, space-between);

    width: 100%;
    padding: 4px 15px;

    &:hover {
      background: @light-green-opacity-10;
      cursor: pointer;
    }
  }

  .selected-item {
    color: @green;
  }
}

</style>
