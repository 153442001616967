<template>
  <div v-if="this.$store.getters.isLoggedIn">
    <LabelComponent :label_text="'Вы уверены что хотите подключится к подписке ' + this.subCode + ' ?'"/>

    <div class="buttons-wraper">
      <ButtonComponent label="Да" @click="this.connectReq"/>
      <ButtonComponent label="Вернуться в начало" href="/"/>
    </div>
  </div>
  <div v-else class="sub-connect-no-auth-wraper">
    <LabelComponent label_text="Для подключения к подписке нужно авторизоваться или зарегистрироваться"/>

    <div class="buttons-wraper">
      <ButtonComponent label="Авторизация" @click="this.hardOpenLink('/login/?next=' + this.pathName)"/>
      <ButtonComponent label="Регистрация" @click="this.hardOpenLink('/register/?next=' + this.pathName)"/>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';

import subConnectionRequest from '@/assets/dataSources/subscription/subConnectionRequest.js';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
  },
  computed: {
    subCode() {
      return this.$route.params.subscriptionCode;
    },
    pathName() {
      return (new URL(window.location.href)).pathname;
    },
  },
  methods: {
    hardOpenLink(link) {
      window.location.href = link;
    },
    connectReq() {
      subConnectionRequest(this.subCode, (status) => {
        if (status == 200) {
          window.openAcceptWindow('Ваша заявка принята. Ожидайте подтверждения владельца подписки');
          return;
        }
        if (status == null) {
          window.openAcceptWindow('Ошибка соединения с сервером. Проверьте интернет', 'Ошибка');
          return;
        }

        window.openAcceptWindow('Ваша заявка уже была подана. Ждите согласования владельцем подписки', 'Внимание');
      });
    },
  },
};
</script>

<style lang="less">
.sub-connect-no-auth-wraper {
  margin: auto;
  padding-top:32px;
  width: fit-content;
}

.buttons-wraper {
  margin-top: 13px;
  .flex(row, center, center);
  & > * {
    margin: 0 13px;
  }
}
</style>
