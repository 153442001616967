<template>
  <div class="page-access-editor flex-column">
    <LabelComponent v-if="this.loadError != null" :label_text="this.loadError" style="color: red;"/>

    <SpinnerComponent v-if="this.isLoading" :width="7" size="35"/>

    <template v-if="this.isDenyPolitic === true">
      <LabelComponent label_text="Политика источника: В рамках подписки запрещено всем, кроме разрешенных"/>

      <DropDownInput :itemList="this.groupListPaginator"
                  :selectedItems="this.accessInfo.allow_groups"
                  :isMultiSelect="true"
                  labelText="Группы, которым разрешен доступ"
                  :isRequired="false"
                  :isFilterable="true"
                  favoritableIdx="sub_groups"
                  @inputChange="(range) => this.accessInfo.allow_groups = range"/>
      <DropDownInput :itemList="this.userListPaginator"
                  :selectedItems="this.accessInfo.allow_users"
                  :isMultiSelect="true"
                  labelText="Пользователи, которым разрешен доступ"
                  :isRequired="false"
                  :isFilterable="true"
                  favoritableIdx="sub_users"
                  @inputChange="(range) => this.accessInfo.allow_users = range"/>
    </template>

    <template v-if="this.isDenyPolitic === false">
      <LabelComponent label_text="Политика источника: В рамках подписки разрешено всем, кроме запрещенных"/>

      <DropDownInput :itemList="this.groupListPaginator"
                  :selectedItems="this.accessInfo.deny_groups"
                  :isMultiSelect="true"
                  labelText="Группы, которым запрещен доступ"
                  :isRequired="false"
                  :isFilterable="true"
                  favoritableIdx="sub_groups"
                  @inputChange="(range) => this.accessInfo.deny_groups = range"/>
      <DropDownInput :itemList="this.userListPaginator"
                  :selectedItems="this.accessInfo.deny_users"
                  :isMultiSelect="true"
                  labelText="Пользователи, которым запрещен доступ"
                  :isRequired="false"
                  :isFilterable="true"
                  favoritableIdx="sub_users"
                  @inputChange="(range) => this.accessInfo.deny_users = range"/>
    </template>

    <ButtonComponent v-if="this.isDenyPolitic != null"
                    label="Сохранить"
                    @click="this.saveForm"
                    />
  </div>
</template>

<script>

import getPageAccessData from '@/assets/dataSources/subscription/getPageAccessData.js';
import getConnectedUsers from '@/assets/dataSources/subscription/getConnectedUsers.js';
import getSubscriptionGroups from '@/assets/dataSources/subscription/getSubscriptionGroups.js';
import setPageAccess from '@/assets/dataSources/subscription/setPageAccess.js';

import LabelComponent from '@/units/RichLabel.vue';
import DropDownInput from '@/units/forms/DropDownInput.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    LabelComponent,
    DropDownInput,
    SpinnerComponent,
    ButtonComponent,
  },
  data: () => ({
    isLoading: true,
    loadError: null,
    accessInfo: null,
    cachedUsers: null,
    cachedGroups: null,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  whatch: {
    pageUid() {
      this.reloadAccessData();
    },
  },
  mounted() {
    this.reloadAccessData();
  },
  methods: {
    reloadAccessData() {
      this.isLoading = true;
      this.accessInfo = null;
      this.loadError = null;
      getPageAccessData((accessData) => {
        this.isLoading = false;
        if (accessData._error) {
          this.loadError = accessData.text + String(accessData.status);
          return;
        }

        this.accessInfo = accessData;
      }, this.pageUid);
    },
    saveForm() {
      setPageAccess(
          () => {
            this.$emit('result');
          },
          this.pageUid,
          this.accessInfo.allow_users,
          this.accessInfo.allow_groups,
          this.accessInfo.deny_users,
          this.accessInfo.deny_groups,
      );
    },

    async groupListPaginator(pageNum, pageSize, searchViews, searchIds) {
      if (this.cachedGroups == null) await this.updateGroupListCache();

      if (this.cachedGroups == null) return [];

      const filteredList = this.cachedGroups.filter(
          (elem) => (
            (searchViews == null && searchIds == null) ||
            (searchViews == null ? false : searchViews.filter((searchElem) => elem.view.toUpperCase().includes(searchElem.toUpperCase())).length > 0) ||
            (searchIds == null ? false : searchIds.filter((searchElem) => elem.uid == searchElem).length > 0)
          ),
      ).sort((a, b) => {
        if (searchViews != null) {
          const isAstarts = searchViews.filter((startView) => a.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;
          const isBstarts = searchViews.filter((startView) => b.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;

          if (isAstarts && !isBstarts) return -1;
          if (!isAstarts && isBstarts) return 1;
        }

        if (a.view < b.view) return -1;
        if (a.view > b.view) return 1;
        return 0;
      });

      return filteredList.slice(pageNum * pageSize, pageNum * pageSize + pageSize);
    },
    updateGroupListCache() {
      return new Promise((resolver) => {
        getSubscriptionGroups((data) => {
          resolver();
          if (data._error) return;

          this.cachedGroups = data;
        });
      });
    },
    async userListPaginator(pageNum, pageSize, searchViews, searchIds) {
      if (this.cachedUsers == null) await this.updateUserListCache();

      if (this.cachedUsers == null) return [];

      const filteredList = this.cachedUsers.filter(
          (elem) => (
            (searchViews == null && searchIds == null) ||
            (searchViews == null ? false : searchViews.filter((searchElem) => elem.view.toUpperCase().includes(searchElem.toUpperCase())).length > 0) ||
            (searchIds == null ? false : searchIds.filter((searchElem) => elem.uid == searchElem).length > 0)
          ),
      ).sort((a, b) => {
        if (searchViews != null) {
          const isAstarts = searchViews.filter((startView) => a.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;
          const isBstarts = searchViews.filter((startView) => b.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;

          if (isAstarts && !isBstarts) return -1;
          if (!isAstarts && isBstarts) return 1;
        }

        if (a.view < b.view) return -1;
        if (a.view > b.view) return 1;
        return 0;
      });

      return filteredList.slice(pageNum * pageSize, pageNum * pageSize + pageSize);
    },
    updateUserListCache() {
      return new Promise((resolver) => {
        getConnectedUsers((data) => {
          resolver();
          if (data._error) return;

          this.cachedUsers = data.map((elem) => ({view: elem.user_view, uid: elem.user_id}));
        });
      });
    },
  },
  computed: {
    isDenyPolitic() {
      if (this.accessInfo == null) return null;
      return this.accessInfo.is_deny_mode;
    },
    pageUid() {
      return this.params.page_uid;
    },
  },
};
</script>
