<template>
  <LabelComponent :label_text="this.params.text"/>
  <ButtonComponent
                   :label="this.params.acceptButtonText == null ? 'Хорошо' : this.params.acceptButtonText"
                   @click="this.$emit('result')"
                  />
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
  },
  data: () => ({

  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {

  },
  computed: {

  },
};
</script>
