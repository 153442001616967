import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/ds/get_data_source/';

function getDataSourceInfo(handler, dsUid) {
  const form = new FormData();
  form.set('ds_uid', dsUid);

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: form,
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.json());
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка получения информации об источнике. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default getDataSourceInfo;
