<template>
  <DropDownInput :itemList="this.dateRangePaginator"
                 :selectedItems="this.selectedItems"
                 :labelText="this.label_text"
                 :isRequired="false"
                 :fieldStatus="this.elementStatus"
                 :isFilterable="false"
                 @inputChange="this.setNewSelect($event[0])"/>
</template>

<script>
import DropDownInput from '@/units/forms/DropDownInput.vue';
import ManualDateRangeSelector from '@/units/forms/ManualDateRangeSelector.vue';

class Period {
  constructor(label, uid, fromBuilder=(date) => date, toBuilder=(date) => date, labelBuilder=(label, obj) => label, canSelect=null) {
    this.label = label;
    this.idx = null;
    this.uid = uid;
    this.dateTime = {
      from: null,
      to: null,
    };

    this.labelBuilder = labelBuilder;
    this.fromBuilder = fromBuilder;
    this.toBuilder = toBuilder;
    this.setCanSelect(canSelect);

    this.updateFromAndTo();
  }

  setCanSelect(newFunc) {
    this.canSelect = newFunc;
  }

  async canSetSelect(selectState) {
    if (this.canSelect == null) return true;
    return await this.canSelect(selectState, this);
  }

  updateFromAndTo() {
    if (this.fromBuilder != null) this.dateTime.from = this.fromBuilder(new FixDate());
    if (this.toBuilder != null) this.dateTime.to = this.toBuilder(new FixDate());

    this.view = this.labelBuilder(this.label, this);
  }
}

const formatDate = (date) => date == null ? '' : new Intl.DateTimeFormat('ru-Ru').format(date.getDateObj());

export default {
  components: {
    DropDownInput,
  },
  data: () => ({
    curSelection: null,
    lastNormSelection: null,
    defaultSelectionIdx: 1,
    selectionList: [
      new Period('Текущий день', 7, (date) => date.moveToBeginToday(), (date) => date.moveToEndToday()),
      new Period('Вчерашний день', 8, (date) => date.moveToBeginToday().shiftDateFromCurrent(0, 0, -1), (date) => date.moveToBeginToday()),
      new Period('Текущая неделя', 9, (date) => date.moveToBeginCurrentWeek(), (date) => date.moveToEndCurrentWeek()),
      new Period('Прошлая неделя', 10, (date) => date.moveToBeginCurrentWeek().shiftDateFromCurrent(0, 0, -7), (date) => date.moveToBeginCurrentWeek()),
      new Period('Текущий месяц', 0, (date) => date.moveToBeginCurrentMonth(), (date) => date.moveToEndCurrentMonth()),
      new Period('Прошлый месяц', 1, (date) => date.moveToBeginCurrentMonth().shiftDateFromCurrent(0, -1, 0), (date) => date.moveToBeginCurrentMonth()),
      new Period('Текущий год', 2, (date) => date.moveToBeginCurrentYear(), (date) => date.moveToEndCurrentYear()),
      new Period('Прошлый год', 3, (date) => date.moveToBeginCurrentYear().shiftDateFromCurrent(-1, 0, 0), (date) => date.moveToBeginCurrentYear()),
      new Period('Позапрошлый год', 5, (date) => date.moveToBeginCurrentYear().shiftDateFromCurrent(-2, 0, 0), (date) => date.moveToBeginCurrentYear().shiftDateFromCurrent(-1, 0, 0)),
      new Period('Последние 3 года', 6, (date) => date.moveToBeginCurrentYear().shiftDateFromCurrent(-3, 0, 0), (date) => date.moveToEndCurrentYear()),
      new Period('Произвольный: ', 4, null, null, (label, obj) => label + `${
        (
          typeof obj.dateTime.from == 'string' ?
          formatDate(new FixDate(obj.dateTime.from)) :
          formatDate(obj.dateTime.from)
        )
      } - ${
        (
          typeof obj.dateTime.to == 'string' ?
          formatDate(new FixDate(obj.dateTime.to)) :
          formatDate(obj.dateTime.to)
        )
      }`),
    ],
  }),
  props: {
    label_text: {required: true, type: String},
    elementStatus: {required: false, default: null},
  },
  watch: {
    curSelection() {
      if (this.curSelection.uid != 4) this.lastNormSelection = this.curSelection;

      this.customDateRange.dateTime.from = this.curSelection.dateTime.from;
      this.customDateRange.dateTime.to = this.curSelection.dateTime.to;

      this.$emit('inputChanged', this.customDateRange.dateTime);
    },
  },
  emits: ['inputChanged'],
  mounted() {
    this.setNewSelect(this.dropdownData[this.defaultSelectionIdx]);
  },
  methods: {
    async getUserCustom(state, obj) {
      const newCustomDate = await window.openWindow({
        caption: 'Выберите период',
        component: ManualDateRangeSelector,
        componentProps: {
          initSelectorPeriod: this.curSelection.dateTime,
        },
      });

      if (newCustomDate == null) {
        return false;
      }

      obj.dateTime.from = newCustomDate.from;
      obj.dateTime.to = newCustomDate.to;
      obj.updateFromAndTo();

      return true;
    },
    async dateRangePaginator(pageNum, pageSize, searchViews, searchIds) {
      if (this.dropdownData == null) return [];

      const filteredList = this.dropdownData.filter(
          (elem) => (
            (searchViews == null && searchIds == null) ||
            (searchViews == null ? false : searchViews.filter((searchElem) => elem.view.toUpperCase().includes(searchElem.toUpperCase())).length > 0) ||
            (searchIds == null ? false : searchIds.filter((searchElem) => elem.uid == searchElem).length > 0)
          ),
      );

      return filteredList.slice(pageNum * pageSize, pageNum * pageSize + pageSize);
    },

    setNewSelect(selectedData) {
      this.curSelection = selectedData;
    },
  },
  computed: {
    customDateRange() {
      return this.selectionList[this.selectionList.length - 1];
    },
    selectedItems() {
      return this.curSelection != null ? [this.curSelection] : [];
    },
    dropdownData() {
      this.selectionList.forEach((period, idx) => {
        period.idx = idx;
        period.updateFromAndTo();
      });
      this.selectionList[this.selectionList.length-1].setCanSelect(this.getUserCustom);
      return this.selectionList;
    },
  },
};
</script>
