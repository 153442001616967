import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
import store from '@/store';

const REQUEST_LINK = '/auth/get_user_info/';

function getUserInfo(handler, isOnlyPing) {
  const headers = !isOnlyPing ? undefined : {
    'Ping-Package': true,
  };

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
    headers: headers,
  }).then(async function(response) {
    if (response.status != 200) {
      store.commit(
          'critical_error_mut',
          {
            type: 'break_user',
            shortInfo: 'Ошибка при запросе данных пользователя!',
            fullInfo: `На сервере произошла ошибка! Возможно идут плановые работы, либо ваш случай уникален и нужно обратиться в поддержку. Статус: ${response.status} Причина: ${(await result.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
          },
      );
      return;
    }

    const userData = await response.json();
    handler(userData);
  }).catch(function(error) {
    store.commit(
        'critical_error_mut',
        {
          type: 'break_user',
          shortInfo: 'Нет соединения с сервером!',
          fullInfo: 'Проверьте подключение к интернету или попробуйте зайти позже.',
        },
    );
  });
}

export default getUserInfo;
