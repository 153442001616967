import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/subscription_plan/add_subscription_plan/?plan_name=';

function addSubscriptionPlan(handler, planName) {
  // store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + String(planName), {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    // const objectTypeData = await response.json();
    // store.commit('set_full_screen_loading', false);
    handler(response.status == 200);
  }).catch((errData) => {
    handler(false);
  });
}

export default addSubscriptionPlan;
