import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/auth/get_country_list/';

function getCountryList(handler) {
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    if (response.status != 200) return;

    const data = await response.json();
    handler(data);
  }).catch(() => {});
}

export default getCountryList;
