import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/subscription_plan/get_active_plan/';

function getActiveSubscriptionPlan(handler) {
  // store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    // const objectTypeData = await response.json();
    // store.commit('set_full_screen_loading', false);
    if (response.status != 200) {
      handler(false);
      return;
    }

    handler(await response.json());
  }).catch((errData) => {
    handler(false);
  });
}

export default getActiveSubscriptionPlan;
