<template>
  <div id="app-background"></div>
  <div id="global-modal"></div>

  <template v-if="!this.is_first_load">
    <router-view v-if="this.withoutBasePage" v-slot="{ Component }">
      <transition name="slow-opacity-fade" appear>
        <component v-show="!this.work_blocked" :is="Component" />
      </transition>
    </router-view>
    <template v-else>
      <transition name="slow-opacity-fade" appear>
        <BasePage v-show="!this.work_blocked" :ext_page_name="this.page_name"/>
      </transition>
      <Teleport to="#page-content">
        <router-view :pageNameChanger="this.setPageName"/>
      </Teleport>
    </template>
  </template>

  <WindowsController />

  <BlockPage  :is_disabled="!this.work_blocked"
              :display_load_status="this.is_first_load"
              @start_work_click="this.startWork"
            />

  <IsOfflineMessage />
  <IsExperementsProd />
</template>

<script>

import WindowsController from '@/windows/WindowsController.vue';

import BlockPage from '@/baseLayout/BlockPage.vue';
import getUserInfo from '@/assets/dataSources/auth/getUserInfo';
import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure';
import BasePage from '@/baseLayout/BasePage';

import IsOfflineMessage from '@/baseLayout/topPanel/IsOfflineMessage.vue';
import IsExperementsProd from '@/baseLayout/topPanel/IsExperementsProd.vue';

export default {
  mounted() {
    // document.body.className = 'light';
    // document.body.className = 'dark';

    let storageTheme = null; // localStorage.getItem('theme_type');
    try {
      storageTheme = localStorage.getItem('theme_type');
    } catch {}
    this.$store.commit('setTheme', storageTheme == null ? 'dark' : storageTheme);

    window.escapeableElements.push(this.blockWork);
    this.loadBaseData();

    this.initWidthThresholds();
  },
  unmounted() {
    const index = window.escapeableElements.indexOf(this.blockWork);
    if (index > -1) {
      window.escapeableElements.splice(index, 1);
    }
  },
  data: () => ({
    page_name: null,
    work_blocked: true,
    is_first_load: true,
  }),
  watch: {
    $route(to, from) {
      if (this.isAuthMode) {
        this.startWork();
      }

      if (String.comparePaths(to.path, from.path)) return;
      this.page_name = null;
    },
    getCriticalError() {
      if (this.getCriticalError == null) return;

      this.is_first_load = true;
      this.work_blocked = true;
    },
  },
  computed: {
    isAuthMode() {
      return (
        this.$route.name == 'Login' ||
        this.$route.name == 'Register' ||
        this.$route.name == 'ResetPassword' ||
        this.$route.name == 'AcceptAccount'
      );
    },
    withoutBasePage() {
      return this.$route.meta.noBasePage == true;
    },
    getCriticalError() {
      return this.$store.state.critical_error;
    },
  },
  components: {
    WindowsController,
    BlockPage,
    BasePage,
    IsOfflineMessage,
    IsExperementsProd,
  },
  methods: {
    initWidthThresholds() {
      const appElem = document.getElementById('app');
      const appStyles = window.getComputedStyle(appElem, null);

      window.transitionThreshold0 = parseInt(appStyles.getPropertyValue('--transition-threshold-0').slice(0, -2));
      window.transitionThreshold1 = parseInt(appStyles.getPropertyValue('--transition-threshold-1').slice(0, -2));
      window.transitionThreshold2 = parseInt(appStyles.getPropertyValue('--transition-threshold-2').slice(0, -2));
      window.transitionThreshold3 = parseInt(appStyles.getPropertyValue('--transition-threshold-3').slice(0, -2));
    },
    startWork() {
      this.work_blocked = false;
      this.is_first_load = false;
    },
    blockWork() {
      if (this.work_blocked) return true;
      this.work_blocked = true;
    },
    loadBaseData() {
      getUserInfo((data) => {
        this.$store.commit('user_obj_mut', data);

        getWebSiteStructure((data) => {
          this.$store.commit('web_site_struct_mut', data);
        });
      });
    },
    setPageName(newName) {
      this.page_name = newName;
    },
  },
};

</script>

<style  lang="less">
@import url('./assets/less/components.less');

#app {
  --transition-threshold-0: @transition-threshold-0;
  --transition-threshold-1: @transition-threshold-1;
  --transition-threshold-2: @transition-threshold-2;
  --transition-threshold-3: @transition-threshold-3;

  height: 100%;

  .page-background;
}
</style>
