<template>
    <div v-if="this.isExperements && this.show" class="is-experements-wraper">
      <div class="is-experements">
        <LabelComponent label_text="Это демонстрационный контур!" />
        <br/>
        <LabelComponent label_text="Команда ZeroFactor не несет ответственности за" />
        <LabelComponent label_text="недостоверность или потерю данных на этом сайте!"/>
        <br/>
        <LabelComponent label_text="Если вы перешли сюда по ошибке," />
        <LabelComponent label_text="пожалуйста продолжите работу на:"/>
        <br/>
        <a href="https://app.zerofactor.ru">https://app.zerofactor.ru</a>
        <br/><br/>
        <LabelComponent label_text="Закрыть" @click="this.show = false" style="cursor: pointer; color: red;"/>
      </div>
    </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import {IS_EXPEREMENTS_PROD} from '@/store';

export default {
  components: {
    LabelComponent,
  },
  data: () => ({
    show: true,
  }),
  mounted() {
  },
  computed: {
    isExperements() {
      return IS_EXPEREMENTS_PROD;
    },
  },
};
</script>

<style lang = "less">

.is-experements-wraper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1001;
  .flex(row, flex-start, flex-start);
}

.is-experements {
  padding: 13px;
  background: #808080B0;
  color: white;
  border-radius: 5px;

  & a {
    color: white;
    text-decoration: underline;
  }
}

</style>
