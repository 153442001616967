import baseTheme from '@/assets/less/base_theme.less';
import lightTheme from '@/assets/less/light_theme.less';
import darkTheme from '@/assets/less/dark_theme.less';
import oldTheme from '@/assets/less/old_theme.less';
import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import mobileInjection from './ProjectInjections/MobileAppInjection';
// import standardTypesInjections from './ProjectInjections/StandardTypesInjection';
import pageBehaviourInjection from './ProjectInjections/PageBehaviourInjection';
import baseJsCacheControl from './ProjectInjections/BaseJsCacheControl';

async function run() {
  await mobileInjection();
  // standardTypesInjections();
  pageBehaviourInjection();
  baseJsCacheControl();

  createApp(App).use(store).use(router).mount('#app');
}

run();
