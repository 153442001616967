<template>
  <div class="pie-chart">
    <svg class="pie-chart__donut"
         :viewBox="`0 0 ${this.chartDiameter} ${this.chartDiameter}`"
         ref="main_area"
         @click="this.changeTotalModeEvent"
         :style="{
          'min-height' : this.diagramHeight + 'px',
          'min-width': this.diagramWidth + 'px',
          'max-height' : this.diagramHeight + 'px',
          'max-width': this.diagramWidth + 'px',
         }">

      <g v-if="!this.isEmpty" :style="`transform: rotate(${90 + this.angle}deg); transform-origin: center;`">
        <circle v-for="curData, dataId in this.series" :key="dataId"
                v-show="curData.angle >= 0.9"
                :cx="this.chartRadius" :cy="this.chartRadius"
                :r="this.chartRadius - this.segmentWidth / 2"
                fill="transparent"
                :style="{stroke: this.seriesColor(curData.startAngle)}"
                :stroke-width="this.segmentWidth"
                :stroke-dasharray="`${this.getAngleLength(curData.angle)}, ${this.getAngleLength(360 - curData.angle)}`"
                :stroke-dashoffset="-this.getAngleLength(curData.startAngle)"
                />

          <Transition name="transparent__fade">
            <circle v-if="!this.isTotalMode"
                    :cx="this.chartRadius" :cy="this.chartRadius"
                    :r="this.chartRadius - this.segmentWidth / 2"
                    fill="transparent"
                    class="pie-chart__stroke-background-color"
                    :stroke-width="this.segmentWidth"
                    :stroke-dasharray="`${this.getAngleLength(360)}, ${this.getAngleLength(0)}`"
                    :stroke-dashoffset="-this.getAngleLength(0)"
                    />
          </Transition>
          <Transition name="transparent__fade">
            <circle v-if="!this.isTotalMode"
                    v-show="this.normSelAngle.a"
                    :cx="this.chartRadius" :cy="this.chartRadius"
                    :r="this.chartRadius - this.segmentWidth / 2"
                    fill="transparent"
                    :style="{stroke: this.seriesColor(this.series[this.curSelectedSeries].startAngle)}"
                    :stroke-width="this.segmentWidth"
                    :stroke-dasharray="`${this.getAngleLength(this.normSelAngle.a)}, ${this.getAngleLength(360 - this.normSelAngle.a)}`"
                    :stroke-dashoffset="-this.getAngleLength(this.normSelAngle.s)"
                    />
          </Transition>
      </g>

      <circle class="pie-chart__donut__piece"
          ref="last_chart_segment"
          :cx="this.chartRadius" :cy="this.chartRadius"
          :r="this.chartRadius" fill="transparent"/>

      <circle :cx="this.chartRadius" :cy="this.chartRadius"
          :r="this.chartRadius - this.segmentWidth" fill="transparent"/>

      <polygon class="pie-chart__donut__arrow"
          :transform="`translate(${this.chartRadius}, ${this.chartDiameter - this.segmentWidth - (this.isRotate ? this.chartRadius * 0.14 : this.chartRadius * 0.06)})`"
          :points="`${-this.chartRadius * 0.07}, ${-this.chartRadius * 0.14} ${this.chartRadius * 0.07}, ${-this.chartRadius * 0.14} 0,0`" />

      <foreignObject style="pointer-events: none;"
                      :x="0"
                      :y="0"
                      :width="this.chartDiameter"
                      :height="this.chartRadius - this.chartRadius * 0.25">
        <div xmlns="http://www.w3.org/1999/xhtml" class="pie-chart__donut__selected-item">
          {{ this.selectedItemName }}
        </div>
      </foreignObject>

      <text class="pie-chart__donut__total"
          dominant-baseline="middle"
          :x="this.chartRadius"
          :y="this.chartRadius - this.chartRadius * 0.07">
        {{ this.selectedTotal }}
      </text>

      <text class="pie-chart__donut__meas" v-if="this.isTotalMode"
          dominant-baseline="middle"
          :x="this.chartRadius" :y="this.chartRadius + this.chartRadius * 0.085">
        {{ this.unitName }}
      </text>

      <text class="pie-chart__donut__meas" v-else
          dominant-baseline="middle"
          :x="this.chartRadius" :y="this.chartRadius + this.chartRadius * 0.085">
        {{ this.selectedItemPercent }} %
      </text>

      <text
          class="pie-chart__donut__meas"
          dominant-baseline="middle"
          :x="this.chartRadius" :y="this.chartRadius + this.chartRadius * 0.25">
        {{ this.pointName }}
      </text>
    </svg>

    <ChartLegend  v-if="!this.isEmpty"
                  @dataSelect="(el) => this.selectElement(el, true)"
                  :drawData="this.legendData"
                  :selectedData="this.curSelectedSeries"
                  :legendHeight="this.legendHeight"
                  :legendWidth="this.legendWidth"
                  @dataHover="(dataIdx) => this.hoverElem = dataIdx"
                  />
  </div>
</template>


<script type="text/javascript">
import ChartLegend from './ChartLegend.vue';

import gestures from '@/assets/gestures.js';

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

function getDecartVectorsAngle(vA, vB) {
  const lenA = Math.sqrt((vA.x * vA.x) + (vA.y * vA.y));
  const lenB = Math.sqrt((vB.x * vB.x) + (vB.y * vB.y));

  let direction = Math.sign(vA.x * vB.y - vA.y * vB.x);
  direction = direction == 0 ? 1 : direction;

  const multiply = (vA.x * vB.x) + (vA.y * vB.y);
  const ans = direction * (Math.acos(multiply / (lenA * lenB)) * 180) / Math.PI;
  return isNaN(ans) ? 0 : ans;
}

export default {
  components: {
    ChartLegend,
  },
  computed: {
    isTotalMode() {
      return this.isRotate ? true : (this.hoverElem != null ? false : this.totalMode);
    },
    curSelectedSeries() {
      return (!this.isRotate && this.hoverElem != null) ? this.hoverElem : this.selectedSeries;
    },
    normSelAngle() {
      const maxAngle = (!this.isRotate && this.hoverElem != null) ? 360 : 170;
      const angle = Math.min(maxAngle, Math.max(this.series[this.curSelectedSeries].angle, 10));
      const startPoint = this.series[this.curSelectedSeries].startAngle - (angle - this.series[this.curSelectedSeries].angle) * 0.5;
      return {
        a: angle,
        s: startPoint,
      };
    },
    selectedItemPercent() {
      return (this.series[this.curSelectedSeries]?.percent * 100).toFixed(2);
    },
    selectedTotal() {
      return (
        this.isTotalMode ?
        this.totalValue :
        parseFloat(this.series[this.curSelectedSeries]?.value).toLocaleString(
            'ru',
            {'minimumFractionDigits': 2, 'maximumFractionDigits': 2},
        )
      );
    },
    selectedItemName() {
      return this.isTotalMode ? 'Всего' : this.series[this.curSelectedSeries]?.view;
    },
    inactiveblockColor() {
      return '#FFFFFFE0';
    },
    legendData() {
      this.series.forEach((elem, idx) => {
        elem.color = this.seriesColor(elem.startAngle);
        elem.view = elem.name;
      });
      return this.series;
    },
    chartDiameter() {
      return this.viewBoxHeight;
    },
    chartRadius() {
      return this.chartDiameter / 2;
    },
    chartInnerRadius() {
      return this.chartRadius - this.segmentWidth;
    },
  },
  props: [
    'diagramWidth',
    'diagramHeight',
    'viewBoxWidth',
    'viewBoxHeight',
    'legendHeight',
    'legendWidth',

    'selectedSeries',

    'segmentWidth',
    'angle',

    'totalValue',
    'unitName',
    'pointName',
    'series',

    'isEmpty',

    'totalMode',
    'multData',
  ],
  emits: [
    'elementSelected',
    'changeTotalMode',
    'chartPointerMove',
  ],
  data: () => ({
    isRotate: false,
    hoverElem: null,
  }),
  methods: {
    changeTotalModeEvent(event) {
      if (event.target.classList.contains('pie-chart__donut__piece')) return;
      this.$emit('changeTotalMode');
    },
    seriesIdByAngle(angle) {
      return this.series.reduce(
          (findId, item, idx) => (item.startAngle <= angle) && (angle <= item.startAngle + item.angle) ? idx : findId,
          -1,
      );
    },
    selectElement(seriesId, selectFromLegend=false) {
      this.$emit('elementSelected', seriesId, selectFromLegend);
    },
    pointerPositionToLocal(px, py) {
      const mainRect = this.$refs.main_area.getBoundingClientRect();
      const radius = this.diagramWidth / 2;
      return {x: px - (mainRect.left + radius), y: py - (mainRect.top + radius)};
    },
    dragStart() {
      this.isRotate = true;
      this.rotateStartAngle = this.angle;
    },
    dragEnd(endData) {
      this.isRotate = false;
      if (this.rotateStartAngle == null) return;
      const startAngle = this.rotateStartAngle;
      this.rotateStartAngle = undefined;

      if (Math.abs(startAngle - this.angle) > 1) {
        const selectAngle = 360 - this.angle;
        this.selectElement(this.seriesIdByAngle(selectAngle));
        return;
      }

      let pointerClickAngle = getDecartVectorsAngle(
          {x: 0, y: -1},
          this.pointerPositionToLocal(endData.x, endData.y),
      ) + 180 - this.angle;
      pointerClickAngle = pointerClickAngle < 0 ? pointerClickAngle + 360 : pointerClickAngle;

      this.selectElement(this.seriesIdByAngle(pointerClickAngle));
    },
    hoverController(hoverMoveData) {
      let pointerClickAngle = getDecartVectorsAngle(
          {x: 0, y: -1},
          this.pointerPositionToLocal(hoverMoveData.x, hoverMoveData.y),
      ) + 180 - this.angle;
      pointerClickAngle = pointerClickAngle < 0 ? pointerClickAngle + 360 : pointerClickAngle;

      this.hoverElem = this.seriesIdByAngle(pointerClickAngle);
    },
    cancelHover(hoverMoveData) {
      this.hoverElem = null;
    },
    dragController(dragData) {
      const fp = this.pointerPositionToLocal(dragData.pX, dragData.pY);
      const sp = this.pointerPositionToLocal(dragData.pX + dragData.x, dragData.pY + dragData.y);
      const deltaAngle = getDecartVectorsAngle(fp, sp);

      this.$emit('chartPointerMove', deltaAngle);
    },
    getAngleLength(angle) {
      return ((angle / 360) * (Math.PI * (this.chartDiameter - this.segmentWidth))).toFixed(2);
    },
    seriesColor(angleStart) {
      return hslToHex(160 + (1 - angleStart/360) * 160, 60, 50)+'f0';
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.last_chart_segment == null) return;

      this.gestController = gestures(this.$refs.last_chart_segment);
      this.gestController.registerDrag(this.dragController, this.dragStart, this.dragEnd);
      this.gestController.registerHover(null, this.hoverController, this.cancelHover);
    }, 1);
  },
  beforeUnmount() {
    if (this.gestController != null) this.gestController.destruct();
  },
};
</script>

<style lang="less">
.transparent {
  &__fade-enter-active,
  &__fade-leave-active {
    transition: opacity 200ms;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    opacity: 0;
  }
}

.pie-chart {
  .flex(row, center, center);
  flex-wrap: wrap;

  &__stroke-background-color {
    stroke: @content-background-color;
  }

  &__donut {
    // margin-right: 13px;
    // margin-bottom: 13px;

    &__arrow {
      fill: @base-text-color;
      transition: all 200ms linear;
    }

    &__total {
      text-anchor: middle;
      font-weight: bold;
      font-size: 11px;
      fill: @base-text-color;
    }

    &__meas {
      text-anchor: middle;
      font-size: 8px;
      fill: @base-text-color;
    }

    &__selected-item {
      align-content: end;
      place-content: end;
      text-align: center;
      height: 100%;
      width: 100%;
      font-size: 8px;
      color: @no-accent-text-color;
    }

    &__piece {
      cursor: pointer;
    }
  }
}
</style>
