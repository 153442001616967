<template>
  <div class="data-source-editor-wraper">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>
    <ChangeObjectForm
                      :raw_fields="this.drawFields"
                      :raw_actions="this.drawActions"
                      @completeFormAction="this.saveUG"
                    />
  </div>
</template>

<script type="text/javascript">
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelComponent from '@/units/RichLabel.vue';

import addNewSubscriptionGroup from '@/assets/dataSources/subscription/addNewSubscriptionGroup.js';

export default {
  components: {
    ChangeObjectForm,
    LabelComponent,
  },
  data: () => ({
    savingError: null,
    isSaving: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    saveUG(saveForm) {
      if (this.isSaving) return;

      this.savingError = null;
      this.isSaving = true;

      addNewSubscriptionGroup((result) => {
        this.isSaving = false;

        if (result._error == true) {
          if (result.status == null) this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.savingError = `${result.text} Статус: ${result.status}`;
          return;
        }

        this.$emit('result', 'ok');
      }, saveForm);
    },
  },
  computed: {
    ug_uid() {
      return this.params.ug_uid;
    },
    initial_name() {
      return this.params.initial_name == null ? '' : this.params.initial_name;
    },
    drawFields() {
      return [
        {
          'type': 'text',
          'view': 'ug_uid',
          'system_name': 'ug_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true,
          'value': this.ug_uid,
        },
        {
          'type': 'text',
          'view': 'Наименование',
          'system_name': 'name',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'value': this.initial_name,
        },
      ];
    },
    drawActions() {
      return [
        {
          system_name: 'save',
          view: 'Сохранить',
          is_loading: this.isSaving,
        },
      ];
    },
  },
};
</script>
