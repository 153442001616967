const localPages = [
  {
    'uid': 'local__0',
    'name': 'Настройки',
    'url': 'settings',
    'open_new_tab': false,
    'icon_url': '/icons/system/gear.svg',
    'page_type': 'group',
    'parent_page_uid': null,
    'priority': 0,
    'fast_panel_priority': 999,
    'in_fast_panel': true,
    'internal_page': true,
  },
  {
    'uid': 'local__1',
    'name': 'Управление подпиской',
    'url': 'subscription',
    'open_new_tab': false,
    'icon_url': '/icons/system/finres_icon.svg',
    'page_type': 'subscription',
    'parent_page_uid': 'local__0',
    'priority': 10,
    'fast_panel_priority': null,
    'in_fast_panel': false,
    'internal_page': true,
  },
  {
    'uid': 'local__2',
    'name': 'Профиль',
    'url': 'profile',
    'open_new_tab': false,
    'icon_url': '/icons/system/badge-002.svg',
    'page_type': 'profile',
    'parent_page_uid': 'local__0',
    'priority': 20,
    'fast_panel_priority': null,
    'in_fast_panel': false,
    'internal_page': true,
  },
  {
    'uid': 'local__3',
    'name': 'Документация',
    'url': 'https://docs.zerofactor.ru/',
    'open_new_tab': true,
    'icon_url': '/icons/system/help_round.svg',
    'page_type': 'ext_link',
    'parent_page_uid': null,
    'priority': 30,
    'fast_panel_priority': 999,
    'in_fast_panel': false,
    'internal_page': true,
  },
];


export default (rawPagesStruct, forUser) => {
  if (forUser == null || forUser.is_anonymous) return;

  localPages.forEach((elem) => {
    rawPagesStruct.push(elem);
  });
};
