<template>
  <div class="input-block" @click="this.set_focus" ref="input_root">
    <div :class="this.get_input_block_field_classes">
      <div class="input-block__text">
        <div class="input-block__title-wrapper">
          <LabelComponent label_component_type="label"
                          :label_type="this.get_hint_label_type(this.is_focused)"
                          :label_text="this.label_text"/>
          <LabelComponent v-if="this.is_required"
                          class="input-block__title__required-symbol"
                          label_component_type="label"
                          :label_type="this.get_hint_label_type(this.is_focused)"
                          :label_text="' *'"/>
        </div>
        <LabelComponent v-if="this.type.startsWith('date') || this.type.startsWith('time')"
                          class="date-field-text"
                          :label_text="this.formatedDate"/>
        <textarea v-else-if="this.type === 'textarea'" class="input-block__input"
                  v-model="this.data_values"
                  @blur="this.focusout"
                  :name="this.name"
                  cols="28"
                  rows="5"
                  :disabled="this.input_field_status === 'disabled'"/>
        <input v-else class="input-block__input"
               ref="input_field"
               v-model="this.data_values"
               @blur="'this.focusout'"
               @keyup="this.keyup"
               :name="this.name"
               :type="this.type"
               :min="this.min"
               :max="this.max"
               :disabled="this.input_field_status === 'disabled' || this.block_edit"
               :autocomplete="this.autocomplete"
               :accept="this.getAcceptTypes"
               :pattern="this.check_pattern"/>
      </div>

      <IconComponent class="input-block__icon"
                     size="24"
                     sizeUnit="%"
                     v-show="this.get_icon_name"
                     :url="this.get_icon_name"/>


    <div v-if="this.file_preview_link" class="preview_container">
      <IconComponent
                    size="100"
                    sizeUnit="%"
                    type="png"
                    :url="this.file_preview_link"/>
      <ButtonComponent
        class="preview_remove_button"
        elementType="hover"
        @click.stop="this.clearData"
        iconUrl="/icons/system/close.svg"
        iconSize="22"/>
    </div>

      </div>
      <div class="date-pick-area"
           v-if="this.input_field_status !== 'disabled' && !this.block_edit && (this.type.startsWith('date') || this.type.startsWith('time')) && this.is_focused"
           ref="datepicker"
           @click.stop="''"
           >
        <ButtonComponent
            v-if="this.type.includes('time')"
            elementType="default-bg"
            label="Применить"
            @click.stop="this.focusout()"
            /><br/>
        <div class="datetime-wraper">
          <div style="margin-right: 23px;" v-if="this.type.includes('date')">
            <LabelComponent label_text="Дата"/>
            <br/>
            <!-- <DatePicker
                        :restrictions_start="this.min ? this.min : new FixDate(-8640000000000000)"
                        :restrictions_end="this.max ? this.max : new FixDate(8640000000000000)"
                        :init_date="this.date_value"
                        @select_changed_by_click="(val) => {this.date_value = val; this.type.includes('time') ? '' : this.focusout();}"
                        /> -->
          </div>
          <div v-if="this.type.includes('time')">
            <LabelComponent label_text="Время"/>
            <br/>
            <!-- <TimePicker
                        :init_time="this.time_value"
                        @select_changed="(val) => { this.time_value = val; }"/> -->
          </div>
        </div>
      </div>
    <LabelComponent class="input-block__error-text"
                    label_type="caption/small/regular 10"
                    v-if="this.input_field_status==='error'"
                    :label_text="this.error_text"/>

  </div>
</template>

<script>
import IconComponent from '@/units/RichIcon.vue';
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  name: 'InputField',
  components: {
    LabelComponent,
    IconComponent,
    ButtonComponent,
  },
  emits: [
    'input_change',
    'focus_change',
    'keyups',
    'update:modelValue',
  ],
  data: function() {
    return {
      input_block_classes: [],
      is_clicked: false,
      is_focused: false,
      // input_text: '',
      data_values: '',
      date_value: null,
      time_value: null,
      file_preview_link: null,
    };
  },
  props: {
    name: {
      type: String,
      required: false,
    },
    modelValue: {
      default: '',
      type: String,
    },
    // значения поля, заданные снаружи
    set_values: {
      required: false,
      // type: String,
    },
    style_type: {
      type: String,
      validator: (prop) => ['default', 'light'].includes(prop),
      default: 'default',
    },
    type: {
      type: String,
      validator: (prop) => ['text', 'email', 'textarea', 'date', 'datetime', 'time', 'password', 'tel', 'file', 'search', 'number']
          .includes(prop),
      default: 'text',
    },
    min: {
      default: undefined,
    },
    max: {
      default: undefined,
    },
    block_edit: {
      default: false,
    },
    input_field_status: {
      required: false,
      validator: (prop) => ['focused', 'error', 'disabled'].includes(prop),
    },
    input_mask: {
      type: String,
      default: undefined,
    },
    label_text: {
      type: String,
      default: 'Заголовок по умолчанию',
    },
    autocomplete: {
      type: String,
      required: false,
      default: undefined,
    },
    error_text: {
      type: String,
      default: 'Неизвестная ошибка, обратитесь к администратору',
    },
    is_all_time_open: {
      type: Boolean,
      default: false,
    },
    is_required: {
      type: Boolean,
      default: false,
    },
    accept_files_type: {
      required: false,
      default: 'all',
      validator: (prop) => ['images', 'audio', 'video', 'all'].includes(prop),
    },
    check_pattern: {
      type: String,
      default: '.+',
    },
    pattern_invalid_message: {
      type: String,
      default: 'Input a valid text!',
    },
  },
  watch: {
    modelValue(newData) {
      if (newData) {
        // newData = String(newData);
        this.data_values = newData;
      }
    },
    set_values(newData) {
      this.convertExtDataToInternal();
    },
    date_value() {
      if (this.date_value == this.data_values) {
        return;
      }
      this.buildDatetimeResult();
    },
    time_value() {
      if (this.time_value == this.data_values) {
        return;
      }
      this.buildDatetimeResult();
    },
    data_values(newData) {
      if (this.type == 'file') {
        if (this.$refs.input_field.files.length > 0) {
          newData = this.$refs.input_field.files[0];
        } else {
          newData = null;
        }
      }

      if (!this.is_valid()) {
        newData = null;
      }

      if (this.type == 'file') {
        this.setFilePreviewLink(newData);
      }

      // сообщаем родителю, что данные изменены изнутри
      this.$emit('input_change', newData);
      this.$emit('update:modelValue', newData);
    },
  },
  created() {
    document.addEventListener('click', this.checkCloseDatepicker);
  },
  mounted() {
    // маска на поле ввода
    if (this.input_mask) {
      (new Inputmask(this.input_mask)).mask(this.$refs.input_field);
    }
    this.convertExtDataToInternal();
  },
  unmounted() {
    document.removeEventListener('click', this.checkCloseDatepicker);
  },
  computed: {
    formatedDate() {
      if (!this.data_values) {
        return '-';
      }

      const dateText = this.data_values.toLocaleDateString('ru-RU', {timeZone: 'UTC'});

      if (this.type == 'date') {
        return dateText;
      }

      const timeText = this.data_values.toLocaleTimeString('ru-RU', {timeZone: 'UTC'}).substring(0, 5);

      if (this.type == 'time') {
        return timeText;
      }

      // datetime
      return dateText + ' ' + timeText;
    },
    getAcceptTypes() {
      if (this.type != 'file') {
        return undefined;
      }

      if (this.accept_files_type == 'images') {
        return 'image/*';
      }

      if (this.accept_files_type == 'audio') {
        return 'audio/*';
      }

      if (this.accept_files_type == 'video') {
        return 'video/*';
      }

      return undefined;
    },
    get_input_block_field_classes() {
      const classes = {
        'input-block__field': true,
        'input-block__filled': this.is_focused || this.data_values || this.is_all_time_open,
        'input-block__focused': this.is_focused,
        'input-block__text-area': this.type === 'textarea',
      };

      if (this.input_field_status) {
        classes[`input-block__${this.input_field_status}`] = true;
      }

      if (this.style_type === 'light') {
        const lightClasses = {};
        // eslint-disable-next-line guard-for-in
        for (const key in classes) {
          lightClasses[key + '__light'] = classes[key];
        }
        return lightClasses;
      }

      return classes;
    },
    get_icon_name() {
      switch (this.type) {
        case 'text':
          return null;
        case 'date':
          return '/icons/system/calendar.svg';
        case 'datetime':
          return '/icons/system/calendar.svg';
        case 'time':
          return '/icons/system/time.svg';
        case 'search':
          return '/icons/system/magnifying-glass.svg';
      };
      return null;
    },
  },
  methods: {
    buildDatetimeResult() {
      if (this.type == 'date') {
        this.data_values = this.date_value;
        return;
      }

      if (this.type == 'time') {
        if (this.time_value == null) {
          this.data_values = null;
          return;
        }
        const res = new FixDate(0);
        // res.setUTCHours(this.time_value.getUTCHours());
        // res.setUTCMinutes(this.time_value.getUTCMinutes());
        this.data_values = res;
        return;
      }

      // datetime

      if (this.date_value == null || this.time_value == null) {
        this.data_values = null;
        return;
      }

      // this.data_values = Date.dateUTC(
      //     this.date_value.getUTCFullYear(),
      //     this.date_value.getUTCMonth(),
      //     this.date_value.getUTCDate(),
      //     this.time_value.getUTCHours(),
      //     this.time_value.getUTCMinutes(),
      // );
    },
    checkCloseDatepicker(event) {
      if (!this.is_focused) {
        return;
      }

      // закрытие выпадающего списка если кликнули вне
      const containerDropdown = this.$refs.datepicker;
      const containerRoot = this.$refs.input_root;
      if (containerDropdown != null && !containerDropdown.contains(event.target) && !containerRoot.contains(event.target) && document.body.contains(event.target)) {
        this.focusout();
      }
    },
    clearData() {
      this.data_values = null;
      if (this.type == 'file') {
        this.$refs.input_field.value = null;
      }
    },
    convertExtDataToInternal() {
      if (!this.is_valid() && this.set_values == null) {
        return;
      }

      if (this.type == 'file') {
        this.setFilePreviewLink(this.set_values);
        return;
      }

      if (this.type.startsWith('date') || this.type.includes('time')) {
        if (typeof this.set_values == typeof '') {
          this.data_values = new FixDate(this.set_values);
        } else {
          this.data_values = this.set_values;
        }

        this.date_value = this.data_values == null ? this.date_value : this.data_values;
        this.time_value = this.data_values == null ? this.time_value : this.data_values;
        return;
      }

      this.data_values = this.set_values;
    },
    setFilePreviewLink(baseData) {
      if (this.accept_files_type != 'images') {
        this.file_preview_link = null;
        return;
      }

      if (baseData == null) {
        this.file_preview_link = baseData;
        return;
      }

      if (typeof baseData == typeof '') {
        this.file_preview_link = baseData;
        return;
      }

      this.file_preview_link = URL.createObjectURL(new Blob([baseData], {type: baseData.type}));
    },
    is_valid() {
      if (this.$refs.input_field == null) {
        return true;
      }

      this.$refs.input_field.setCustomValidity('');

      const ans = this.$refs.input_field.reportValidity();

      if (!ans && this.$refs.input_field.validity.patternMismatch) {
        this.$refs.input_field.setCustomValidity(this.pattern_invalid_message);
      }

      return ans;
    },
    get_files() { // only to file input
      return this.$refs.input_field.files;
    },
    focusout() {
      // console.log('WAT2???');
      this.is_focused = false;
      this.$emit('focus_change', this.is_focused);
    },
    set_focus() {
      // для textarea не требуется вызывать focus()
      // console.log('WAT???');
      if (this.type !== 'textarea') {
        this.is_focused = true;
        this.$emit('focus_change', this.is_focused);
        if (this.$refs.input_field != null) this.$refs.input_field.focus();
      }
    },
    get_hint_label_type(isFocused) {
      if (isFocused || this.data_values || this.is_all_time_open) {
        return 'caption/small/regular 10';
      } else {
        return 'body/large/regular 14';
      }
    },
    keyup() {
      this.$emit('keyups', this.data_values);
    },
  },
};
</script>


<style lang="less">
@input__border__color: @stroke-gray;
@input__icon__color: @light-gray;
@input__text__color: @light-gray;

@input__filled__border__color: @gray;
@input__focused__border__color: @green;
@input__error__border__color: @error;
@input__error__text__color: @error;

@input__disabled__background-color: @super-light;
@input__disabled__border__color: @super-light;
@input__disabled__text__color: @light-gray;

// .date-field-text {
//   color: fieldtext;
// }

.date-pick-area {
  position: absolute;
  // min-width: 200px;
  top: 100%;
  // right: 0;
  left: 0;
  margin-top: 10px;
  padding: 12px;
  z-index: 500;
  background: #ffffffff;
  border-radius: 10px;
  box-shadow: 0 13px 40px rgba(46, 50, 45, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;

  .datetime-wraper {
    display: flex;
    flex-direction: row;
  }
}

.preview_container {
  width: 30%;
  position: relative;
  .preview_remove_button {
    position: absolute;
    top: 14px;
    right: 14px;
  }

  .preview_remove_button * {
    cursor: pointer;
  }
}

.input-block * {
  cursor: text;
}

.input-block {
  .flex(column, flex-start, flex-start);
  width: 100%;
  //min-width: 250px;
  resize: none;
  position: relative;

  &__field, &__field__light {
    .flex(row, flex-start, center);
    min-height: 58px;
    width: 100%;
    border-radius: 6px;
    padding: 0 15px;

    background-color: transparent;

    .set_icon_color(@input__icon__color);
  }

  &__field {
    border: 1px solid @input__border__color;
  }

  &__field__light {
    border: 1px dashed transparent;
    border-bottom-color: @input__border__color;

    &:hover {
      border: 1px solid @input__focused__border__color;
    }
  }

  &__text {
    .flex(column, flex-start, stretch);
    flex-grow: 1;
    user-select: none;
    .set_color(@no-accent-text-color);
  }

  &__title {
    &-wrapper {
      line-height: 0;
    }

    &__required-symbol {
      color: @error;
    }
  }

  &__input {
    width: 100%;
    background-color: transparent;
    border: 0 solid transparent;
    height: 0;
    position: absolute;
  }

  &__icon {
    margin-left: 10px;
    cursor: pointer;
  }

  &__filled, &__filled__light {
    border: 1px solid @input__filled__border__color;
    resize: inherit;

    .input-block__input {
      height: 100%;
      color: @base-text-color;
      position: relative;
      overflow: visible;
    }
  }

  &__filled {
    border: 1px solid @input__filled__border__color;
  }

  &__filled__light {
    border: 1px dashed transparent;
    border-bottom-color: @input__border__color;
  }

  &__focused, &__focused__light {
    border: 1px solid @input__focused__border__color;
  }

  &__error, &__error__light {
    border: 1px solid @input__error__border__color;

    &-text {
      color: @input__error__text__color;
      margin: 6px 0;
    }
  }

  &__disabled, &__disabled__light {
    //pointer-events: none;
    background-color: @input__disabled__background-color;
    border-color: @input__disabled__border__color;
    color: @input__disabled__text__color;
  }
}

.input-block__text {
  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-outer-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    .hide_icon_to_input()
  }

  input[type="time"]::-webkit-inner-spin-button,
  input[type="time"]::-webkit-outer-spin-button,
  input[type="time"]::-webkit-calendar-picker-indicator {
    .hide_icon_to_input();
  }

  input[type="date"]::-webkit-date-and-time-value,
  input[type="time"]::-webkit-date-and-time-value {
    text-align: left;
    color: @dark;
  }
}

textarea {
  resize: vertical;
}

.input-block__text-area, .input-block__text-area__light {
  padding-top: 12px;
  padding-bottom: 12px;
}

</style>
