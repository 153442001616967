<template>
    <div class="flex-row">
      <LabelComponent
          class="icon-selector-label"
          :label_text="this.label_text + (this.label_text && this.label_text.length > 0 ? ':' : '')"/>
      <ButtonComponent
                      elementType="default"
                      :iconUrl="this.selectedIcon" iconSize="27"
                      class="icon-select-button"
                      @click="this.input_field_status != 'disabled' ? this.selectIconWindow() : ''"
      />
    </div>
</template>

<script type="text/javascript">
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';

import IconSelector from './IconSelector.vue';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
  },
  emits: ['input_change'],
  data: () => ({
    selectedIcon: null,
  }),
  watch: {
    selectedIcon() {
      this.$emit('input_change', this.selectedIcon);
    },
    initial_data() {
      this.selectedIcon = this.initial_data;
    },
  },
  props: {
    label_text: {
      default: '',
      type: String,
      required: false,
    },
    initial_data: {
      default: null,
      type: String,
      required: false,
    },
    input_field_status: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
  },
  mounted() {
    this.selectedIcon = this.initial_data;
  },
  computed: {

  },
  methods: {
    async selectIconWindow() {
      const newIcon = await window.openWindow({
        caption: 'Выберите иконку',
        component: IconSelector,
      });

      if (newIcon == null) return;

      this.selectedIcon = newIcon;
    },
  },
};
</script>
