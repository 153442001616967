<template>
  <div style="margin: auto; padding-top:32px; width: fit-content;">
    <LabelComponent label_text="Механизм редактирования объектов находится в разработке. Следите за нашими новостями для получения подробностей!"/>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';


export default {
  components: {
    LabelComponent,
  },
  data: ()=> ({
  }),
  props: {
  },
  mounted() {

  },
  computed: {
  },
  methods: {
  },
};
</script>
