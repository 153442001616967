<template>
  <HybridChartPainter
                          :diagramWidth="this.diagramWidth"
                          :diagramHeight="this.maxDiagramHeight"
                          :viewBoxWidth="this.chartViewBoxWidth"
                          :viewBoxHeight="this.chartViewBoxHeight"
                          :legendHeight="this.maxDiagramHeight"
                          :legendWidth="this.legendWidth"
                          :drawData="this.drawObjects"
                          :userMultipliers="this.userMultipliers"
  />
</template>

<script type="text/javascript">
import HybridChartPainter from './ChartPainters/HybridChartPainter.vue';

export default {
  components: {
    HybridChartPainter,
  },
  data: () => ({
    // scaleValueRuler: 1,
    // scaleSeriesRuler: 1,
    // transitionValueRuler: 0,
    // transitionSeriesRuler: 0,
    chartViewBoxWidth: 200,
    chartViewBoxHeight: 100,
  }),
  computed: {
    diagramWidth() {
      if (this.legendWidth < 150) return this.maxChartWidth;
      return this.maxChartWidth * (3/5) - 13;
    },
    legendWidth() {
      if (this.maxChartWidth == null) return null;
      return this.maxChartWidth * (2/5);
    },
    hybridRawValues() {
      const ans = this.typeName == 'Смешанный' ? this.rawValues : [
        {
          'type': String(this.typeName).includes('Гистогр') ? 'histogram' : 'graph',
          'ruler': 'main',
          'draw': String(this.typeName).includes('накопл') ? 'accumulate' : 'separate',
          'series': this.rawSeries,
          'data': this.rawValues,
        },
      ];

      // console.log('VALUES', ans);

      return ans;
    },
    seriesList() {
      const ans = [];
      this.hybridRawValues.forEach((curDiagram) => Object.values(curDiagram.series).forEach((series) => {
        series.idx = ans.length;
        ans.push(series);
      }));
      return ans;
    },
    drawObjects() {
      class DrawObj {
        constructor(accumulateMode, parent) {
          this.accumulateMode = accumulateMode;
          this._mainRulerUse = false;
          this._subRulerUse = false;
          this._mainMax = 0;
          this._mainMin = 0;
          this._subMax = 0;
          this._subMin = 0;
          this.parent = parent;
          this.mainTotal = 0;
          this.subTotal = 0;
        }

        get ruler() {
          return 'main';
        }

        addTotal(value, ruler = null) {
          if (ruler == null) ruler = this.ruler;
          value = value != null ? value : 0;
          this[ruler + 'Total'] += value;
          if (this.parent != null) this.parent.addTotal(value, ruler);
        }

        get value() {
          return this[this.ruler + 'Total'];
        }

        set mainMax(value) {
          this._mainRulerUse = true;
          if (this.accumulateMode) value < 0 ? null : (this._mainMax += value);
          else this._mainMax = Math.max(this._mainMax, value);
          if (this.parent != null) this.parent.mainMax = this._mainMax;
        }

        set mainMin(value) {
          this._mainRulerUse = true;
          if (this.accumulateMode) value > 0 ? null : (this._mainMin += value);
          else this._mainMin = Math.min(this._mainMin, value);
          if (this.parent != null) this.parent.mainMin = this._mainMin;
        }

        set subMax(value) {
          this._subRulerUse = true;
          if (this.accumulateMode) value < 0 ? null : (this._subMax += value);
          else this._subMax = Math.max(this._subMax, value);
          if (this.parent != null) this.parent.subMax = this._subMax;
        }

        set subMin(value) {
          this._subRulerUse = true;
          if (this.accumulateMode) value > 0 ? null : (this._subMin += value);
          else this._subMin = Math.min(this._subMin, value);
          if (this.parent != null) this.parent.subMin = this._subMin;
        }

        get mainRulerUse() {
          return this._mainRulerUse;
        }

        get subRulerUse() {
          return this._subRulerUse;
        }

        get mainMax() {
          if (!this.mainRulerUse) return 100;
          if (this._mainMax == this._mainMin) return this._mainMax + 10;
          return this._mainMax;
        }

        get mainMin() {
          if (!this.mainRulerUse) return -100;
          if (this._mainMax == this._mainMin) return this._mainMin - 10;
          return this._mainMin;
        }

        get subMax() {
          if (!this.subRulerUse) return 100;
          if (this._subMax == this._subMin) return this._subMax + 10;
          return this._subMax;
        }

        get subMin() {
          if (!this.subRulerUse) return -100;
          if (this._subMax == this._subMin) return this._subMin - 10;
          return this._subMin;
        }
      }

      class DrawObjsParent extends DrawObj {
        constructor(seriesList, rawPoints) {
          super(false, null);
          this.type = 'parent';
          this.chunkColumnCounts = {};
          if (seriesList != null) this.seriesList = seriesList;
          if (rawPoints != null) this.points = rawPoints;
          this.diagramSizeY = 0;
          this.diagramSizeX = 0;
          this.child = [];
        }
      }

      class DrawAccumColumn extends DrawObj {
        constructor(parent, colIdx, diagram, chunkIdx, point) {
          super(true, parent);
          this.type = 'accum_column';
          this.colIdx = colIdx;
          this.diagram = diagram;
          this.chunkIdx = chunkIdx;
          this.point = point;
          this.child = [];
        }

        get ruler() {
          return this.diagram.ruler;
        }
      }

      class DrawElement extends DrawObj {
        constructor(parent, type, diagram, chunkIdx, series, point, data) {
          super(true, parent);
          this.type = type;
          this.diagram = diagram;
          this.chunkIdx = chunkIdx;
          this.series = series;
          this.point = point;
          this.data = data;

          this.addTotal(this.data.view, this.ruler);

          this[this.ruler + 'Max'] = this.value;
          this[this.ruler + 'Min'] = this.value;
        }

        get ruler() {
          return this.diagram.ruler;
        }
      }

      const ans = new DrawObjsParent(this.seriesList, this.rawPoints);

      ans.seriesList.forEach((series) => series.total = 0);
      Object.values(ans.points).forEach((point) => [point.mainTotal = 0, point.subTotal = 0]);

      this.hybridRawValues.forEach((curDiagram) => {
        const seriesLastDrawObj = {};
        Object.keys(this.rawPoints).forEach((pointKey, idx) => {
          const chunkIdx = idx;
          ans.chunkColumnCounts[chunkIdx] = ans.chunkColumnCounts[chunkIdx] == null ? 0 : ans.chunkColumnCounts[chunkIdx];

          let curDrawObjects = ans;

          if (curDiagram.type == 'histogram' && curDiagram.draw == 'accumulate') {
            ans.chunkColumnCounts[chunkIdx] += 1;
            const column = new DrawAccumColumn(ans, ans.chunkColumnCounts[chunkIdx]-1, curDiagram, chunkIdx, this.rawPoints[pointKey]);
            curDrawObjects.child.push(column);
            curDrawObjects = column;
          }

          Object.keys(curDiagram.series).forEach((seriesKey) => {
            const drawObj = new DrawElement(
                curDrawObjects,
                curDiagram.type == 'histogram' ? 'column' : 'point',
                curDiagram,
                chunkIdx,
                curDiagram.series[seriesKey],
                this.rawPoints[pointKey],
                curDiagram.data[seriesKey+pointKey],
            );

            drawObj.point[drawObj.ruler + 'Total'] += drawObj.value;
            drawObj.series.total += drawObj.value;

            if (curDiagram.type == 'histogram') {
              if (curDiagram.draw != 'accumulate') ans.chunkColumnCounts[chunkIdx] += 1;
              drawObj.colIdx = ans.chunkColumnCounts[chunkIdx] - 1;
            }

            if (curDiagram.type == 'graph' && seriesLastDrawObj[seriesKey] != null) {
              const graphLine = {
                type: 'line',
                diagram: drawObj.diagram,
                series: drawObj.series,
                sPoint: seriesLastDrawObj[seriesKey].point,
                ePoint: drawObj.point,
                sData: seriesLastDrawObj[seriesKey].data,
                eData: drawObj.data,
                sChunkIdx: seriesLastDrawObj[seriesKey].chunkIdx,
                eChunkIdx: drawObj.chunkIdx,
                ruler: drawObj.ruler,
                sValue: seriesLastDrawObj[seriesKey].value,
                eValue: drawObj.value,
              };
              curDrawObjects.child.push(graphLine);
            }
            seriesLastDrawObj[seriesKey] = drawObj;
            curDrawObjects.child.push(seriesLastDrawObj[seriesKey]);
          });
        });
      });

      ans.chunkColumnCounts = Math.max(Math.max.apply(null, Object.values(ans.chunkColumnCounts)), 1);

      // console.log('drawObjects', ans);

      return ans;
    },
  },
  methods: {

  },
  watch: {
    maxChartWidth() {
      this.recalculateViewBoxWidth();
    },
    maxDiagramHeight() {
      this.recalculateViewBoxWidth();
    },
  },
  methods: {
    recalculateViewBoxWidth() {
      if (this.maxChartWidth == null) {
        this.chartViewBoxWidth = 200;
        return;
      }

      this.chartViewBoxWidth = this.diagramWidth * this.chartViewBoxHeight / this.maxDiagramHeight;
    },
  },
  mounted() {
    this.recalculateViewBoxWidth();
  },
  beforeUnmount() {

  },
  props: {
    maxChartWidth: {
      default: null,
      required: false,
    },
    maxDiagramHeight: {
      default: 250,
      required: false,
    },
    isVertical: {
      default: false,
    },
    typeName: {
      default: 'Смешанный',
      required: false,
    },
    rawSeries: {
      default: null,
    },
    rawPoints: {
      default: {
        '_0': {
          'view': 'Divuar Cacao company',
          'field': 'Контрагент',
        },
        '_1': {
          'view': 'Palm paradise meals Ltd.',
          'field': 'Контрагент',
        },
        '_2': {
          'view': 'Агроферма "Коровино"',
          'field': 'Контрагент',
        },
        '_3': {
          'view': 'База "Продукты" ',
          'field': 'Контрагент',
        },
        '_4': {
          'view': 'Конфетпром ООО',
          'field': 'Контрагент',
        },
        '_5': {
          'view': 'Малако Беларусі',
          'field': 'Контрагент',
        },
      },
    },
    rawValues: {
      default: [
        {
          'type': 'histogram',
          'ruler': 'main',
          'draw': 'separate',
          'series': {
            '_0': {
              'view': 'Аванс на начало периода',
              'field': 'АвансНаНачало',
            },
            '_1': {
              'view': 'Аванс на конец периода',
              'field': 'АвансНаКонец',
            },
          },
          'data': {
            '_0_0': {
              'view': 0,
              'field': 'АвансНаНачало',
            },
            '_1_0': {
              'view': 2452,
              'field': 'АвансНаКонец',
            },
            '_0_1': {
              'view': 1045,
              'field': 'АвансНаНачало',
            },
            '_1_1': {
              'view': 8658,
              'field': 'АвансНаКонец',
            },
            '_0_2': {
              'view': 6542,
              'field': 'АвансНаНачало',
            },
            '_1_2': {
              'view': -782,
              'field': 'АвансНаКонец',
            },
            '_0_3': {
              'view': 900,
              'field': 'АвансНаНачало',
            },
            '_1_3': {
              'view': 855,
              'field': 'АвансНаКонец',
            },
            '_0_4': {
              'view': -8658,
              'field': 'АвансНаНачало',
            },
            '_1_4': {
              'view': 5000,
              'field': 'АвансНаКонец',
            },
            '_0_5': {
              'view': 6742,
              'field': 'АвансНаНачало',
            },
            '_1_5': {
              'view': 50,
              'field': 'АвансНаКонец',
            },
          },
        },
        {
          'type': 'graph',
          'ruler': 'sub',
          'series': {
            '_0': {
              'view': 'Аванс на начало периода',
              'field': 'АвансНаНачало',
            },
            '_1': {
              'view': 'Аванс на конец периода',
              'field': 'АвансНаКонец',
            },
          },
          'data': {
            '_0_0': {
              'view': 0,
              'field': 'ДолгНаНачало',
            },
            '_1_0': {
              'view': 1003307.45,
              'field': 'ДолгНаКонец',
            },
            '_0_1': {
              'view': 0,
              'field': 'ДолгНаНачало',
            },
            '_1_1': {
              'view': 2892389.31,
              'field': 'ДолгНаКонец',
            },
            '_0_2': {
              'view': 3871447.5,
              'field': 'ДолгНаНачало',
            },
            '_1_2': {
              'view': 3172532.5,
              'field': 'ДолгНаКонец',
            },
            '_0_3': {
              'view': 3890072.5,
              'field': 'ДолгНаНачало',
            },
            '_1_3': {
              'view': 3803350,
              'field': 'ДолгНаКонец',
            },
            '_0_4': {
              'view': 2629702.5,
              'field': 'ДолгНаНачало',
            },
            '_1_4': {
              'view': 6289623.21,
              'field': 'ДолгНаКонец',
            },
            '_0_5': {
              'view': 0,
              'field': 'ДолгНаНачало',
            },
            '_1_5': {
              'view': 4423012.17,
              'field': 'ДолгНаКонец',
            },
          },
        },
        {
          'type': 'histogram',
          'ruler': 'sub',
          'draw': 'accumulate',
          'series': {
            '_0': {
              'view': 'Аванс на начало периода',
              'field': 'АвансНаНачало',
            },
            '_1': {
              'view': 'Аванс на конец периода',
              'field': 'АвансНаКонец',
            },
          },
          'data': {
            '_0_0': {
              'view': 0,
              'field': 'ДолгНаНачало',
            },
            '_1_0': {
              'view': 1003307.45,
              'field': 'ДолгНаКонец',
            },
            '_0_1': {
              'view': 0,
              'field': 'ДолгНаНачало',
            },
            '_1_1': {
              'view': 2892389.31,
              'field': 'ДолгНаКонец',
            },
            '_0_2': {
              'view': 3871447.5,
              'field': 'ДолгНаНачало',
            },
            '_1_2': {
              'view': 3172532.5,
              'field': 'ДолгНаКонец',
            },
            '_0_3': {
              'view': -3890072.5,
              'field': 'ДолгНаНачало',
            },
            '_1_3': {
              'view': 6289623.21,
              'field': 'ДолгНаКонец',
            },
            '_0_4': {
              'view': 10289623.21,
              'field': 'ДолгНаНачало',
            },
            '_1_4': {
              'view': -10289623.21,
              'field': 'ДолгНаКонец',
            },
            '_0_5': {
              'view': 0,
              'field': 'ДолгНаНачало',
            },
            '_1_5': {
              'view': 4423012.17,
              'field': 'ДолгНаКонец',
            },
          },
        },
      ],
    },
    userMultipliers: {
      default: [
        {
          view: '10⁻¹⁰',
          mult: Math.pow(10, -10),
        },
        {
          view: '10⁻⁸',
          mult: Math.pow(10, -8),
        },
        {
          view: '10⁻⁶',
          mult: Math.pow(10, -6),
        },
        {
          view: '10⁻⁴',
          mult: Math.pow(10, -4),
        },
        {
          view: '10⁻²',
          mult: Math.pow(10, -2),
        },
        {
          extended: true,
          view: '10⁻¹',
          mult: Math.pow(10, -1),
        },
        {
          view: 'ед',
          mult: 1,
        },
        {
          extended: true,
          view: 'дес',
          mult: Math.pow(10, 1),
        },
        {
          extended: true,
          view: 'сот',
          mult: Math.pow(10, 2),
        },
        {
          view: 'тыс',
          mult: Math.pow(10, 3),
        },
        {
          view: 'млн',
          mult: Math.pow(10, 6),
        },
        {
          view: 'млрд',
          mult: Math.pow(10, 9),
        },
        {
          view: 'трлн',
          mult: Math.pow(10, 12),
        },
        {
          view: 'квадр',
          mult: Math.pow(10, 15),
        },
      ],
    },
  },
};
</script>
