import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
import JsEncrypt from 'jsencrypt';

const CRYPT_SESSION_ADDRESS = '/auth/get_crypt_session/';
const CRYPT_REGISTER_ADDRESS = '/auth/crypt_register/';

function getCryptSession(handler) {
  fetch(ROOT_URL+CRYPT_SESSION_ADDRESS, {
    method: 'GET',
  }).then(async (result) => {
    if (result.status != 200) {
      handler({
        _error: true,
        status: result.status,
        text: 'Ошибка получения ключа шифрования: ' + (await result.text()).trimLen(MAX_ERROR_TEXT_LEN),
      });
      return;
    }
    handler({
      key_data: await result.json(),
    });
  }).catch(() => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения при получении ключа шифрования',
    });
  });
}

export default function registerRequest(handler, {email, password, firstName, lastName, country}) {
  const cryptData = {
    email: email,
    password: password,
  };

  const additionalData = {
    first_name: firstName,
    last_name: lastName,
    country: country,
  };

  getCryptSession((cryptSession) => {
    if (cryptSession._error) {
      handler(cryptSession);
      return;
    }

    const cryptSessionKey = cryptSession.key_data.session;
    const cryptPublicKey = cryptSession.key_data.public_key;

    const en = new JsEncrypt();
    en.setPublicKey(cryptPublicKey);
    const encrypted = en.encrypt(JSON.stringify(cryptData));

    // console.log('encrypted', encrypted, JSON.stringify(cryptData), JSON.stringify(cryptData).length);

    fetch(
        ROOT_URL+CRYPT_REGISTER_ADDRESS + `?additional=${JSON.stringify(additionalData)}`,
        {
          method: 'GET',
          credentials: 'include',
          headers: {
            'ENCRYPTED-DATA': encrypted,
            'CRYPT-SESSION': cryptSessionKey,
          },
        },
    ).then(async (regResult) => {
      if (regResult.status != 200) {
        handler({
          _error: true,
          status: regResult.status,
          text: `${(await regResult.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
        });
        return;
      }

      handler({
        register_result: await regResult.text(),
      });
    }).catch((error) => {
      console.log('error', error);
      handler({
        _error: true,
        status: null,
        text: 'Ошибка подключения при регистрации',
      });
    });
  });
}
