import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/subscription/set_page_access/?page_uid=';

function getPageAccessData(handler, pageUid) {
  fetch(ROOT_URL + REQUEST_LINK + pageUid, {
    method: 'GET',
    credentials: 'include',
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.json());
    }
  }).catch((errData) => {

  });
}

export default getPageAccessData;
