<template>
  <div class="data-source-editor-wraper">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>
    <ChangeObjectForm
                      :raw_fields="this.drawFields"
                      :raw_actions="this.drawActions"
                      @completeFormAction="this.saveDS"
                    />
  </div>
</template>

<script type="text/javascript">
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelComponent from '@/units/RichLabel.vue';

import addNewDataSource from '@/assets/dataSources/subscription/addNewDataSource';
import editDataSource from '@/assets/dataSources/subscription/editDataSource.js';

const DS_TYPES = {
  '1C_IAPI': {
    'view': 'Система 1С',
    'uid': '1C_IAPI',
  },
  'FILE_STORAGE': {
    'view': 'Файловое хранилище',
    'uid': 'FILE_STORAGE',
  },
  'DL_IAPI': {
    'view': 'Система DataLens',
    'uid': 'DL_IAPI',
  },
};

export default {
  components: {
    ChangeObjectForm,
    LabelComponent,
  },
  data: () => ({
    savingError: null,
    isSaving: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    saveDS(saveForm) {
      if (this.isSaving) return;

      this.savingError = null;
      this.isSaving = true;

      if (this.ds_uid == null) {
        addNewDataSource((result) => {
          this.isSaving = false;

          if (result._error == true) {
            if (result.status == null) this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
            else this.savingError = `${result.text} Статус: ${result.status}`;
            return;
          }

          this.$emit('result', 'ok');
        }, saveForm);
      } else {
        editDataSource((result) => {
          this.isSaving = false;

          if (result._error == true) {
            if (result.status == null) this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
            else this.savingError = `${result.text} Статус: ${result.status}`;
            return;
          }

          this.$emit('result', 'ok');
        }, saveForm);
      }
    },
  },
  computed: {
    ds_uid() {
      if (!this.params.dsData) return null;
      return this.params.dsData.uid;
    },
    initial_name() {
      if (!this.params.dsData) return '';
      return this.params.dsData.view;
    },
    ds_type() {
      if (!this.params.dsData) return null;
      return this.params.dsData.type;
    },
    drawFields() {
      return [
        {
          'type': 'text',
          'view': 'ds_uid',
          'system_name': 'ds_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true,
          'value': this.ds_uid,
        },
        {
          'type': 'text',
          'view': 'Наименование',
          'system_name': 'name',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'value': this.initial_name,
        },
        this.ds_uid == null ? {
          'type': 'choice',
          'view': 'Тип',
          'system_name': 'type',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'item_list': Object.values(DS_TYPES),
          'selected_items': [DS_TYPES['1C_IAPI']],
        } : {
          'type': 'text',
          'view': 'Тип',
          'system_name': '_type_view',
          'nullable': false,
          'readonly': true,
          'is_hidden': false,
          'value': DS_TYPES[this.ds_type].view,
        },
      ];
    },
    drawActions() {
      return [
        {
          system_name: 'save',
          view: 'Сохранить',
          is_loading: this.isSaving,
        },
      ];
    },
  },
};
</script>
