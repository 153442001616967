<template>
  <div>
    <Teleport to="#page-tools">
      <div>
        <ButtonComponent iconUrl="/icons/system/file-plus.svg" elementType="default" iconSize="27" @click="this.openPageForm" />
      </div>
    </Teleport>

    <SpinnerComponent v-if="this.isPreloading" style="width: 50px;" :width="5" />
    <LabelComponent v-if="this.formRequestError != null" :label_text="this.formRequestError" style="color: red;"/>

    <ReportPainter
                :inputsDisabled="this.isReportDataRequesting"
                :report_form="this.curReportForm"
                :raw_report_data="this.curReportData"
                @reportFormFilled="this.requestReportData"
                />

    <LabelComponent v-if="this.dataRequestError != null" :label_text="this.dataRequestError" style="color: red;"/>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';

import getPreviewReportForm from '@/assets/dataSources/reports/getPreviewReportForm.js';
import buildPreviewReport from '@/assets/dataSources/reports/buildPreviewReport.js';
import getDataSourceInfo from '@/assets/dataSources/subscription/getDataSourceInfo.js';

import ReportPainter from './ReportPainter.vue';
import PageForm from '@/windows/PageForm.vue';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
    ReportPainter,
    SpinnerComponent,
  },
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        return;
      }

      this.updateReportPath();
      this.reloadReport();
    },
    curReportForm() {
      if (this.curReportForm == null) {
        this.pageNameChanger('Предпросмотр отчета');
        return;
      }

      this.pageNameChanger(
          'Источник - ' + this.curSourceRawInfo.view +
          ': Предпросмотр отчета: ' + this.curReportForm.name,
      );
    },
    curSourceRawInfo() {
      if (this.curSourceRawInfo == null) return;
      this.pageNameChanger('Источник - ' + this.curSourceRawInfo.view + ': Предпросмотр отчета');
    },
  },
  data: ()=> ({
    formRequestError: null,
    dataRequestError: null,
    requestVersion: 0,
    isPreloading: false,
    isReportDataRequesting: false,
    curSourceRawInfo: null,
    curSourceUid: null,
    curReportUid: null,
    curReportForm: null,
    curReportData: null,
  }),
  props: {
    pageNameChanger: null,
  },
  mounted() {
    // this.pageNameChanger('kek');
    this.updateReportPath();
    this.reloadReport();
  },
  computed: {
  },
  methods: {
    openPageForm() {
      window.openWindow({
        caption: 'Добавление страницы отчета',
        component: PageForm,
        componentProps: {
          page_type: 'report',
          initial_name: this.curReportForm ? this.curReportForm.name : null,
          data_source_uid: this.curSourceUid,
          initial_object_uid: this.curReportUid,
        },
      });
    },
    updateReportPath() {
      this.curSourceUid = this.$route.params.sourceUid;
      this.curReportUid = this.$route.params.reportUid;
    },
    reloadReport() {
      this.formRequestError = null;
      this.dataRequestError = null;
      this.isReportDataRequesting = false;
      this.curReportForm = null;
      this.curReportData = null;

      this.curSourceRawInfo = null;

      this.requestVersion += 1;
      this.isPreloading = true;

      const requestReportFormVersion = this.requestVersion;

      getDataSourceInfo((result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        if (result._error == true) {
          if (result.status == null) this.formRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.formRequestError = `${result.text} Статус: ${result.status}`;
          return;
        }

        this.curSourceRawInfo = result;

        getPreviewReportForm(this.curReportUid, this.curSourceRawInfo, (result) => {
          if (requestReportFormVersion != this.requestVersion) return;

          this.isPreloading = false;

          if (result._error == true) {
            if (result.status == null) this.formRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
            else this.formRequestError = `${result.text} Статус: ${result.status}`;
            return;
          }

          this.curReportForm = result;
        });
      }, this.curSourceUid);
    },
    requestReportData(formData) {
      if (this.isReportDataRequesting) return;

      this.dataRequestError = null;

      this.curReportData = null;
      this.isReportDataRequesting = true;

      this.requestVersion += 1;
      const requestReportFormVersion = this.requestVersion;

      buildPreviewReport(this.curReportUid, this.curSourceRawInfo, formData, (result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        this.isReportDataRequesting = false;

        if (result._error == true) {
          if (result.status == null) {
            this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else {
            this.dataRequestError = `${result.text} Статус: ${result.status}`;
          }
          return;
        }

        this.curReportData = result;
      });
    },
  },
};
</script>
