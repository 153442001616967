<template>
  <div>
    <div v-for="(item, key) in this.levelData" :key="key">
      <linkTo :href="this.normaliseItemLink(item.fullUrl)" :newTab="item.open_new_tab === true"
                    class="menu__item"
                    :class="{'menu__item_active': this.isItemSelected(item, key)}">

          <div class="menu__content" :style="{'margin-left': this.currentDepth*17+'px'}">
            <IconComponent size=24 :url="item.icon_url"/>
            <LabelComponent class="menu__text"
                            label_type="caption/small/medium 10"
                            :label_text="item.name" />
          </div>
          <div class="menu__pointer"/>
      </linkTo>
      <transition name="struct-node__fade">
        <StructNode :levelData="item.children"
                    :currentDepth="this.currentDepth+1"
                    v-show="this.isItemSelected(item, key)"
                  />
      </transition>
    </div>
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';
import linkTo from '@/units/linkTo.vue';

export default {
  name: 'StructNode',
  components: {
    LabelComponent,
    IconComponent,
    linkTo,
  },
  data: ()=> ({
  }),
  mounted() {
  },
  watch: {

  },
  props: {
    levelData: {default: undefined},
    currentDepth: {default: 0},
  },
  computed: {
    get_current_page_info() {
      return this.$store.state.current_page_info;
    },
    getCurrentFullUrl() {
      return this.$route.fullPath;
    },
  },
  methods: {
    normaliseItemLink(link) {
      if (!String(link).startsWith('http') && !String(link).startsWith('/') && !String(link).startsWith('\\')) {
        return '/' + String(link);
      }
      return String(link);
    },
    isItemSelected(item, key) {
      const curUrl = this.getCurrentFullUrl;
      return curUrl ? curUrl.startsWith(item.fullUrl) : false;
    },
  },
};
</script>

<style lang="less">
// @left__menu__content__text__color: @dark;
@left__menu__icon__color: @base-text-color;

// @left__menu__item__text__color: @white;
@left__menu__item__hover__background-color: @light-gray-opacity-10;
@left__menu__item__active__background-color: @light-green-opacity-10;
@left__menu__item__active__menu_pointer__color: @light-green;
@left__menu__item__active__hover__background-color: @light-gray-opacity-10;

.menu {
  &__content{
    .flex(row, flex-start, center);
    padding: 10px 10px 10px 32px;
    // color: @left__menu__content__text__color;
    .set_icon_color(@left__menu__icon__color)
  }
  &__text{
    margin-left: 14px;
  }

  &__item{
    .flex(row, space-between, stretch);
    width: 100%;
    // color: @left__menu__item__text__color;
    // text-decoration: none;

    &:hover{
      background: @left__menu__item__hover__background-color;
    }

    &_active{
      background: @left__menu__item__active__background-color;
      .menu__pointer{
        min-width: 4px;
        max-width: 4px;
        background: @left__menu__item__active__menu_pointer__color;
        display: flex;
        border-radius: 8px 0 0 8px;
      }

      &:hover{
        background: @left__menu__item__active__hover__background-color;
      }
    }
  }
}

.struct-node {
  &__fade-enter-active,
  &__fade-leave-active {
    transform-origin: top left;
    transform: scale(1);
    max-height: 3000px;
    transition: transform 0.3s, max-height 0.3s ease-in-out;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    transform-origin: top left;
    max-height: 0px;
    transform: scale(0);
  }
}
</style>
