import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
// import store from '@/store';

const REQUEST_LINK = '/ds/full_search/';

function fullSearch(
    searchNumber,
    searchRequest,
    acceptHandler,
    errorHandler,
    finalyHandler,
    query,
    ignoreDsErrors,
) {
  if (query == null) query = '0';
  if (ignoreDsErrors == null) ignoreDsErrors = [];

  const reqData = new FormData();
  reqData.set('search_data', searchRequest);
  reqData.set('query', query);

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: reqData,
  }).then(async function(response) {
    if (![200, 204, 206, 207].includes(response.status)) {
      errorHandler({
        _error: true,
        status: response.status,
        text: `Ошибка запроса поиска. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
      }, searchNumber);
      finalyHandler(searchNumber);
      return;
    }

    if (response.status == 204) {
      errorHandler({
        _error: true,
        status: response.status,
        text: 'Отсутствуют подключенные базы.',
      }, searchNumber);
      finalyHandler(searchNumber);
      return;
    }

    const resultData = await response.json();

    let continueSearch = false;
    let searchErrorStatus = null;

    const dsUid = resultData.idx;
    const dsView = resultData.view;

    // if (response.status == 200) {
    //   // handler('ok');
    // }

    if (response.status == 206) {
      continueSearch = true;
      // handler('ok');
    }

    if (response.status == 207) {
      continueSearch = resultData.continue_status == 206;
      searchErrorStatus = resultData.error_status;
      // handler('ok');
    }

    resultData.data = searchErrorStatus == 401 ? 'Доступ запрещен' : resultData.data;

    if (searchErrorStatus == 401 && !ignoreDsErrors.includes(dsUid)) {
      const authResult = await window.openExtAuthWindow(dsUid, dsView);
      if (authResult != 'ok') ignoreDsErrors.push(dsUid);
      else {
        fullSearch(searchNumber, searchRequest, acceptHandler, errorHandler, finalyHandler, query, ignoreDsErrors);
        return;
      }
    }

    continueSearch = acceptHandler(resultData, searchNumber) && continueSearch;

    if (continueSearch) {
      query = resultData.next_query;
      fullSearch(searchNumber, searchRequest, acceptHandler, errorHandler, finalyHandler, query, ignoreDsErrors);
    } else {
      finalyHandler(searchNumber);
    }
  }).catch((errData) => {
    console.log('errData', errData);

    errorHandler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    }, searchNumber);
    finalyHandler(searchNumber);
  });
}

export default fullSearch;
