import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/payment/get_payment_link/?amount=';

function getPayLink(handler, amount, returnLink) {
  const returnLinkText = returnLink ? ('&return_link='+returnLink) : '';

  // store.commit('set_full_screen_loading', true);
  fetch(ROOT_URL + REQUEST_LINK + amount + returnLinkText, {
    method: 'GET',
    credentials: 'include',
    // body: JSON.stringify({params: {}}),
  }).then(async function(response) {
    const data = await response.text();
    handler(data);
  });
}

export default getPayLink;
