<template>
  <div class="hybrid-chart">
    <div class="hybrid-chart-svg-container" :style="{
          'min-height' : this.diagramHeight + 'px', 'min-width': this.diagramWidth + 'px',
          'height' : this.diagramHeight + 'px', 'width': this.diagramWidth + 'px',
          'max-height' : this.diagramHeight + 'px', 'max-width': this.diagramWidth + 'px',
        }" ref="svg_container">
      <svg ref="main_ruler_svg"
        :width="this.mainRulerSize.gw" :height="this.mainRulerSize.gh"
        :style="{
          'min-height' : this.mainRulerSize.gh + 'px', 'min-width': this.mainRulerSize.gw + 'px',
          'max-height' : this.mainRulerSize.gh + 'px', 'max-width': this.mainRulerSize.gw + 'px',
        }"
        :viewBox="`0 0 ${this.mainRulerSize.w} ${this.mainRulerSize.h}`"
        xmlns="http://www.w3.org/2000/svg">
        <text v-for="rulerLine, idx in this.mainRulerLines" :key="idx"
              :x="this.mainRulerSize.w"
              :y="rulerLine.sP.y"
              text-anchor="end"
              dominant-baseline="middle"
              class="hybrid-chart__fill-no-accent-color"
              :style="{'font-size': this.rulerTextSize(rulerLine.mainView) + 'px'}">
              {{ rulerLine.mainView }}
        </text>
      </svg>
      <svg ref="base_svg"
        :width="this.baseDiagramSize.gw" :height="this.baseDiagramSize.gh"
        :style="{
          'min-height' : this.baseDiagramSize.gh + 'px', 'min-width': this.baseDiagramSize.gw + 'px',
          'max-height' : this.baseDiagramSize.gh + 'px', 'max-width': this.baseDiagramSize.gw + 'px',
        }"
        :viewBox="`0 0 ${this.baseDiagramSize.w} ${this.baseDiagramSize.h}`"
        xmlns="http://www.w3.org/2000/svg">

        <mask :id="'_' + this.baseMaskIdx">
          <rect fill="white"
              x="0" y="0" :width="this.baseDiagramSize.w" :height="this.baseDiagramSize.h"/>
          <line v-if="this.richInfoData != null && this.richInfoData.mask.type == 'line'"
              :x1="this.richInfoData.mask.x1"
              :y1="this.richInfoData.mask.y1"
              :x2="this.richInfoData.mask.x2"
              :y2="this.richInfoData.mask.y2"
              :stroke-width="this.richInfoData.mask.w"
              stroke="black" />

          <circle v-if="this.richInfoData != null && this.richInfoData.mask.type == 'point'"
              :cx="this.richInfoData.mask.cx"
              :cy="this.richInfoData.mask.cy"
              :r="this.richInfoData.mask.r"
              fill="black" />
        </mask>

        <!-- ------------- Горизонтальные линии линейки ------------ -->
        <line v-for="rulerLine, idx in this.mainRulerLines" :key="idx"
              :x1="rulerLine.sP.x"
              :y1="rulerLine.sP.y"
              :x2="rulerLine.eP.x"
              :y2="rulerLine.eP.y"
              :stroke-width="this.baseRulerW"
              class="hybrid-chart__stroke-no-accent-color"
              />

        <!-- ------------- Линия линейки ноль ------------ -->
        <line
              :x1="0"
              :y1="this.mainViewMatrix.translateY"
              :x2="this.baseDiagramSize.w"
              :y2="this.mainViewMatrix.translateY"
              :stroke-width="this.baseRulerW"
              class="hybrid-chart__stroke-base-color"
              />

        <!-- ------------- Вертикальные линии линейки ------------ -->
        <line v-for="rulerLine, idx in this.vRulerLines" :key="idx"
              :x1="rulerLine.uP.x"
              :y1="rulerLine.uP.y"
              :x2="rulerLine.bP.x"
              :y2="rulerLine.bP.y"
              :stroke-width="this.baseRulerW"
              class="hybrid-chart__stroke-no-accent-color"
              />

        <line v-for="rulerLine, idx in this.vRulerBorderLines" :key="idx"
              :x1="rulerLine.uP.x"
              :y1="rulerLine.uP.y"
              :x2="rulerLine.bP.x"
              :y2="rulerLine.bP.y"
              :stroke-width="this.baseRulerW"
              class="hybrid-chart__stroke-no-accent-color"
              />

        <!-- ------------- Невидимые вертикальные триггеры клика пары чанков ------------ -->
        <template v-for="rulerLine, idx in this.vRulerLines" :key="idx">
          <line v-if="idx < this.vRulerLines.length - 1"
                :data-idx="idx"
                ref="chunk_pair_triggers"
                :x1="rulerLine.uP.x + this.chunkWidth / 2"
                :y1="rulerLine.uP.y"
                :x2="rulerLine.bP.x + this.chunkWidth / 2"
                :y2="rulerLine.bP.y"
                :stroke-width="this.chunkPadding + (this.chunkWidth - this.chunkPadding) * 2"
                stroke="transparent"
                />
        </template>

        <!-- ------------- Невидимые вертикальные триггеры клика чанка ------------ -->
        <line v-for="rulerLine, idx in this.vRulerLines" :key="idx"
              ref="chunk_triggers"
              :data-idx="idx"
              :x1="rulerLine.uP.x"
              :y1="rulerLine.uP.y"
              :x2="rulerLine.bP.x"
              :y2="rulerLine.bP.y"
              :stroke-width="this.chunkWidth - this.chunkPadding"
              stroke="transparent"
              />

        <!-- ------------- Колонки гистограммы ------------ -->
        <line v-for="column, idx in this.columnData" :key="idx"
              ref="histogram_columns"
              :data-idx="idx"
              :x1="column.uP.x"
              :y1="column.uP.y"
              :x2="column.bP.x"
              :y2="column.bP.y"
              :stroke-width="this.cW"
              :stroke="column.color"
              />

        <!-- ------------- Видимые линии Графиков ------------ -->
        <line v-for="line, idx in this.lineData" :key="idx"
              :x1="line.uP.x"
              :y1="line.uP.y"
              :x2="line.bP.x"
              :y2="line.bP.y"
              :stroke-width="this.baseLW"
              :stroke="line.color" />

        <!-- ------------- Невидимые триггеры клика линий Графиков ------------ -->
        <line v-for="line, idx in this.lineData" :key="idx"
              ref="histogram_line_triggers"
              :data-idx="idx"
              :x1="line.uP.x"
              :y1="line.uP.y"
              :x2="line.bP.x"
              :y2="line.bP.y"
              :stroke-width="this.baseLW + 5"
              stroke="transparent"
              />

        <!-- ------------- Узлы линий Графиков ------------ -->
        <circle v-for="point, idx in this.pointsData" :key="idx"
              ref="histogram_point_triggers"
              :data-idx="idx"
              :cx="point.pointData.x"
              :cy="point.pointData.y"
              :r="this.basePW"
              :fill="point.color"
              :stroke-width="5"
              stroke="transparent"
              />

        <rect :mask="'url(#_' + this.baseMaskIdx + ')'"
              v-if="this.richInfoData != null"
              style="pointer-events: none;"
              class="hybrid-chart__fill-content-background"
              x="0" y="0" :width="this.baseDiagramSize.w" :height="this.baseDiagramSize.h"/>

        <image
                href="/icons/system/collapse.svg"
                :x="this.baseDiagramSize.w - this.baseDiagramSize.h * 0.18"
                :width="this.baseDiagramSize.h * 0.18"
                style="cursor: pointer;"
                @mousedown="this.resetUserTransform"
                @touchstart="this.resetUserTransform"
                />

      </svg>
      <svg ref="sub_ruler_svg"
        :width="this.subRulerSize.gw" :height="this.subRulerSize.gh"
        :style="{
          'min-height' : this.subRulerSize.gh + 'px', 'min-width': this.subRulerSize.gw + 'px',
          'max-height' : this.subRulerSize.gh + 'px', 'max-width': this.subRulerSize.gw + 'px',
        }"
        :viewBox="`0 0 ${this.subRulerSize.w} ${this.subRulerSize.h}`"
        xmlns="http://www.w3.org/2000/svg">
        <template v-if="this.hasSubRuler">
          <text v-for="rulerLine, idx in this.mainRulerLines" :key="idx"
              :x="0"
              :y="rulerLine.sP.y"
              text-anchor="start"
              dominant-baseline="middle"
              class="hybrid-chart__fill-no-accent-color"
              :style="{'font-size': this.rulerTextSize(rulerLine.mainView) + 'px'}">
              {{ rulerLine.subView }}
          </text>
        </template>
      </svg>
      <svg ref="main_ruler_mult_svg"
        :width="this.mainRulerMultSize.gw" :height="this.mainRulerMultSize.gh"
        :style="{
          'min-height' : this.mainRulerMultSize.gh + 'px', 'min-width': this.mainRulerMultSize.gw + 'px',
          'max-height' : this.mainRulerMultSize.gh + 'px', 'max-width': this.mainRulerMultSize.gw + 'px',
        }"
        :viewBox="`0 0 ${this.mainRulerMultSize.w} ${this.mainRulerMultSize.h}`"
        xmlns="http://www.w3.org/2000/svg">
          <text
              :x="this.mainRulerMultSize.w"
              :y="2"
              text-anchor="end"
              dominant-baseline="hanging"
              class="hybrid-chart__fill-no-accent-color"
              :style="{'font-size': this.rulerTextSize(mainMult.view) + 'px'}">
              {{ mainMult.view }}
          </text>
      </svg>
      <svg ref="legend_svg"
        :width="this.legendRulerSize.gw" :height="this.legendRulerSize.gh"
        :style="{
          'min-height' : this.legendRulerSize.gh + 'px', 'min-width': this.legendRulerSize.gw + 'px',
          'max-height' : this.legendRulerSize.gh + 'px', 'max-width': this.legendRulerSize.gw + 'px',
        }"
        :viewBox="`0 0 ${this.legendRulerSize.w} ${this.legendRulerSize.h}`"
        xmlns="http://www.w3.org/2000/svg">

        <template v-for="legend, idx in this.legendTexts" :key="idx">
          <path :id="legend.pathIdx" fill="transparent" tstroke="this.elementColor(idx, 10)" :d="legend.path"/>
          <text
                dominant-baseline="middle"
                class="hybrid-chart__fill-no-accent-color"
                :style="{'font-size': legend.textW + 'px'}"
          >
            <textPath :href="'#' + legend.pathIdx">
              {{ legend.view }}
            </textPath>
          </text>
        </template>
      </svg>
      <svg ref="sub_ruler_mult_svg"
        :width="this.subRulerMultSize.gw" :height="this.subRulerMultSize.gh"
        :style="{
          'min-height' : this.subRulerMultSize.gh + 'px', 'min-width': this.subRulerMultSize.gw + 'px',
          'max-height' : this.subRulerMultSize.gh + 'px', 'max-width': this.subRulerMultSize.gw + 'px',
        }"
        :viewBox="`0 0 ${this.subRulerMultSize.w} ${this.subRulerMultSize.h}`"
        xmlns="http://www.w3.org/2000/svg">
        <template v-if="this.hasSubRuler">
          <text
              :x="0"
              :y="2"
              text-anchor="start"
              dominant-baseline="hanging"
              class="hybrid-chart__fill-no-accent-color"
              :style="{'font-size': this.rulerTextSize(subMult.view) + 'px'}">
              {{ subMult.view }}
          </text>
        </template>
      </svg>
      <div ref="rich_info_svg"
        v-if="this.richInfoData != null"
        class="rich-info-window secure-content-background"
        :width="this.diagramWidth / 2" :height="this.diagramHeight"
        :style="{
          'min-height' : this.diagramHeight + 'px', 'min-width': this.diagramWidth / 2 + 'px',
          'max-height' : this.diagramHeight + 'px', 'max-width': this.diagramWidth / 2 + 'px',
          'left': (this.diagramWidth / 2) * (this.richInfoData.infoPopupIsLeft ? 0 : 1) + 'px',
        }"
        >
        <template v-for="row in this.richInfoData.popupData" :key="row">
          <LabelComponent
              v-if="row.label == null"
              :label_text="row"
          />
          <LabelComponent v-else :label_text="row.label + ': ' + row.value" />
        </template>
      </div>
    </div>
    <ChartLegend
                :drawData="this.legendData"
                :legendHeight="this.legendHeight"
                :legendWidth="this.legendWidth"
    />
  </div>
</template>

<script type="text/javascript">
import ChartLegend from './ChartLegend.vue';
import LabelComponent from '@/units/RichLabel.vue';

import gestures from '@/assets/gestures.js';
import varAnimator from '@/assets/varAnimator.js';

class ChartPoint {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }

  applyTransform(transform) {
    return this.applyManualTransform(
        transform.scaleX,
        transform.scaleY,
        transform.translateX,
        transform.translateY,
        transform.angle == null ? 0 : transform.angle,
    );
  }

  applyManualTransform(scaleX, scaleY, translateX, translateY, angle = 0) {
    const a = Math.cos(angle) * scaleX;
    const b = Math.sin(angle) * scaleX;
    const c = -Math.sin(angle) * scaleY;
    const d = Math.cos(angle) * scaleY;
    const e = translateX;
    const f = translateY;

    const newX = a * this.x + c * this.y + e;
    const newY = b * this.x + d * this.y + f;
    this.x = newX;
    this.y = newY;

    return this;
  }
}

function hslToHex(h, s, l) {
  l /= 100;
  const a = s * Math.min(l, 1 - l) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color).toString(16).padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
}

export default {
  components: {
    ChartLegend,
    LabelComponent,
  },
  data: () => ({
    rulerLineCount: 3,
    baseCW: 5,
    baseLW: 1,
    baseRulerW: 0.5,
    basePW: 1.5,
    diagramTopPadding: 15,

    rulerWidth: 18,
    legendRulerHeight: 30,

    userTransform: {sX: 0.5, sY: 0.5, pX: 0, pY: 0},

    richInfoData: null,
    baseMaskIdx: '-1',
  }),
  computed: {
    defaultDrawDataSize() {
      return {
        w: (this.chunkWidth / this.userTransform.sX) * Object.keys(this.drawData.points).length,
        h: this.mainRulerScaleY * this.mainMinus,
      };
    },
    drawDataSize() {
      return {
        w: this.chunkWidth * Object.keys(this.drawData.points).length,
        h: this.mainRulerScaleY * this.mainMinus * this.userTransform.sY,
      };
    },
    legendData() {
      return this.drawData.seriesList.map((series, idx) => ({
        view: series.view,
        value: series.total,
        color: this.elementColor(idx, this.drawData.seriesList.length),
      }));
    },
    legendTexts() {
      return Object.values(this.drawData.points).map((point, idx) => {
        const p = new ChartPoint(
            this.chunkCenters[idx],
            0,
        );
        p.x = p.x * this.mainViewMatrix.scaleX + this.mainViewMatrix.translateX;
        p.y = 0;

        const w = this.chunkWidth;

        const pathIdx = (
          '_' + this.getUniqueNumber() + '_' + String(parseInt(p.x)) + '_' + String(parseInt(p.y)) + '_path'
        );

        const textW = Math.min(w / 5, 6);

        const padding = textW / 2;

        const h = this.legendRulerSize.h - padding * 2;

        p.x -= w;

        const pathLines = [];
        let counter = 0;

        while (counter * textW < h) {
          const curMin = Math.min(h - textW * counter, w);

          const pair = `M ${p.x + w},${p.y + padding + (textW * 1.3) * counter} l ${curMin},${curMin} `;
          pathLines.push(pair);
          counter += 1;
        }

        return {p, w, h, view: point.view, pathIdx, path: pathLines.join(''), textW};
      });
    },
    pointsData() {
      return this.drawData.child.filter((elem) => elem.type == 'point').map((point, idx) => {
        const pointData = new ChartPoint(
            this.chunkCenters[point.chunkIdx],
            point.value,
        );

        if (point.ruler == 'main') {
          pointData.applyTransform(this.mainViewMatrix);
        } else {
          pointData.applyTransform(this.subViewMatrix);
        }

        const color = this.elementColor(
          point.series == null ? point.child[0].series.idx : point.series.idx,
          this.drawData.seriesList.length,
        );

        return {pointData, color, point};
      });
    },
    lineData() {
      return this.drawData.child.filter((elem) => elem.type == 'line').map((line, idx) => {
        const uP = new ChartPoint(
            this.chunkCenters[line.sChunkIdx],
            line.sValue,
        );
        const bP = new ChartPoint(
            this.chunkCenters[line.eChunkIdx],
            line.eValue,
        );
        if (line.ruler == 'main') {
          bP.applyTransform(this.mainViewMatrix);
          uP.applyTransform(this.mainViewMatrix);
        } else {
          bP.applyTransform(this.subViewMatrix);
          uP.applyTransform(this.subViewMatrix);
        }

        const color = this.elementColor(
          line.series == null ? line.child[0].series.idx : line.series.idx,
          this.drawData.seriesList.length,
        );

        return {uP, bP, color, line};
      });
    },
    columnData() {
      const packColumn = (column, endVal) => {
        const uP = new ChartPoint(
            column.colIdx * (this.innerChunkPadding + this.cW) +
            this.cW / 2 +
            this.chunkPadding / 2 +
            this.chunkWidth * column.chunkIdx +
            0,
            endVal,
        );
        const bP = new ChartPoint(uP.x, endVal - column.value);
        if (column.ruler == 'main') {
          bP.applyTransform(this.mainViewMatrix);
          uP.applyTransform(this.mainViewMatrix);
        } else {
          bP.applyTransform(this.subViewMatrix);
          uP.applyTransform(this.subViewMatrix);
        }

        if (Math.abs(uP.y - bP.y) < 1) uP.y = bP.y - 1;

        const color = this.elementColor(
          column.series == null ? column.child[0].series.idx : column.series.idx,
          this.drawData.seriesList.length,
        );

        return {uP, bP, color, column: column};
      };

      const ans = [];
      this.drawData.child.filter(
          (elem) => elem.type == 'column' || elem.type == 'accum_column',
      ).forEach((column) => {
        if (column.type == 'accum_column' && column[column.ruler + 'Max'] - column[column.ruler + 'Min'] > 0) {
          let curMaxVal = 0;
          let curMinVal = 0;

          column.child.slice().reverse().forEach((subCol) => {
            if (subCol.value == 0) return;
            if (subCol.value < 0) {
              curMinVal -= subCol.value;
              ans.push(packColumn(subCol, curMinVal));
            } else {
              curMaxVal += subCol.value;
              ans.push(packColumn(subCol, curMaxVal));
            }
          });
        } else {
          ans.push(packColumn(column, column.value));
        }
      });

      return ans;
    },
    vRulerBorderLines() {
      return [this.chunkPadding/2, this.drawDataSize.w - this.chunkPadding/2].map((centerX, idx) => {
        const uP = new ChartPoint(centerX, this.drawData.mainMax).applyTransform(this.mainViewMatrix);
        const bP = new ChartPoint(centerX, this.drawData.mainMin).applyTransform(this.mainViewMatrix);
        return {
          uP: {x: uP.x + this.diagramTopPadding * (idx == 0 ? -1 : 1), y: uP.y - this.diagramTopPadding},
          bP: {x: bP.x + this.diagramTopPadding * (idx == 0 ? -1 : 1), y: bP.y + this.diagramTopPadding},
        };
      });
    },
    vRulerLines() {
      return this.chunkCenters.map((centerX) => {
        const uP = new ChartPoint(centerX, 0).applyTransform(this.mainViewMatrix);
        uP.y = 0;
        const bP = new ChartPoint(centerX, this.baseDiagramSize.h).applyTransform(this.mainViewMatrix);
        bP.y = this.baseDiagramSize.h;
        return {uP, bP};
      });
    },
    mainRulerLines() {
      let currentDrawPoint = parseInt(this.mainCurMin / this.mainRulerLineStep) - 1;

      const ans = [];

      while ((currentDrawPoint - 1) * this.mainRulerLineStep < this.mainCurMax) {
        const sP = new ChartPoint(
            0,
            currentDrawPoint * this.mainRulerLineStep,
        ).applyTransform(this.mainViewMatrix);
        sP.x = 0;
        const eP = new ChartPoint(this.baseDiagramSize.w, sP.y);
        ans.push({
          mainView: ((currentDrawPoint * this.mainRulerLineStep) * this.mainMult.mult).toFixed(2),
          subView: ((currentDrawPoint * this.subRulerLineStep) * this.subMult.mult).toFixed(2),
          sP,
          eP,
          color: '#777b7525',
        });

        currentDrawPoint += 1;
      }

      const maxMinColor = '#00000010';
      const sP = new ChartPoint(
          this.chunkPadding/2,
          this.drawData.mainMax,
      ).applyTransform(this.mainViewMatrix);
      const eP = new ChartPoint(
          this.drawDataSize.w - this.chunkPadding/2,
          this.drawData.mainMax,
      ).applyTransform(this.mainViewMatrix);

      ans.push({
        mainView: '',
        subView: '',
        sP: {x: sP.x - this.diagramTopPadding, y: sP.y - this.diagramTopPadding},
        eP: {x: eP.x + this.diagramTopPadding, y: eP.y - this.diagramTopPadding},
        color: maxMinColor,
      });

      sP.x = this.chunkPadding/2,
      sP.y = this.drawData.mainMin;
      sP.applyTransform(this.mainViewMatrix);
      eP.x = this.drawDataSize.w - this.chunkPadding/2,
      eP.y = this.drawData.mainMin;
      eP.applyTransform(this.mainViewMatrix);

      ans.push({
        mainView: '',
        subView: '',
        sP: {x: sP.x - this.diagramTopPadding, y: sP.y + this.diagramTopPadding},
        eP: {x: eP.x + this.diagramTopPadding, y: eP.y + this.diagramTopPadding},
        color: maxMinColor,
      });

      return ans;
    },
    chunkCenters() {
      return Object.keys(this.drawData.points).map((_, idx) => this.chunkWidth / 2 + this.chunkWidth * idx);
    },
    cW() {
      return this.baseCW * this.userTransform.sX;
    },
    innerChunkPadding() {
      return this.cW / 3;
    },
    chunkPadding() {
      return this.innerChunkPadding * 12;
    },
    chunkWidth() {
      return (
        this.chunkPadding +
        this.innerChunkPadding * (this.drawData.chunkColumnCounts - 1) +
        this.cW * this.drawData.chunkColumnCounts
      );
    },
    mainViewMatrix() {
      return {
        scaleX: 1,
        scaleY: -this.mainRulerScaleY * this.userTransform.sY,
        translateX: this.userTransform.pX,
        translateY: this.origZeroPoint - this.userTransform.pY,
      };
    },
    subViewMatrix() {
      return {
        scaleX: 1,
        scaleY: -this.subRulerScaleY * this.userTransform.sY,
        translateX: this.userTransform.pX,
        translateY: this.origZeroPoint - this.userTransform.pY,
      };
    },
    subRulerLineStep() {
      return this.mainRulerLineStep * this.mainRulerScaleY / this.subRulerScaleY;
    },
    mainRulerLineStep() {
      let ans = parseInt(
          this.mainMult.mult * (Math.abs(this.mainCurMax - this.mainCurMin) / this.rulerLineCount),
      );
      const curMult = this.getMultiplier(ans, true);
      ans = parseInt(ans * curMult.mult) / curMult.mult / this.mainMult.mult;

      return ans;
    },
    subCurMax() {
      return (this.drawYzone - this.userTransform.pY) / (this.subRulerScaleY * this.userTransform.sY);
    },
    subCurMin() {
      return (
        -this.userTransform.pY - Math.abs(this.drawData.subMin * this.subRulerScaleY)
      ) / (this.subRulerScaleY * this.userTransform.sY);
    },
    subMult() {
      return this.getMultiplier(
          Math.abs(this.subCurMax - this.subCurMin) / this.rulerLineCount,
      );
    },
    mainCurMax() {
      return (this.drawYzone - this.userTransform.pY) / (this.mainRulerScaleY * this.userTransform.sY);
    },
    mainCurMin() {
      return (
        -this.userTransform.pY - Math.abs(this.drawData.mainMin * this.mainRulerScaleY)
      ) / (this.mainRulerScaleY * this.userTransform.sY);
    },
    mainMult() {
      return this.getMultiplier(
          Math.abs(this.mainCurMax - this.mainCurMin) / this.rulerLineCount,
      );
    },
    mainMinus() {
      return Math.abs(this.drawData.mainMax - this.drawData.mainMin);
    },
    mainRulerScaleY() {
      return this.drawYzone / this.mainMinus;
    },
    subRulerScaleY() {
      const bmv = parseInt(
          this.origMainMult.mult * Math.max(Math.abs(this.drawData.mainMax), Math.abs(this.drawData.mainMin)),
      ) / this.origMainMult.mult;
      const bsv = parseInt(
          this.origSubMult.mult * Math.max(Math.abs(this.drawData.subMax), Math.abs(this.drawData.subMin)),
      ) / this.origSubMult.mult;
      return bmv * this.mainRulerScaleY / bsv;
    },
    origZeroPoint() {
      return this.baseDiagramSize.h - Math.abs(this.drawData.mainMin * this.mainRulerScaleY);
    },
    hasSubRuler() {
      return this.drawData.subRulerUse === true;
    },
    drawYzone() {
      return this.baseDiagramSize.h - this.diagramTopPadding;
    },
    drawXzone() {
      return this.baseDiagramSize.w;
    },
    viewBoxScale() {
      return {
        w: this.diagramWidth / this.viewBoxWidth,
        h: this.legendHeight / this.viewBoxHeight,
      };
    },
    mainRulerSize() {
      const ans = {
        w: this.rulerWidth,
        h: this.viewBoxHeight-this.legendRulerHeight,
      };
      ans.gw = ans.w * this.viewBoxScale.w;
      ans.gh = ans.h * this.viewBoxScale.h;
      return ans;
    },
    baseDiagramSize() {
      const ans = {
        w: this.viewBoxWidth - this.rulerWidth * (this.hasSubRuler ? 2 : 1),
        h: this.viewBoxHeight-this.legendRulerHeight,
      };
      ans.gw = ans.w * this.viewBoxScale.w;
      ans.gh = ans.h * this.viewBoxScale.h;
      return ans;
    },
    subRulerSize() {
      const ans = {
        w: this.rulerWidth * (this.hasSubRuler ? 1 : 0),
        h: this.viewBoxHeight-this.legendRulerHeight,
      };
      ans.gw = ans.w * this.viewBoxScale.w;
      ans.gh = ans.h * this.viewBoxScale.h;
      return ans;
    },
    mainRulerMultSize() {
      const ans = {
        w: this.rulerWidth,
        h: this.legendRulerHeight,
      };
      ans.gw = ans.w * this.viewBoxScale.w;
      ans.gh = ans.h * this.viewBoxScale.h;
      return ans;
    },
    legendRulerSize() {
      const ans = {
        w: this.viewBoxWidth - this.rulerWidth * (this.hasSubRuler ? 2 : 1),
        h: this.legendRulerHeight,
      };
      ans.gw = ans.w * this.viewBoxScale.w;
      ans.gh = ans.h * this.viewBoxScale.h;
      return ans;
    },
    subRulerMultSize() {
      const ans = {
        w: this.rulerWidth * (this.hasSubRuler ? 1 : 0),
        h: this.legendRulerHeight,
      };
      ans.gw = ans.w * this.viewBoxScale.w;
      ans.gh = ans.h * this.viewBoxScale.h;
      return ans;
    },
    origMainMult() {
      return this.getMultiplier(
          Math.max(Math.abs(this.drawData.mainMax), Math.abs(this.drawData.mainMin)),
          true,
      );
    },
    origSubMult() {
      return this.getMultiplier(
          Math.max(Math.abs(this.drawData.subMax), Math.abs(this.drawData.subMin)),
          true,
      );
    },
    beautifyMultipliers() {
      const oneMults = this.userMultipliers;
      const twoMults = oneMults.map((mult) => ({
        extended: true,
        view: '2 x ' + mult.view,
        mult: mult.mult * 2,
      }));
      const twoHalfMults = oneMults.map((mult) => ({
        extended: true,
        view: '2.5 x ' + mult.view,
        mult: mult.mult * 2.5,
      }));
      const fiveMults = oneMults.map((mult) => ({
        extended: true,
        view: '5 x ' + mult.view,
        mult: mult.mult * 5,
      }));
      const ans = oneMults.concat(twoMults, twoHalfMults, fiveMults).sort((a, b) => (a.mult - b.mult));
      return ans;
    },
    visibleMaskData() {
      if (this.richInfoData == null) return null;
      return this.richInfoData.mask;
    },
  },
  methods: {
    drawNumber(number) {
      return number == null ? 0 : number.toLocaleString('ru', {'minimumFractionDigits': 2, 'maximumFractionDigits': 2});
    },
    getMultiplier(multData, extended=false) {
      const ans = {view: 'ед', mult: 1};

      this.beautifyMultipliers.forEach((ms) => {
        if (!extended && ms.extended) return;
        if (multData >= ms.mult) {
          ans.view = ms.view;
          ans.mult = ms.mult == 0 ? 1 : 1 / ms.mult;
        }
      });

      return ans;
    },
    resetUserTransform() {
      varAnimator(this.userTransform.sX, 1, (aVal) => this.userTransform.sX = aVal);
      varAnimator(this.userTransform.sY, 1, (aVal) => this.userTransform.sY = aVal);
      const endWPoint = -this.defaultDrawDataSize.w + this.baseDiagramSize.w;
      varAnimator(this.userTransform.pX, endWPoint, (aVal) => this.userTransform.pX = aVal);
      varAnimator(this.userTransform.pY, 4, (aVal) => this.userTransform.pY = aVal);
    },
    seriesLineClick(line, event) {
      this.richInfoData = {
        mask: {
          type: 'line',
          x1: event.target.attributes.x1.value,
          y1: event.target.attributes.y1.value,
          x2: event.target.attributes.x2.value,
          y2: event.target.attributes.y2.value,
          w: event.target.attributes['stroke-width'].value,
        },
        popupData: [
          line.series.view + ': ' + line.sPoint.view + ' => ' + line.ePoint.view,
          {label: 'Динамика', value: this.drawNumber(line.eValue - line.sValue)},
        ],
        event,
      };
    },
    seriesPointClick(point, event) {
      this.richInfoData = {
        mask: {
          type: 'point',
          cx: event.target.attributes.cx.value,
          cy: event.target.attributes.cy.value,
          r: event.target.attributes['stroke-width'].value,
        },
        popupData: [
          point.series.view + ': ' + point.point.view,
          {label: 'Значение', value: this.drawNumber(point.value)},
        ],
        event,
      };
    },
    columnClick(column, event) {
      this.richInfoData = {
        mask: {
          type: 'line',
          x1: event.target.attributes.x1.value,
          y1: event.target.attributes.y1.value,
          x2: event.target.attributes.x2.value,
          y2: event.target.attributes.y2.value,
          w: event.target.attributes['stroke-width'].value,
        },
        popupData: [
          column.series.view + ': ' + column.point.view,
          {label: 'Значение', value: this.drawNumber(column.value)},
        ],
        event,
      };
    },
    chunkPairClick(firstChunkId, secondChunkId, event) {
      this.richInfoData = {
        mask: {
          type: 'line',
          x1: event.target.attributes.x1.value,
          y1: event.target.attributes.y1.value,
          x2: event.target.attributes.x2.value,
          y2: event.target.attributes.y2.value,
          w: event.target.attributes['stroke-width'].value,
        },
        popupData: [
          this.drawData.points['_' + firstChunkId].view + ' => ' + this.drawData.points['_' + secondChunkId].view,
          {label: 'Динамика', value: this.drawNumber(
              this.drawData.points['_' + secondChunkId].mainTotal - this.drawData.points['_' + firstChunkId].mainTotal,
          )},
        ],
        event,
      };

      if (this.drawData.subRulerUse) {
        this.richInfoData.popupData.push({
          label: 'Динамика доп измерения',
          value: this.drawNumber(
              this.drawData.points['_' + secondChunkId].subTotal - this.drawData.points['_' + firstChunkId].subTotal,
          ),
        });
      }
    },
    chunkClick(chunkId, event) {
      this.richInfoData = {
        mask: {
          type: 'line',
          x1: event.target.attributes.x1.value,
          y1: event.target.attributes.y1.value,
          x2: event.target.attributes.x2.value,
          y2: event.target.attributes.y2.value,
          w: event.target.attributes['stroke-width'].value,
        },
        popupData: [
          String(this.drawData.points['_' + chunkId].view),
          {label: 'Значение', value: this.drawNumber(this.drawData.points['_' + chunkId].mainTotal)},
        ],
        event,
      };
      if (this.drawData.subRulerUse) {
        this.richInfoData.popupData.push({
          label: 'Значение доп измерения',
          value: this.drawNumber(this.drawData.points['_' + chunkId].subTotal),
        });
      }
    },
    rulerTextSize(drawText) {
      const baseSize = 5;
      const maxCount = 6;
      return drawText.length <= maxCount ? baseSize : (baseSize*maxCount) / drawText.length;
    },
    elementColor(num, maxCount) {
      return hslToHex(160 + (1 - (num == maxCount - 1 ? 1 : num/(maxCount - 1))) * 160, 85, 60)+'f0';
    },
    dragController(moveDistance) {
      this.richInfoData = null;
      const diagramRect = this.$refs.base_svg.getBoundingClientRect();

      const chartX = (moveDistance.x) * this.baseDiagramSize.w / diagramRect.width;
      const chartY = (moveDistance.y) * this.baseDiagramSize.h / diagramRect.height;

      this.userTransform.pX = this.userTransform.pX + chartX;
      this.userTransform.pY = this.userTransform.pY - chartY;

      const newPX = this.userTransform.pX;
      const newPY = this.userTransform.pY;

      this.checkUserTransform();

      return {
        x: newPX != this.userTransform.pX ? moveDistance.x : 0,
        y: newPY != this.userTransform.pY ? moveDistance.y : 0,
      };
    },
    zoomController(zoomData) {
      this.richInfoData = null;
      const diagramRect = this.$refs.base_svg.getBoundingClientRect();

      const centerX = (zoomData.pX - diagramRect.left) * this.baseDiagramSize.w / diagramRect.width;
      const centerY = (zoomData.pY - diagramRect.top) * this.baseDiagramSize.h / diagramRect.height;

      const sizeX = (zoomData.sizeX) * this.baseDiagramSize.w / diagramRect.width;
      const sizeY = (zoomData.sizeY) * this.baseDiagramSize.h / diagramRect.height;

      const oldValueScale = this.userTransform.sY;
      const oldPointsScale = this.userTransform.sX;

      this.userTransform.sY -= sizeY * 0.1 * this.userTransform.sY;
      this.userTransform.sX -= sizeX * 0.1 * this.userTransform.sX;

      const scaleVChange = oldValueScale - this.userTransform.sY;

      const fromVPointerToZero = centerY - (this.origZeroPoint - this.userTransform.pY);
      this.userTransform.pY -= scaleVChange * fromVPointerToZero / oldValueScale;

      const scalePChange = this.userTransform.sX - oldPointsScale;
      const fromPPointerToZero = (centerX - this.userTransform.pX);
      this.userTransform.pX -= scalePChange * fromPPointerToZero / oldPointsScale;

      this.checkUserTransform();
    },
    checkUserTransform() {
      if (this.userTransform.pY < -this.drawDataSize.h + this.diagramTopPadding) {
        this.userTransform.pY = -this.drawDataSize.h + this.diagramTopPadding;
      } else if (this.userTransform.pY > this.baseDiagramSize.h - this.diagramTopPadding) {
        this.userTransform.pY = this.baseDiagramSize.h - this.diagramTopPadding;
      }

      const endXCheckData = (
        -this.drawDataSize.w + this.diagramTopPadding + this.chunkPadding / 2
      );

      if (this.userTransform.pX < endXCheckData) {
        this.userTransform.pX = endXCheckData;
      } else if (this.userTransform.pX > this.drawXzone - this.chunkPadding / 2 - this.diagramTopPadding) {
        this.userTransform.pX = this.drawXzone - this.chunkPadding / 2 - this.diagramTopPadding;
      }
    },
    getUniqueNumber() {
      const ans = window.pathCounter == null ? 0 : window.pathCounter + 1;
      window.pathCounter = ans;
      return ans;
    },
  },
  mounted() {
    this.baseMaskIdx = String(this.getUniqueNumber());
    setTimeout(() => {
      if (this.$refs.base_svg == null) return;

      this.gestController = gestures(this.$refs.base_svg);
      this.gestController.registerDrag(this.dragController);
      this.gestController.registerZoom(this.zoomController);

      this.hoverControllers = [];

      if (this.$refs.chunk_pair_triggers != null) {
        this.hoverControllers.push(this.$refs.chunk_pair_triggers.map((elem) => {
          const gestController = gestures(elem);
          gestController.registerClick(null, (_, event) => this.chunkPairClick(
              parseInt(event.target.attributes['data-idx'].value),
              parseInt(event.target.attributes['data-idx'].value) + 1,
              [event.uniPointer = _, event][1],
          ));
          gestController.registerHover(
              null,
              (_, event) => this.chunkPairClick(
                  parseInt(event.target.attributes['data-idx'].value),
                  parseInt(event.target.attributes['data-idx'].value) + 1,
                  [event.uniPointer = _, event][1],
              ),
              () => this.richInfoData = null,
          );
          return gestController;
        }));
      }

      if (this.$refs.chunk_triggers != null) {
        this.hoverControllers.push(this.$refs.chunk_triggers.map((elem) => {
          const gestController = gestures(elem);
          gestController.registerClick(null, (_, event) => this.chunkClick(
              parseInt(event.target.attributes['data-idx'].value), [event.uniPointer = _, event][1],
          ));
          gestController.registerHover(
              null,
              (_, event) => this.chunkClick(
                  parseInt(event.target.attributes['data-idx'].value), [event.uniPointer = _, event][1],
              ),
              () => this.richInfoData = null,
          );
          return gestController;
        }));
      }

      if (this.$refs.histogram_columns != null) {
        this.hoverControllers.push(this.$refs.histogram_columns.map((elem) => {
          const gestController = gestures(elem);
          gestController.registerClick(null, (_, event) => this.columnClick(
              this.columnData[parseInt(event.target.attributes['data-idx'].value)].column,
              [event.uniPointer = _, event][1],
          ));
          gestController.registerHover(
              null,
              (_, event) => this.columnClick(
                  this.columnData[parseInt(event.target.attributes['data-idx'].value)].column,
                  [event.uniPointer = _, event][1],
              ),
              () => this.richInfoData = null,
          );
          return gestController;
        }));
      }

      if (this.$refs.histogram_line_triggers != null) {
        this.hoverControllers.push(this.$refs.histogram_line_triggers.map((elem) => {
          const gestController = gestures(elem);
          gestController.registerClick(null, (_, event) => this.seriesLineClick(
              this.lineData[parseInt(event.target.attributes['data-idx'].value)].line,
              [event.uniPointer = _, event][1],
          ));
          gestController.registerHover(
              null,
              (_, event) => this.seriesLineClick(
                  this.lineData[parseInt(event.target.attributes['data-idx'].value)].line,
                  [event.uniPointer = _, event][1],
              ),
              () => this.richInfoData = null,
          );
          return gestController;
        }));
      }

      if (this.$refs.histogram_point_triggers != null) {
        this.hoverControllers.push(this.$refs.histogram_point_triggers.map((elem) => {
          const gestController = gestures(elem);
          gestController.registerClick(null, (_, event) => this.seriesPointClick(
              this.pointsData[parseInt(event.target.attributes['data-idx'].value)].point,
              [event.uniPointer = _, event][1],
          ));
          gestController.registerHover(
              null,
              (_, event) => this.seriesPointClick(
                  this.pointsData[parseInt(event.target.attributes['data-idx'].value)].point,
                  [event.uniPointer = _, event][1],
              ),
              () => this.richInfoData = null,
          );
          return gestController;
        }));
      }

      this.resetUserTransform();
    }, 1);
  },
  beforeUnmount() {
    if (this.gestController != null) this.gestController.destruct();
    if (this.hoverControllers != null) {
      this.hoverControllers.forEach(
          (controllers) => controllers.forEach((elem) => elem.destruct()),
      );
    }
  },
  props: [
    'diagramWidth',
    'diagramHeight',
    'viewBoxWidth',
    'viewBoxHeight',
    'legendHeight',
    'legendWidth',
    'drawData',
    'userMultipliers',
  ],
  watch: {
    richInfoData() {
      if (this.richInfoData == null) return;

      const diagramRect = this.$refs.svg_container.getBoundingClientRect();

      const svgX = this.richInfoData.event.uniPointer.x - diagramRect.left;

      this.richInfoData.infoPopupIsLeft = svgX > this.diagramWidth / 2;
    },
  },
  emits: [
    'chartPointerMove',
    'chartZoom',
    'chartReset',
    'unitSelect',
    'seriesSelect',
  ],
};
</script>

<style lang="less">

// svg {
//   background-color: black;
// }

.hybrid-chart {
  .flex(row, center, center);
  flex-wrap: wrap;

  &__stroke-base-color {
    stroke: @base-text-color;
  }

  &__fill-base-color {
    fill: @base-text-color;
  }

  &__stroke-no-accent-color {
    stroke: @no-accent-text-color;
  }

  &__fill-no-accent-color {
    fill: @no-accent-text-color;
  }

  &__fill-content-background {
    fill: @content-background-color;
  }
}

.rich-info-window {
  // .secure-content-background;
  position: absolute;
}

.hybrid-chart-svg-container {
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr 1fr;
  // margin-right: 13px;
  // margin-bottom: 13px;
}

</style>
