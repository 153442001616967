import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const ADD_REQUEST_LINK = '/user_pages/add_page/';
const UPD_REQUEST_LINK = '/user_pages/update_page/';

function pageAddOrChange(isAdd, pageForm, handler) {
  const REQUEST_LINK = isAdd ? ADD_REQUEST_LINK : UPD_REQUEST_LINK;

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: pageForm,
  }).then(async (response) => {
    if (response.status == 200) {
      handler('ok');
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка работы со страницей. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default pageAddOrChange;
