export default {
  data: () => ({
    isMobileWall: 0,
    currentWidth: 0,
  }),
  methods: {
    mobMixinWinResize() {
      this.currentWidth = window.innerWidth;
    },
  },
  beforeMount() {
    const mobileTransitionThreshold = window.transitionThreshold1;

    this.isMobileWall = parseInt(mobileTransitionThreshold);
    this.currentWidth = window.innerWidth;

    window.addEventListener('resize', this.mobMixinWinResize);
  },
  unmounted() {
    window.removeEventListener('resize', this.mobMixinWinResize);
  },
  computed: {
    get_is_mobile_info() {
      return this.currentWidth <= this.isMobileWall;
    },
  },
};
