<template>
  <div class="chart-legend" :style="{
    'max-height' : this.legendHeight + 'px',
    'max-width': (this.legendWidth == null || this.legendWidth < 150) ? null : this.legendWidth + 'px',
    }">
    <div  class="chart-legend-data"
          ref="legend_row"
          :class="{'legend-elem-selected': this.selectedData == dataId}"
          :style="`grid-template-columns: ${this.drawTwoColumn ? null : 'repeat(2, 1fr)'};`"
          v-for="dataItem, dataId in this.drawData"
          :key="dataId"
          @click="this.$emit('dataSelect', dataId)"
          >
      <!-- <LabelComponent   :style="`color: ${dataItem.color};`"
                        label_text="●"/> -->
      <div class="chart-legend-data-name">
        <div class="chart-legend-data-pointer" :style="`background: ${dataItem.color};`"></div>
        <LabelComponent   :label_text="dataItem.view"/>
      </div>
      <div class="chart-legend-data-value" v-if="this.drawTwoColumn">
        <LabelComponent  :label_text="dataItem.value" :digits_precision="0"/>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import gestures from '@/assets/gestures.js';

export default {
  components: {
    LabelComponent,
  },
  data: () => ({

  }),
  computed: {
    drawTwoColumn() {
      return this.legendWidth == null || this.legendWidth > 220 || this.legendWidth < 150;
    },
  },
  methods: {

  },
  watch: {

  },
  mounted() {
    setTimeout(() => {
      if (this.$refs.legend_row == null) return;

      this.gestControllers = this.$refs.legend_row.map((rowObj, idx) => {
        const curDataIdx = idx;
        const gestController = gestures(rowObj);
        gestController.registerHover(
            null,
            () => this.$emit('dataHover', curDataIdx),
            () => this.$emit('dataHover', null),
        );
        return gestController;
      });
    }, 1);
  },
  beforeUnmount() {
    if (this.gestControllers != null) this.gestControllers.forEach((elem) => elem.destruct());
  },
  props: [
    'drawData',
    'legendWidth',
    'legendHeight',
    'selectedData',
  ],
  emits: [
    'dataSelect',
    'dataHover',
  ],
};
</script>

<style lang="less">

.legend-elem-selected {
  color: @light-green;
}

.chart-legend {
  overflow-y: auto;
  overflow-wrap: anywhere;
  min-width: 150px - 13px;

  &-data {
    padding: 3px 0;
    padding-right: 7px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 7px;

    &:hover {
      background: @light-green-opacity-10;
    }

    &-name {
      grid-column: span 2;
      .flex(row, flex-start, flex-start);
    }
    &-value {
      text-align: right;
    }
    &-pointer {
      margin-left: 13px;
      min-width: 4px;
      max-width: 4px;
      min-height: 17px;
      max-height: 17px;
      border-radius: 8px 8px 8px 8px;
      margin-right: 3px;
    }
    cursor: pointer;
  }
}
</style>
