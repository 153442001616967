import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/auth/accept_account/';

function acceptAccount(userActivateCode, handler) {
  fetch(ROOT_URL + REQUEST_LINK + userActivateCode + '/', {
    method: 'GET',
    credentials: 'include',
  }).then((response) => {
    handler(response.status);
  }).catch((errData) => {
    handler(null);
  });
}

export default acceptAccount;
