import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
// import store from '@/store';

const REQUEST_LINK = '/file_storage/upload_folder/?uid=';

function uploadFolder(pageUid, folder, handler) {
  const formData = new FormData();
  formData.append('folder', folder);

  fetch(ROOT_URL + REQUEST_LINK + pageUid, {
    method: 'POST',
    credentials: 'include',
    body: formData,
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.json());
      return;
    }

    if (response.status == 401) {
      const dsName = await response.text();

      const authResult = await window.openExtAuthWindow(sourceInfo.uid, dsName);
      if (authResult == 'ok') {
        uploadFolder(pageUid, folder, handler);
        return;
      }

      handler({
        _error: true,
        status: response.status,
        text: 'Ошибка доступа к источнику. Неверный логин или пароль.',
      });
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка создания папки. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default uploadFolder;
