<template>
  <div class="base-content-wrapper" :class="{'content-box': this.is_with_background}">
    <teleport
              :disabled="!(this.drawHeader && this.get_is_mobile_info)"
              :to="this.drawHeader && this.get_is_mobile_info ? '#content-header' : null"
            >

      <div class="base-content-header" :class="{'content-background': !this.is_with_background}">

        <Transition name="back_button__fade" appear>
          <ButtonComponent
            v-if="this.get_current_page_info"
            class="back_button"
            iconUrl="/icons/system/arrow-left.svg"
            iconMargin="0 8px 0 0"
            elementType="default"
            :href="this.get_parent_structure ? this.get_parent_structure.fullUrl : '/'"
            label="Назад" />
        </Transition>

        <div class="page-header" :class="this.get_is_mobile_info ? 'mobile-header' : null">
          <Transition name="page-title__fade" appear>
            <LabelComponent class="page-title"
                            label_type="h2"
                            :label_text="this.get_current_page_name" :key="this.get_current_page_name"/>
          </Transition>

          <div id="page-tools" v-show="this.$store.getters.isUserOwner">
            <ButtonComponent  v-if="this.get_current_page_info != null && !this.get_current_page_info.internal_page"
                              iconUrl="/icons/system/edit.svg"
                              elementType="default"
                              iconSize="27"
                              @click="this.openPageForm"
                              />
            <ButtonComponent  v-if="this.get_current_page_info != null && !this.get_current_page_info.internal_page"
                              iconUrl="/icons/system/trash.svg"
                              elementType="default"
                              iconSize="27"
                              iconColor="#ee6767"
                              @click="this.removePage"
                              />
          </div>
        </div>

        <!-- <BlockSeparator v-if="true || this.get_is_mobile_info" /> -->

      </div>
    </teleport>

    <!-- <br/> -->
    <!-- <BlockSeparator v-if="true || this.get_is_mobile_info" /> -->
    <!-- <br/> -->
    <div class="for-scroll-wrapper grow">
      <div
          class="content__slider"
          id="content-slider">

        <div id="content-header"></div>

        <div id="page-content"></div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import isMobileMixin from '@/assets/mixins/isMobileMixin.js';
// import BlockSeparator from '@/units/forms/BlockSeparator.vue';

import PageForm from '@/windows/PageForm.vue';

import deletePage from '@/assets/dataSources/navigation/deletePage.js';
import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
    // BlockSeparator,
  },
  data: ()=>({
    drawHeader: false,
  }),
  watch: {
    get_current_page_info() {
      if (this.get_current_page_info != null) return;
    },
  },
  mounted() {
    this.$nextTick(() => this.drawHeader = true);
  },
  mixins: [isMobileMixin],
  methods: {
    openPageForm() {
      window.openWindow({
        caption: 'Редактирование страницы',
        component: PageForm,
        componentProps: {
          page_uid: this.get_current_page_info.uid,
          page_type: this.get_current_page_info.page_type,
          initial_name: this.get_current_page_info.name,
          initial_icon: this.get_current_page_info.icon_url,
          initial_parent_id: this.get_current_page_info.parent_page_uid,
          initial_open_new_tab: this.get_current_page_info.open_new_tab,
          initial_enable_diagram_preview: this.get_current_page_info.enable_diagram_preview,
          initial_can_edit_storage: this.get_current_page_info.can_edit_storage,
          initial_in_fast_panel: this.get_current_page_info.in_fast_panel,
          initial_url: this.get_current_page_info.url,
        },
      });
    },
    async removePage() {
      const selectResult = await window.openYNWindow(
          'Вы уверены что хотите удалить эту и все дочерние страницы?\nЭто действие нельзя будет отменить!',
          'Удаление страницы',
      );

      if (selectResult != 'yes') return;

      deletePage(this.get_current_page_info.uid, () => {
        getWebSiteStructure((result) => {
          this.$store.commit('web_site_struct_mut', result);
          this.$router.replace({
            path: this.get_current_page_info.parent_page == null ? '/' : this.get_current_page_info.parent_page.fullUrl,
          });
        });
      });
    },
  },
  computed: {
    // get_is_mobile_info() {
    //   return true;
    // },
    get_current_page_info() {
      return this.$store.state.current_page_info;
    },
    get_parent_structure() {
      return this.get_current_page_info ? this.get_current_page_info.parent_page : null;
    },
    get_current_page_name() {
      if (this.name) return this.name;
      const currentPage = this.get_current_page_info;
      if (currentPage) return currentPage.name;
      return null;
    },
  },
  props: {
    name: null,
    is_with_background: {
      type: Boolean,
      default: true,
    },
    // fixedMobileHeader: {
    //   type: Boolean,
    //   default: false,
    // },
  },
};

</script>

<style lang="less">
@page__slider__background-color: @white;

@scroll__track__background-color: @super-light;
@scroll_track__thumb__color: @light-gray;

// default size
@scroll__track__vertical__width: @margin-horizontal-default;

@page__padding__top: @margin-vertical-default;
@page__padding__left: @margin-horizontal-default;

// @page__slider__margin-bottom: @margin-vertical-default;
@scroll__track__bottom__padding: @margin-vertical-default;
//_________________

//transition-threshold-1 size (800)
@scroll__track__vertical__width__800: @margin-horizontal-800;

@page__padding__top__800: @margin-vertical-800;
@page__padding__left__800: @margin-horizontal-800;

@page__slider__margin-bottom__800: @margin-vertical-800;
@scroll__track__bottom__padding__800: @margin-vertical-800;
//_________________________________

#page-tools {
  .flex(row, center, center);
}

.back_button{
  align-self: flex-start;
  // padding-top: 14px;
  // margin-right: auto;
  // background-color: #eb5050;

  &__fade-enter-active,
  &__fade-leave-active {
    transition: max-height 0.3s, opacity 0.3s;
    max-height: 16px;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    max-height: 0px;
    opacity: 0;
  }
}

.page-header {
  .flex(row, space-between, end);
  // padding-bottom: 14px;
  // margin-right: 25px;
}

#content-header {
  .flex(column, flex-start, stretch);
  padding-left: 14px;
}

.base-content-header{
  .flex(column, flex-start, stretch);
  padding: @base-gap;
  margin-right: @base-gap;
  margin-bottom: @base-gap;
  // padding-bottom: 17px;
  // padding-top:@page__padding__top;
}

.base-content-wrapper{
  .flex(column, flex-start, stretch);
  // .set_custom_scrollbar(6px, 10px, 0px, 20px, 20px);
  // position: relative;
  height: 100%;
  width: 100%;

  // margin: 7px;

  // padding-left:@page__padding__left;
  //.wrap{
  //  padding-right: @margin-horizontal-default
  //}
  .page-title{
    // margin-top: 8px;

    &__fade-enter-active,
    &__fade-leave-active {
      transition: opacity 0.3s;
    }

    &__fade-enter-from,
    &__fade-leave-to {
      opacity: 0;
      pointer-events: none;
      position: absolute;
    }
  }

  .for-scroll-wrapper {
    // flex-grow: 1;
    // background: linear-gradient(45deg, black, transparent);
    // overflow: auto;
    // margin-top: @page__padding__top;
    position: relative;
  }

  // .mobile-header {
  //   // padding-left: 14px;
  //   padding-top: 14px;
  //   padding-bottom: 14px;
  // }

  .content{
    &__slider{
      max-width: 100%;
      // margin-right: @page__padding__left;
      //margin-bottom: @page__slider__margin-bottom;
      //overflow: auto;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      // bottom: 0;
      //max-height: 99%;
      right: 0;
      overflow: auto;

      #page-content{
        width: 100%;

        // .mobile-header {
        //   padding-top: 14px;
        //   padding-bottom: 14px;
        // }
      }

      transition: background 0.3s;

      // margin-bottom: @page__slider__margin-bottom;
    }
  }
}


@media screen and (max-width: @transition-threshold-1) {
  .back_button {
    // padding-top: 14px;

    &__fade-enter-active,
    &__fade-leave-active {
      transition: padding 0.3s;
      max-height: 30px;
    }

    &__fade-enter-from,
    &__fade-leave-to {
      // padding-top: 0px;
      max-height: 0px;
    }
  }

  // .base-content-header{
  //   margin-bottom: 0;
  //   padding-bottom: 0;
  //   padding-top: 0;
  //   padding-left: 0;
  // }

  #content-header {
    padding-left: 0;
  }

  .base-content-wrapper {
    // padding-top: 0;
    // padding-left: 21px;

    .for-scroll-wrapper {
      margin-left: 5px;
    }

    .content__slider{
      // margin-right: 5px;
      // padding-right: 10px;
    }
  }
}
</style>
