<template>
  <div class="subscription-wraper">
    <div>
      <LabelComponent class="subscription-block-header" label_text="Баланс" label_type="h2"/>
      <LabelComponent class="subscription-block-description" label_text="Здесь отображаются средства на вашем балансе" label_type="body/large/regular 16"/>

      <div class="subscription-name-wraper">
        <SpinnerComponent v-if="this.wallet_amount == null" :width="7"/>
        <template v-else>
          <LabelComponent :label_text="(this.wallet_amount / 100)" :digits_precision="2" label_type="h3"/>
          <LabelComponent label_text="Монет" label_type="h3"/>
        </template>
      </div>

      <template v-if="this.isSubscriptionEditor">
        <br/>
        <ButtonComponent  elementType="default"
                          elementSize="big"
                          label="Пополнить"
                          v-if="!this.isExperements"
                          @click="this.topUpWindow"
                        />
        <LabelComponent v-else label_text="Пополнение недоступно в демо контуре"/>
        <br/>
        <ButtonComponent  elementType="default"
                          elementSize="big"
                          label="Активировать Промокод"
                          @click="this.promocodeWindow"
                        />
      </template>
      <br/>
    </div>

    <BlockSeparator class="subscription-block-separator" />

    <SpinnerComponent v-if="this.is_subscription_exists === null" :width="7"/>

    <div v-else-if="this.is_subscription_exists === false">
      <LabelComponent class="subscription-block-header" label_text="Выберите тариф" label_type="h2"/>

      <SubscriptionPlanAdder />

    </div>

    <template v-else>
      <div>
        <LabelComponent class="subscription-block-header" label_text="Тариф" label_type="h2"/>
        <LabelComponent class="subscription-block-description" label_text="Вы можете управлять тарифным планом здесь" label_type="body/large/regular 16"/>

        <br/>

        <div class="subscription-name-wraper">
          <IconComponent size = "95"/>
          <div class="subscription-name">
            <LabelComponent :label_text="`'${this.active_subscription_plan.view}'`" label_type="h2"/>
            <br/>
            <ButtonComponent  elementType="default"
                              elementSize="big"
                              label="Изменить"
                              @click="this.openPlanAddWindow"
                              v-if="this.isSubscriptionEditor"
                            />
          </div>
        </div>

        <br/><br/>

        <div class="subscription-name-wraper">
          <LabelComponent :label_text="`Активирован: ${this.getPlanActivateDate}`" label_type="body/large/regular 16"/>
          <LabelComponent :label_text="`Истекает: ${this.getPlanExpiresDate}`" label_type="body/large/regular 16"/>
        </div>


        <LabelComponent
                        v-if="this.active_subscription_plan.max_user_count != null"
                        :label_text="`Максимально возможное количество пользователей: ${this.active_subscription_plan.max_user_count}`"
                        label_type="body/large/regular 16"
                        />
        <LabelComponent
                        v-else
                        label_text="Ограничений по количеству пользователей нет"
                        label_type="body/large/regular 16"
                        />

        <LabelComponent
                        v-if="this.active_subscription_plan.each_action_plan_data != null"
                        :label_text="`Осталось действий: ${
                          this.active_subscription_plan.each_action_plan_data.available_action_count
                        } из ${
                          this.active_subscription_plan.each_action_plan_data.max_action_count
                        }`"
                        label_type="body/large/regular 16"
                        />

        <br/><br/>

        <ButtonComponent  v-if="this.isSubscriptionEditor"
                          elementType="default-bg"
                          :label="'Установить политику: ' + (this.active_subscription_plan.is_deny_politic ? '\'Разрешать всё, что не запрещено\'' : '\'Запрещать всё, что не разрешено\'')"
                          @click="this.changePolitic"
                        />
      </div>

      <BlockSeparator class="subscription-block-separator" />

      <div>
        <LabelComponent class="subscription-block-header" label_text="Базы" label_type="h2"/>
        <LabelComponent class="subscription-block-description" label_text="Вы можете управлять подключенными базами здесь" label_type="body/large/regular 16"/>
        <DataSourcesList :isSubscriptionEditor="this.isSubscriptionEditor"/>
      </div>

      <BlockSeparator class="subscription-block-separator" />

      <div>
        <LabelComponent class="subscription-block-header" label_text="Пользователи" label_type="h2"/>
        <LabelComponent class="subscription-block-description" label_text="Вы можете управлять подключенными пользователями здесь" label_type="body/large/regular 16"/>
        <UserList
                  :isSubscriptionEditor="this.isSubscriptionEditor"
                  :subscriptionCode="this.active_subscription_plan?.subscription_code" />
      </div>

      <BlockSeparator class="subscription-block-separator" />

      <template v-if="this.isSubscriptionEditor">
        <div>
          <LabelComponent class="subscription-block-header" label_text="Группы пользователей" label_type="h2"/>
          <LabelComponent class="subscription-block-description" label_text="Вы можете управлять группами пользователей здесь" label_type="body/large/regular 16"/>
          <UserGroupList :isSubscriptionEditor="this.isSubscriptionEditor"/>
        </div>

        <BlockSeparator class="subscription-block-separator" />
      </template>
    </template>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import BlockSeparator from '@/units/forms/BlockSeparator.vue';
import IconComponent from '@/units/RichIcon.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import SubscriptionPlanAdder from './SubscriptionPlanAdder.vue';
import DataSourcesList from './DataSourcesList.vue';

import UserGroupList from './UserGroupList.vue';

import UserList from './UserList.vue';
import TopUpWalletForm from './TopUpWalletForm.vue';
import ActivatePromocodeForm from './ActivatePromocodeForm.vue';

import getWallet from '@/assets/dataSources/subscription/getWallet';
import getActiveSubscriptionPlan from '@/assets/dataSources/subscription/getActiveSubscriptionPlan';
import setPolitic from '@/assets/dataSources/subscription/setPolitic.js';
import {IS_EXPEREMENTS_PROD} from '@/store';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
    BlockSeparator,
    IconComponent,
    SpinnerComponent,
    SubscriptionPlanAdder,
    DataSourcesList,
    UserList,
    UserGroupList,
  },
  data: ()=> ({
    active_subscription_plan: {},
    is_subscription_exists: null,
    wallet_amount: null,
  }),
  props: {
  },
  mounted() {
    getWallet((result) => {
      if (result === false) return;

      this.wallet_amount = result.balance;
    });
    getActiveSubscriptionPlan((subscriptionData) => {
      if (subscriptionData === false) {
        this.is_subscription_exists = false;
        return;
      }

      this.active_subscription_plan = subscriptionData;

      this.active_subscription_plan.activate_date = (
        this.active_subscription_plan.activate_date ? new FixDate(this.active_subscription_plan.activate_date) : null
      );
      this.active_subscription_plan.expires_date = (
        this.active_subscription_plan.expires_date ? new FixDate(this.active_subscription_plan.expires_date) : null
      );
      this.active_subscription_plan.freeze_date = (
        this.active_subscription_plan.freeze_date ? new FixDate(this.active_subscription_plan.freeze_date) : null
      );

      // subscriptionData.fields.forEach((field) => {
      //   if (field.system_name == 'subscription_plan_id') this.active_subscription_plan = Object.assign({}, this.active_subscription_plan, field.value);
      //   if (field.system_name == 'activate_date') this.active_subscription_plan.activate_date = new FixDate(field.value);
      //   if (field.system_name == 'expires_date') this.active_subscription_plan.expires_date = new FixDate(field.value);
      // });

      this.is_subscription_exists = true;
      // getObjectInfo('internal__SubscriptionModelObject', '_', (objectTypeInfo) => {
      //   this.is_subscription_exists = true;
      //   // console.log('this.workObjectTypeInfo', objectTypeInfo);
      //   this.subscription_entity = objectTypeInfo;
      // });
    });
  },
  computed: {
    isExperements() {
      return IS_EXPEREMENTS_PROD;
    },
    isSubscriptionEditor() {
      return this.$store.getters.isUserOwner !== false;
    },
    // logoff() {
    //   return !this.$store.getters.isLoggedIn;
    // },
    getPlanActivateDate() {
      if (!this.active_subscription_plan.activate_date) return '-';
      return this.active_subscription_plan.activate_date.toLocaleDateString();
    },
    getPlanExpiresDate() {
      if (!this.active_subscription_plan.expires_date) return '-';
      return this.active_subscription_plan.expires_date.toLocaleDateString();
    },
  },
  methods: {
    async changePolitic() {
      const selectResult = await window.openYNWindow(
          'Вы уверены что хотите поменять политику доступов?\nНекоторые страницы для других пользователей могут стать недоступны!',
          'Смена политики доступа',
      );

      if (selectResult != 'yes') return;
      setPolitic(!this.active_subscription_plan.is_deny_politic, () => window.location.reload());
    },
    openPlanAddWindow() {
      window.openWindow({
        caption: 'Выберите тариф',
        component: SubscriptionPlanAdder,
      });
    },
    topUpWindow() {
      window.openWindow({
        caption: 'Пополнение кошелька',
        component: TopUpWalletForm,
        componentProps: {
          amount: 10000,
        },
      });
    },
    promocodeWindow() {
      window.openWindow({
        caption: 'Ввод промокода',
        component: ActivatePromocodeForm,
      });
    },
  },
};
</script>

<style lang="less">
.subscription-wraper {
  padding: 13px;
}

.subscription-block-header {
  margin-bottom: 11px;
}

.subscription-block-description {
  margin-bottom: 23px;
}

.subscription-name-wraper {
  .flex(row, flex-start, center);
  gap: 23px;
}

.subscription-block-separator {
  margin-top: 13px;
  margin-bottom: 13px;
}
</style>
