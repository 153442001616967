<template>
  <div class="top-up-wallet-wraper">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>
    <ChangeObjectForm
                      :raw_fields="this.drawFields"
                      :raw_actions="this.drawActions"
                      @completeFormAction="this.saveDS"
                    />
  </div>
</template>

<script type="text/javascript">
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelComponent from '@/units/RichLabel.vue';

import activatePromoCode from '@/assets/dataSources/activatePromoCode.js';

export default {
  components: {
    ChangeObjectForm,
    LabelComponent,
  },
  data: () => ({
    savingError: null,
    isSaving: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    saveDS(saveForm) {
      if (this.isSaving) return;

      this.savingError = null;
      this.isSaving = true;

      activatePromoCode(saveForm.get('promocode'), (result) => {
        this.isSaving = false;
        if (result._error) {
          if (result.status == null) {
            this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else {
            this.savingError = `${result.text} Статус: ${result.status}`;
          }

          return;
        }

        window.location.reload();
      });
    },
  },
  computed: {
    promocode() {
      return this.params.promocode == null ? '' : this.params.promocode;
    },
    drawFields() {
      return [
        {
          'type': 'text',
          'view': 'Введите промокод',
          'system_name': 'promocode',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'value': this.promocode,
        },
      ];
    },
    drawActions() {
      return [
        {
          system_name: 'activate',
          view: 'Применить',
          is_loading: this.isSaving,
        },
      ];
    },
  },
};
</script>

