<template>
  <div class="top-up-wallet-wraper">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>
    <ChangeObjectForm
                      :raw_fields="this.drawFields"
                      :raw_actions="this.drawActions"
                      @completeFormAction="this.saveDS"
                    />
  </div>
</template>

<script type="text/javascript">
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelComponent from '@/units/RichLabel.vue';

import getPayLink from '@/assets/dataSources/getPayLink';

export default {
  components: {
    ChangeObjectForm,
    LabelComponent,
  },
  data: () => ({
    savingError: null,
    isSaving: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    saveDS(saveForm) {
      if (this.isSaving) return;

      this.savingError = null;
      this.isSaving = true;

      getPayLink((link) => {
        window.location.href = link;
      }, String(parseInt(saveForm.get('amount')) * 100));
    },
  },
  computed: {
    amount() {
      return this.params.amount == null ? '' : this.params.amount;
    },
    drawFields() {
      return [
        {
          'type': 'number',
          'view': 'Сумма пополнения',
          'system_name': 'amount',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'value': this.amount,
        },
      ];
    },
    drawActions() {
      return [
        {
          system_name: 'top_up',
          view: 'Пополнить',
          is_loading: this.isSaving,
        },
      ];
    },
  },
};
</script>
