<template>
  <Transition name="offline-message-wraper__fade" appear>
    <div v-show="this.isOffline" class="offline-message-wraper">
      <div class="offline-message">
        <LabelComponent label_text="Нет доступа к интернету!" />
      </div>
    </div>
  </Transition>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';

export default {
  components: {
    LabelComponent,
  },
  data: () => ({
    isOffline: false,
  }),
  mounted() {
    window.addEventListener('offline', () => {
      this.isOffline = true;
    });

    window.addEventListener('online', () => {
      this.isOffline = false;
    });
  },
  methods: {
  },
};
</script>

<style  lang="less">

@micro-gap: 3px;
@line-height: 17px;

@panel-back-color: @base-error;
@message-height: @line-height + (@micro-gap * 2);
@grad-len: sqrt((@message-height * @message-height) * 2) * 0.5;

.offline-message-wraper {
  .full-screen-block(fixed);
  bottom: auto;
  z-index: 1001;
  .flex(row, center, center);
  pointer-events: none;

  &__fade-enter-active,
  &__fade-leave-active {
    transition: top @animation-speed;
  }

  &__fade-enter-from,
  &__fade-leave-to {
    top: -1 * @message-height;
  }
}

.offline-message {
  margin: 0 @small-gap;

  border-right: @message-height solid;
  background: linear-gradient(45deg, transparent @grad-len, @panel-back-color @grad-len);
  background-clip: content-box;
  border-image: linear-gradient(-45deg, transparent @grad-len, @panel-back-color @grad-len);
  border-image-slice: 0 unit(@message-height);

  & > * {
    padding-left: @message-height;
    margin: @micro-gap @base-gap;
  }
}

</style>
