<template>
  <PageGroup v-if="this.$store.getters.isLoggedIn || this.$store.state.autostart_mode" :defautPageList="this.$store.getters.webSitePagesRoot"/>
  <div v-else class="index-no-auth-wraper content-box">
    <LabelComponent label_text="Для доступа нужно авторизоваться или зарегистрироваться"/>

    <div class="noauth-buttons-wraper">
      <ButtonComponent label="Авторизация" href="/login"/>
      <ButtonComponent label="Регистрация" href="/register"/>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import PageGroup from '@/navigationLayout/PageGroup.vue';

export default {
  components: {
    ButtonComponent,
    LabelComponent,
    PageGroup,
  },
};
</script>

<style lang="less">
.index-no-auth-wraper {
  margin: auto;
  padding-top:32px;
  width: fit-content;

  .noauth-buttons-wraper {
    margin-top: 13px;
    .flex(row, center, center);
    & > * {
      margin: 0 13px;
    }
  }
}
</style>
