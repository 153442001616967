<template>
  <div>
  </div>
</template>

<script>
import acceptAccoutRequest from '@/assets/dataSources/auth/acceptAccoutRequest.js';

export default {
  components: {

  },
  computed: {
    acceptCode() {
      return this.$route.params.acceptAccountCode;
    },
    pathName() {
      return (new URL(window.location.href)).pathname;
    },
  },
  mounted() {
    this.connectReq();
  },
  methods: {
    hardOpenLink(link) {
      window.location.href = link;
    },
    connectReq() {
      acceptAccoutRequest(this.acceptCode, async (status) => {
        if (status == 200) {
          await window.openAcceptWindow('Подтверждение аккаунта прошло успешно. Пожалуйста, авторизуйтесь');

          window.location.href = '/login';

          return;
        }
        if (status == null) {
          await window.openAcceptWindow('Ошибка соединения с сервером. Проверьте интернет', 'Ошибка');

          window.location.reload();
          return;
        }

        await window.openAcceptWindow('Ссылка недействительна! Статус:' + status, 'Ошибка ' + status);

        window.location.href = '/login';
      });
    },
  },
};
</script>
