<template>
  <div ref="root" class="input_box" @click="this.setFocused(true)" :class="{'input_box_focused': this.isFocused, 'input_box_fixed_height': !this.flexHeight}">
    <div class="input_box_content" :class="{'input_box_hide_data': this.hideData}">
      <div class="input_box_title">
        <LabelComponent :label_text="this.title" />
      </div>
      <div class="input_box_field">
        <slot></slot>
      </div>
    </div>
    <IconComponent v-if="this.iconUrl != null" :url="this.iconUrl" class="input_box_icon" size="24"/>
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';

export default {
  components: {
    LabelComponent,
    IconComponent,
  },
  emits: ['focusChange'],
  data: () => ({
    isFocused: false,
  }),
  watch: {
    setFocus() {
      this.setFocused(this.setFocus);
    },
  },
  props: {
    title: {
      default: '',
      required: false,
    },
    hideData: {
      default: false,
      required: false,
    },
    iconUrl: {
      default: null,
      required: false,
    },
    setFocus: {
      default: false,
      required: false,
    },
    flexHeight: {
      default: false,
      required: false,
    },
  },
  created() {
    document.addEventListener('click', this.checkFocusOut);
    window.escapeableElements.push(this.escKey);
  },
  mounted() {
    this.setFocused(this.setFocus);
  },
  computed: {

  },
  unmounted() {
    document.removeEventListener('click', this.checkFocusOut);

    const index = window.escapeableElements.indexOf(this.escKey);
    if (index > -1) {
      window.escapeableElements.splice(index, 1);
    }
  },
  methods: {
    escKey() {
      this.setFocused(false);
    },
    checkFocusOut(event) {
      if (!this.isFocused) return;

      // закрытие режима редактирования
      const rootElem = this.$refs.root;
      if (rootElem != null && !rootElem.contains(event.target) && document.body.contains(event.target)) {
        this.setFocused(false);
      }
    },
    setFocused(val) {
      this.isFocused = val;
      this.$emit('focusChange', this.isFocused);
    },
  },
};
</script>

<style lang="less">
.input_box {
  .flex(row, flex-start, center);
  // background: red;
  border: 1px solid @no-accent-text-color;
  border-radius: 6px;
  min-height: 58px;
  padding: 15px;
  width: 100%;
  cursor: text;

  &_fixed_height {
    height: 58px;
    max-height: 58px;
  }

  &_focused {
    border-color: @base-accent;
  }

  &_content {
    .flex(column, flex-start, stretch);
    user-select: none;
    flex-grow: 1;

    .input_box_title {
      color: @no-accent-text-color;

      & > * {
        font-weight: normal;
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  .input_box_hide_data {
    .input_box_title > * {
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
    }

    .input_box_field {
      display: none;
    }
  }

  &_icon {
    cursor: pointer;
  }
}
</style>
