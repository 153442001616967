<template>
  <div class="profile-wraper">
    <div class="flex-column">
      <LabelComponent class="profile-block-header" label_text="Безопасность" label_type="h2"/>
      <LabelComponent class="profile-block-description" label_text="Здесь находятся настройки безопасности вашего профиля" label_type="body/large/regular 16"/>

      <br/>

      <LabelComponent label_type="h3"
                      label_text = "Смена пароля"/>
      <BlockSeparator separatorType="horizontal"/>

      <ChangePassword />

    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import BlockSeparator from '@/units/forms/BlockSeparator.vue';

import ChangePassword from '@/baseLayout/topPanel/ChangePassword.vue';

export default {
  components: {
    LabelComponent,
    BlockSeparator,
    ChangePassword,
  },
  data: ()=> ({
    active_subscription_plan: {},
    is_subscription_exists: null,
    is_add_plan_modal: false,
  }),
  props: {
  },
  mounted() {

  },
  computed: {

  },
  methods: {
  },
};
</script>
