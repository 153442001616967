<!-- eslint-disable vue/require-component-is -->
<template>
  <div class="diagram-tile" :style="`min-width: ${this.max_width}px; max-width: ${this.max_width}px;`">
    <div class="diagram-tile-content content-box">

      <!-- :href="this.draw_page.fullUrl" :newTab="this.draw_page.open_new_tab === true" -->

      <div class="diagram-tile-content-header" @click="this.openPage">
        <div class="diagram-tile-content-header-caption">
          <div class="icon-wrapper">
            <IconComponent size=37 :url="this.draw_page.icon_url"/>
          </div>

          <LabelComponent label_type="h3"
                          :label_text="this.draw_page.name"/>
        </div>

        <div class="diagram-tile-content-header-controls">
          <ButtonComponent elementType="hover"
                           elementStatus="selected"
                           :href="this.draw_page.fullUrl"
                           iconUrl="/icons/system/caret-circle-down.svg"
                           iconSize="22"/>
        </div>
      </div>
      <div class="flex-row">
        <ButtonComponent elementType="hover"
                          elementStatus="selected"
                          @click="this.requestReportData(true)"
                          iconUrl="/icons/system/arrow-counter-clockwise.svg"
                          iconSize="22"/>
        <LabelComponent v-if="this.cacheTime != null" :label_text="'Данные на: ' + String(this.cacheTime)" :is_no_accent="true"/>
      </div>
      <div class="diagram-tile-content-diagram">
        <SpinnerComponent v-if="this.isDiagramDataRequesting" style="width: 50px;" :width="5" />
        <LabelComponent v-if="this.dataRequestError != null" :label_text="this.dataRequestError" style="color: red;"/>
        <template v-if="this.curDiagramData != null">
          <!-- <HistogramChart
                  v-if="this.curChart == 'HistogramChart'"
                  :maxChartWidth="this.chartWidth"
                  :typeName="this.curDiagramData.type"
                  :rawSeries="this.curDiagramData.series"
                  :rawPoints="this.curDiagramData.points"
                  :rawValues="this.curDiagramData.values"
                  @seriesSelect="this.openPage"
                  />
          <GraphChart
                  v-if="this.curChart == 'GraphChart'"
                  :maxChartWidth="this.chartWidth"
                  :typeName="this.curDiagramData.type"
                  :rawSeries="this.curDiagramData.series"
                  :rawPoints="this.curDiagramData.points"
                  :rawValues="this.curDiagramData.values"
                  @seriesSelect="this.openPage"
                  /> -->
          <HybridChart
                  v-if="this.curChart == 'HistogramChart' || this.curChart == 'GraphChart' || this.curChart == 'HybridChart'"
                  :maxChartWidth="this.chartWidth"
                  :typeName="this.curDiagramData.type"
                  :rawSeries="this.curDiagramData.series"
                  :rawPoints="this.curDiagramData.points"
                  :rawValues="this.curDiagramData.values"
                  @seriesSelect="this.openPage"
          />
          <PieChart
                  v-if="this.curChart == 'PieChart'"
                  :maxChartWidth="this.chartWidth"
                  :typeName="this.curDiagramData.type"
                  :rawSeries="this.curDiagramData.series"
                  :rawPoints="this.curDiagramData.points"
                  :rawValues="this.curDiagramData.values"
                  @seriesSelect="this.openPage"
                />
        </template>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">

const DiagramTypesList = {
  'Гистограмма горизонтальная': 'HistogramChart',
  'Гистограмма': 'HistogramChart',
  'Гистограмма объемная': 'HistogramChart',
  'Гистограмма горизонтальная объемная': 'HistogramChart',
  'Гистограмма с накоплением': 'HybridChart',
  'График': 'GraphChart',
  'График с областями': 'GraphChart',
  'Смешанный': 'HybridChart',
  // 'График с накоплением': 'GraphChart',
  // 'График по шагам': 'GraphChart',
  'Кольцевая': 'PieChart',
  'Кольцевая объемная': 'PieChart',
  'Круговая': 'PieChart',
  'Круговая объемная': 'PieChart',
};

import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import LabelComponent from '@/units/RichLabel.vue';
import PieChart from '@/units/charts/PieChart.vue';
// import HistogramChart from '@/units/charts/HistogramChart.vue';
// import GraphChart from '@/units/charts/GraphChart.vue';
import HybridChart from '@/units/charts/HybridChart.vue';
import IconComponent from '@/units/RichIcon.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

import buildReport from '@/assets/dataSources/reports/buildReport.js';

export default {
  components: {
    SpinnerComponent,
    IconComponent,
    ButtonComponent,
    LabelComponent,
    PieChart,
    // HistogramChart,
    // GraphChart,
    HybridChart,
  },
  props: ['draw_page', 'max_width'],
  data: ()=> ({
    dataRequestError: null,
    requestVersion: 0,
    isDiagramDataRequesting: false,
    curDiagramData: null,
    cacheTime: null,
  }),
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) {
        return;
      }

      this.isDiagramDataRequesting = false;
      this.requestReportData();
    },
  },
  mounted() {
    this.requestReportData();
  },
  computed: {
    chartWidth() {
      return this.max_width - 24 - 24 - 13;
    },
    curChart() {
      return DiagramTypesList[this.curDiagramData.type];
    },
  },
  methods: {
    openPage() {
      this.$router.push(this.draw_page.fullUrl);
    },
    requestReportData(resetCache=false) {
      if (this.isDiagramDataRequesting) return;

      this.dataRequestError = null;

      this.curDiagramData = null;
      this.isDiagramDataRequesting = true;

      this.requestVersion += 1;
      const requestReportFormVersion = this.requestVersion;

      const buildForm = {};

      if (resetCache) {
        buildForm['__force_update_preview_cache'] = true;
      }

      buildReport(this.draw_page.uid, buildForm, (result) => {
        if (requestReportFormVersion != this.requestVersion) return;

        this.isDiagramDataRequesting = false;

        if (result._error == true) {
          if (result.status == null) {
            this.dataRequestError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else {
            this.dataRequestError = `${result.text} Статус: ${result.status}`;
          }
          return;
        }

        if (result.diagrams != null) {
          if (result.diagram_error != null) {
            this.dataRequestError = 'Ошибка формирования диаграммы. ' + String(result.diagram_error);
            return;
          }

          if (result.diagrams.length < 1) {
            this.dataRequestError = 'Отчет не содержит диаграмм';
            return;
          }

          this.curDiagramData = result.diagrams[0];
        } else {
          this.curDiagramData = result;
        }

        this.cacheTime = null;

        if (result.__cached__ != null) {
          this.cacheTime = new FixDate(result.__cached__).toLocaleString();
        } else {
          this.cacheTime = new FixDate().toLocaleString();
        }
      });
    },
  },
};


</script>

<style lang="less">
@report-tile__background-color: @white;
@report-tile__text__color: @dark;
@report-tile__text__hover__color: @light-green;
@report-tile__icon__background__color: @mint;
@report-tile__icon__color: @green;

@report-tile__next-arrow__icon__color: @green;

.flex-row {
  .flex(row, flex-start, center);
}

.diagram-tile{
  // .flex(column, flex-start, flex-start);
  // position: relative;
  //padding: 0 15px 24px;
  padding: 0 13px 13px 0;


  &-content{
    // .flex(row,  space-between, center);
    transition: color 0.2s;
    // background: @report-tile__background-color;
    text-decoration: none;
    // color: @report-tile__text__color;

    // padding: 21px 24px;

    width: 100%;
    height: 100%;
    flex-grow: 1;
    // border-radius: 10px;
    // box-shadow: 0 4px 14px rgba(46, 50, 45, 0.03);

    // &:hover{
    //   color: @report-tile__text__hover__color;
    // }

    &-header {
      cursor: pointer;

      .flex(row, space-between, center);
      width: 100%;

      &-caption {
        overflow-wrap: anywhere;
        .flex(row, center, center);

        .icon-wrapper{
          min-width: 52px;
          max-width: 52px;
          min-height: 52px;
          max-height: 52px;
          // background: @report-tile__icon__background__color;
          // border-radius: 50%;
          .flex(row,  center, center);
          .set_icon_color(@report-tile__icon__color);

          margin-right: 15px;
        }
      }

      &-controls {
        .flex(row, center, center);

        margin-left: 13px;

        & > * {
          margin-right: 25px;

          transform: rotate(-90deg);
        }

        & > *:last-child {
          margin-right: 0;
        }
      }
    }

    &-diagram {
      // max-height: 200px;
      overflow: hidden;
      margin-top: 9px;
    }
  }
}
</style>
