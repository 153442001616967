<template>
  <div class="dropdown-input">
    <InputField class="dropdown-input__input-block"
                :set_values="this.viewValue"
                :input_field_status="this.fieldStatus"
                :error_text="this.errorText"
                :label_text="this.labelText"
                :block_edit="true"
                :is_required="this.isRequired"
    />
    <Icon class="dropdown-arrow" url="/icons/system/caret-circle-down.svg"
          :style="{transform: this.isOpened ? 'rotate(180deg)' : 'rotate(0deg)'}"/>
    <div style="position: absolute; inset: 0;" @click="this.openDropdown"></div>
  </div>
</template>

<script>
import InputField from '@/units/forms/InputField.vue';
import Icon from '@/units/RichIcon.vue';
import DropDownSelector from './DropDownSelector.vue';

export default {
  components: {
    InputField,
    Icon,
  },
  data: () => ({
    isOpened: false,
    selectedValues: null,
    paginator: null,
  }),
  emits: ['inputChange'],
  props: {
    extenderHandler: {default: null, type: Object, required: false},
    editorHandler: {default: null, type: Object, required: false},
    removeHandler: {default: null, type: Object, required: false},

    isFilterable: {default: true, type: Boolean, required: false},
    isMultiSelect: {default: false, type: Boolean, required: false},

    itemList: {
      default: [
        {view: 'Первый', uid: '1'},
        {view: 'Второй', uid: '2'},
      ],
      required: false,
    },
    selectedItems: {
      default: [{view: 'Второй', uid: '2'}],
      required: false,
    },

    labelText: {default: 'Список', type: String, required: false},
    fieldStatus: {
      type: String,
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    errorText: {default: null, type: String, required: false},
    isRequired: {default: true, type: Boolean, required: false},
    favoritableIdx: {default: null, type: String, required: false},
  },
  watch: {
    itemList() {
      this.initPaginator();
    },
    selectedItems() {
      this.initDefaultSelections();
    },
  },
  computed: {
    viewValue() {
      if (this.selectedValues == null || this.selectedValues.length < 1) return null;
      const ans = this.selectedValues.map((elem) => elem.view).join(', ');
      return ans;
    },
  },
  mounted() {
    this.initPaginator();
    this.initDefaultSelections();
    this.$emit('inputChange', this.selectedItems ? this.selectedItems : []);
  },
  methods: {
    async defaultPaginator(pageNum, pageSize, searchViews, searchIds) {
      if (this.itemList == null) return [];

      const filteredList = this.itemList.filter(
          (elem) => (
            (searchViews == null && searchIds == null) ||
            (searchViews == null ? false : searchViews.filter((searchElem) => elem.view.toUpperCase().includes(searchElem.toUpperCase())).length > 0) ||
            (searchIds == null ? false : searchIds.filter((searchElem) => elem.uid == searchElem).length > 0)
          ),
      ).sort((a, b) => {
        if (searchViews != null) {
          const isAstarts = searchViews.filter((startView) => a.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;
          const isBstarts = searchViews.filter((startView) => b.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;

          if (isAstarts && !isBstarts) return -1;
          if (!isAstarts && isBstarts) return 1;
        }

        if (a.view < b.view) return -1;
        if (a.view > b.view) return 1;
        return 0;
      });

      return filteredList.slice(pageNum * pageSize, pageNum * pageSize + pageSize);
    },
    initPaginator() {
      if (typeof(this.itemList) == 'function') {
        this.paginator = this.itemList;
      } else {
        this.paginator = this.defaultPaginator;
      }
    },
    async initDefaultSelections() {
      let forSearchUIDs = this.selectedItems ? this.selectedItems.filter((elem) => elem.view == null).map((elem) => elem.uid) : [];
      if (forSearchUIDs.length > 0) {
        forSearchUIDs = Object.fromEntries((
          await this.paginator(0, forSearchUIDs.length, null, forSearchUIDs)
        ).map((elem) => [elem.uid, {view: elem.view, uid: elem.uid}]));

        this.selectedValues = this.selectedItems ? this.selectedItems.map(
            (elem) => elem.view != null ? elem : forSearchUIDs[elem.uid],
        ).filter((elem) => elem != null) : [];
        this.$emit('inputChange', this.selectedItems ? this.selectedItems : []);
        return;
      } else {
        let newVal = this.selectedItems ? this.selectedItems.map(
            (elem) => elem.view != null ? elem : forSearchUIDs[elem.uid],
        ).filter((elem) => elem != null) : [];

        if (this.selectedItems && newVal.length == this.selectedItems.length) {
          newVal = this.selectedItems;
          this.selectedValues = newVal;
          this.$emit('inputChange', this.selectedValues);
        } else {
          this.selectedValues = newVal;
          this.$emit('inputChange', this.selectedValues);
        }
        return;
      }
    },
    async openDropdown() {
      this.isOpened = true;
      const newSelections = await window.openWindow({
        caption: 'Выберите элемент' + (this.isMultiSelect ? 'ы' : ''),
        component: DropDownSelector,
        componentProps: {
          isMultiSelect: this.isMultiSelect,
          filterable: this.isFilterable,
          paginator: async (fa, sa, ta, la) => await this.paginator(fa, sa, ta, la),
          selections: this.selectedValues,
          favoritableIdx: this.favoritableIdx,
          extenderHandler: this.extenderHandler,
          editorHandler: this.editorHandler,
          removeHandler: this.removeHandler,
        },
      });
      if (newSelections != null) {
        this.selectedValues = newSelections;
        this.$emit('inputChange', newSelections);
      }
      this.isOpened = false;
    },
  },
};
</script>


<style lang="less">
.dropdown-input {
  & * {
    cursor: pointer;
  }

  position: relative;
  width: 100%;

  .dropdown-arrow {
    background-color: @green-opacity-30;
    position: absolute;
    right: 10px;
    top: 20px;
    transition: all 0.1s ease 0s;
  }

  &__input-block {
    position: relative;

    div {
      padding-right: 20px;
    }
  }
}
</style>
