<template>
  <LabelComponent :label_text="this.params.text"/>
  <div class="top-padding-gap flex-row">
    <ButtonComponent
                    :label="this.params.yesButtonText == null ? 'Да' : this.params.yesButtonText"
                    @click="this.$emit('result', 'yes')"
                    />
    <ButtonComponent
                    :label="this.params.noButtonText == null ? 'Нет' : this.params.noButtonText"
                    @click="this.$emit('result', 'no')"
                    />
    <ButtonComponent
                    :label="this.params.cancelButtonText == null ? 'Отмена' : this.params.cancelButtonText"
                    @click="this.$emit('result')"
                    />
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    LabelComponent,
    ButtonComponent,
  },
  data: () => ({

  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {

  },
  computed: {

  },
};
</script>
