<template>
  <div class="subscription-plan-adder-wraper flex-column">
    <SpinnerComponent v-if="this.is_loading" :width="7"/>

    <LabelComponent
                    v-else-if="this.data_source_list.length < 1"
                    label_text="Пока не добавлено ни одного источника. Для добавления воспользуйтесь кнопкой ниже."
                    label_type="body/large/regular 14"
                  />

    <div class="data-source-item content-box" v-for="elem in this.data_source_list" :key="elem">
      <div class="data-source-item-line flex-column">
        <!-- <LabelComponent label_text="Наименование:" label_type="body/large/regular 14" /> -->
        <LabelComponent :label_text="elem.view" label_type="h3"/>

        <template v-if="this.isSubscriptionEditor">
          <ButtonComponent
            elementType="default"
            iconUrl="/icons/system/file-arrow-down.svg"
            label="Сгенерировать ключ источника"
            :elementStatusModifier="this.is_loading ? 'disabled' : undefined"
            @click_to_button="this.getNewDsKey(elem.uid)"
          />

          <ButtonComponent
            elementType="default"
            iconUrl="/icons/system/gear.svg"
            label="Изменить"
            @click_to_button="this.openEditDSModal(elem)"
          />

          <ButtonComponent
            elementType="default"
            iconUrl="/icons/system/trash.svg"
            elementStatusModifier="danger"
            label="Удалить"
            @click_to_button="this.deleteDS(elem.uid)"
          />
        </template>
      </div>
    </div>

    <br />

    <ButtonComponent v-if="this.isSubscriptionEditor"
          elementType="default"
          iconUrl="/icons/system/plus.svg"
          elementSize="big"
          label="Добавить источник данных"
          @click_to_button="this.openAddDSModal"
        />
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';
import DataSourceForm from './DataSourceForm.vue';

import getConnectedDataSources from '@/assets/dataSources/subscription/getConnectedDataSources';
import genNewDataSourceKey from '@/assets/dataSources/subscription/genNewDataSourceKey';
import deleteDataSource from '@/assets/dataSources/subscription/deleteDataSource.js';


export default ({
  components: {
    ButtonComponent,
    LabelComponent,
    SpinnerComponent,
  },
  data: ()=> ({
    is_loading: true,
    data_source_list: [],
  }),
  props: ['isSubscriptionEditor'],
  mounted() {
    this.reloadConnectedDataSources();
  },
  computed: {

  },
  methods: {
    getNewDsKey(dsUid) {
      if (this.is_loading) {
        return;
      }

      this.is_loading = true;
      genNewDataSourceKey((keyFile) => {
        this.is_loading = false;
      }, dsUid);
    },
    reloadConnectedDataSources() {
      this.is_loading = true;
      getConnectedDataSources((dsData) => {
        this.is_loading = false;
        if (dsData === false) {
          this.data_source_list = [];
          // this.is_subscription_exists = false;
          return;
        }

        this.data_source_list = dsData;
      });
    },
    async openAddDSModal() {
      const addResult = await window.openWindow({
        caption: 'Заполните данные источника',
        component: DataSourceForm,
      });

      if (addResult != 'ok') return;

      this.reloadConnectedDataSources();
    },
    async openEditDSModal(dsData) {
      const addResult = await window.openWindow({
        caption: 'Изменение данных источника',
        component: DataSourceForm,
        componentProps: {
          dsData: dsData,
        },
      });

      if (addResult != 'ok') return;

      this.reloadConnectedDataSources();
    },
    async deleteDS(dsUid) {
      const selectResult = await window.openYNWindow(
          'Вы уверены что хотите удалить источник данных\nВсе связанные страницы будут удалены!\nЭто действие нельзя отменить!',
          'Удаление источника',
      );

      if (selectResult != 'yes') return;

      this.is_loading = true;
      deleteDataSource(dsUid, () => window.location.reload());
    },
  },
});
</script>
