import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/subscription/connect_user/';

function subUserConnector(userId, handler) {
  const sendForm = new FormData();
  sendForm.set('connect_user_id', userId);

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: sendForm,
  }).then(async (response) => {
    if (response.status == 200) {
      handler({
        status: response.status,
        text: 'ok',
      });
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка добавления пользователя. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default subUserConnector;
