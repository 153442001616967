<template>
  <div class="navigation-sliders" ref="navigation_parent">

    <div class="slider-tiles-wrapper" v-if="this.get_inner_structure.length !== 0">
      <PageGroupReportDiagramTile v-for="item, i in this.get_list_reports_preview" :key="i"
                :draw_page="item"
                :max_width="Math.max(this.contentWidth / this.currentColumnsCount, 250)"
                class="large-tile"/>

      <PageGroupReportTile v-for="(item, i) in this.get_list_reports" :key="i"
                :loc_data="item"
                class="small-tile"/>
    </div>

    <div
          class="tiles-not-found"
          v-if="this.get_list_reports_preview.length === 0 && this.get_list_reports.length === 0">
      <LabelComponent
        label_text="Нет доступных категорий"
        label_type="body/large/regular 16"
        class="tiles-not-found-text"
      />
    </div>
  </div>
</template>

<script type="text/javascript">
import PageGroupReportTile from './PageGroupReportTile.vue';
import PageGroupReportDiagramTile from './PageGroupReportDiagramTile.vue';
import LabelComponent from '@/units/RichLabel.vue';

export default {
  components: {
    LabelComponent,
    PageGroupReportTile,
    PageGroupReportDiagramTile,
  },
  data: () => ({
    contentWidth: null,
    currentColumnsCount: 3,
  }),
  witoutBackground: true,
  computed: {
    get_inner_structure() {
      const currentPage = this.$store.state.current_page_info;
      return currentPage ? currentPage.children : this.defautPageList;
    },
    get_list_reports() {
      return this.get_inner_structure.filter(function(item) {
        return !item.enable_diagram_preview;
      });
    },
    get_list_reports_preview() {
      return this.get_inner_structure.filter((item) => item.enable_diagram_preview);
    },
  },
  methods: {
    winResize() {
      this.currentColumnsCount = 2;

      this.currentColumnsCount = window.innerWidth > window.transitionThreshold3 ? 3 : this.currentColumnsCount;
      this.currentColumnsCount = window.innerWidth <= window.transitionThreshold2 ? 1 : this.currentColumnsCount;

      if (this.$refs.navigation_parent == null) return;

      const navRect = this.$refs.navigation_parent.getBoundingClientRect();
      this.contentWidth = navRect.width;

      setTimeout(() => {
        const navRect = this.$refs.navigation_parent.getBoundingClientRect();
        this.contentWidth = navRect.width;
      }, 100);
    },
  },
  mounted() {
    const element = document.getElementById('content-slider');
    if (element) {
      element.classList.remove('content-wrapper-type-1__slider__content__back');
      element.classList.remove('content-wrapper-type-2__page__content__slider__back');
    }

    window.addEventListener('resize', this.winResize);

    setTimeout(this.winResize, 1);
  },
  unmounted() {
    window.addEventListener('resize', this.winResize);
  },
  props: {
    defautPageList: {
      default: [],
      required: false,
    },
  },
};


</script>

<style lang="less">

.navigation-sliders{
  .flex(column, flex-start, stretch);
  & > * {
    margin-bottom: 15px;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
  padding-bottom: 20px;
}

.slider-tiles-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media screen and (max-width: @transition-threshold-3) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: @transition-threshold-2) {
    grid-template-columns: 1fr;
  }
}

.large-tile {
  grid-row: span 3;
}

.small-tile {
  align-content: center;
}

.tiles-not-found{
  background: @white;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0 4px 14px rgba(46, 50, 45, 0.03);
}
</style>
