<template>
  <div class="date-constructor">
    <div v-if="this.label != null" class="header-label">
      <LabelComponent label_type="h3" :label_text="this.label"/>
    </div>

    <DateInput  v-if="this.useAdditionalDateField"
                class="header-label"
                @elementChange="this.additionChange"
                :base_day="this.day"
                :base_month="this.month + 1"
                :base_year="this.year"
                />

    <div class="header">
      <LabelComponent label_text="<"
                      label_type="body/large/regular 12"
                      class="arrow"
                      @click="this.prevHead"
                      :class="[this.canBack ? null : 'arrow-inactive']"/>
      <div class="header-label-wrapper" @click="this.nextMode" :class="{'is-dozen-header': this.curMode == 'year'}">
        <LabelComponent label_type="body/large/regular 12"
                        :label_text="this.headerText"/>
      </div>
      <LabelComponent label_text=">"
                      label_type="body/large/regular 12"
                      class="arrow"
                      @click="this.nextHead"
                      :class="[this.canForward ? null : 'arrow-inactive']"/>
    </div>

    <div class="day-picker" :class="this.useFixedHeight ? 'picker-h-fix' : null" v-if="this.curMode == 'day'">
      <table class="picker-table">
        <tr>
          <td v-for="item in this.weekNames" :key="item" class="week-names">
            <LabelComponent label_type="body/large/regular 12" :label_text="item.narrow"/>
          </td>
        </tr>

        <tr v-for="(itemRow) in this.curMonthTable" :key="itemRow" class="days-row">
            <td v-for="(itemColumn) in itemRow" :key="itemColumn" class="day-cell" :class="{
              'no-active-day': itemColumn.no_active,
              'selected': itemColumn.selected,
              }" @click="this.selectElem(itemColumn)">
              <LabelComponent label_type="body/large/regular 12" :label_text="itemColumn.day"/>
            </td>
        </tr>
      </table>
    </div>

    <div class="month-picker" :class="this.useFixedHeight ? 'picker-h-fix' : null" v-if="this.curMode == 'month'">
      <table class="picker-table">
        <tr v-for="itemRow in this.curYearTable" :key="itemRow" class="months-row" >
          <td v-for="itemColumn in itemRow" :key="itemColumn" class="month-cell" :class="{
            'selected': itemColumn.selected,
            }" @click="this.selectElem(itemColumn)">
            <div>
              <LabelComponent label_type="body/large/regular 12" :label_text="itemColumn.text" />
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="year-picker" :class="this.useFixedHeight ? 'picker-h-fix' : null" v-if="this.curMode == 'year'">
      <table class="picker-table">
        <tr v-for="itemRow in this.getDozen" :key="itemRow" class="years-row">
          <td v-for="itemColumn in itemRow" :key="itemColumn" class="year-cell" :class="{
            'selected': itemColumn.selected,
            }" @click="this.selectElem(itemColumn)">
            <LabelComponent label_type="body/large/regular 12"
                            :label_text="itemColumn.text"/>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
import DateInput from '@/units/InputFields/DateTimeInput/DateInput.vue';

function getWeekStart(reqRegion) {
  const parts = reqRegion.match(
      /^([a-z]{2,3})(?:-([a-z]{3})(?=$|-))?(?:-([a-z]{4})(?=$|-))?(?:-([a-z]{2}|\d{3})(?=$|-))?/i,
  );

  const region = parts[4];
  const language = parts[1];
  const regionSat = 'AEAFBHDJDZEGIQIRJOKWLYOMQASDSY'.match(/../g);
  const regionSun = ('AGARASAUBDBRBSBTBWBZCACNCODMDOETGTGUHKHNIDILINJMJPKEKHKRLAMHMMMOMTMXMZNINPPAPEPHPKPRPT' +
      'PYSASGSVTHTTTWUMUSVEVIWSYEZAZW').match(/../g);
  const languageSat = ['ar', 'arq', 'arz', 'fa'];
  const languageSun = 'amasbndzengnguhehiidjajvkmknkolomhmlmrmtmyneomorpapssdsmsnsutatethtnurzhzu'
      .match(/../g);

  return (
  region ? (
      regionSun.includes(region) ? 0 :
          regionSat.includes(region) ? 7 : 1) : (
      languageSun.includes(language) ? 0 :
          languageSat.includes(language) ? 7 : 1));
}

function getUTCMonthsForLocale(locale) {
  const format = new Intl.DateTimeFormat(locale, {month: 'short'});
  const months = [];
  for (let month = 0; month < 12; month++) {
    const testDate = new Date(2000, month, 15);
    months.push(format.format(testDate));
  }
  return months;
}

export default {
  components: {
    LabelComponent,
    DateInput,
  },
  data: () => ({
    curMode: 'day',
    day: 1,
    month: 0,
    year: 1,
  }),
  emits: ['result', 'selectDate', 'selectDay', 'selectMonth', 'selectYear'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  watch: {
    base_day() {
      this.day = this.extDay;
    },
    base_month() {
      this.month = this.extMonth;
    },
    base_year() {
      this.year = this.extYear;
    },
    day() {
      this.$emit('selectDay', {day: this.day, month: this.month+1, year: this.year});
    },
    month() {
      this.day = Math.min(this.maxDay, this.day);
      this.$emit('selectMonth', {day: this.day, month: this.month+1, year: this.year});
    },
    year() {
      this.day = Math.min(this.maxDay, this.day);
      this.$emit('selectYear', {day: this.day, month: this.month+1, year: this.year});
    },
  },
  mounted() {
    this.day = this.extDay;
    this.month = this.extMonth;
    this.year = this.extYear;
  },
  methods: {
    additionChange(data) {
      this.day = data.day == null ? 1 : data.day;
      this.month = data.month == null ? 0 : data.month - 1;
      this.year = data.year == null ? (new Date()).getFullYear() : data.year;
    },
    nextMode() {
      if (this.curMode == 'month') this.curMode = 'year';
      if (this.curMode == 'day') this.curMode = 'month';
    },
    nextHead() {
      if (!this.canForward) return;

      if (this.curMode == 'day') {
        if (this.month > 10) {
          this.month = 0;
          this.year += 1;
        } else {
          this.month += 1;
        }
      }

      if (this.curMode == 'month') {
        this.year += 1;
      }

      if (this.curMode == 'year') {
        this.year += 12;
      }
    },
    prevHead() {
      if (!this.canBack) return;

      if (this.curMode == 'day') {
        if (this.month < 1) {
          this.month = 11;
          this.year -= 1;
        } else {
          this.month -= 1;
        }
      }

      if (this.curMode == 'month') {
        this.year -= 1;
      }

      if (this.curMode == 'year') {
        this.year -= 12;
      }
    },
    selectElem(elem) {
      if (this.curMode == 'day') {
        this.day = elem.data;
        this.month = elem.month_data;
        this.year = elem.year_data;

        const result = {day: this.day, month: this.month+1, year: this.year};

        this.$emit('selectDate', result);
        this.$emit('result', result);
      }
      if (this.curMode == 'month') this.month = elem.data;
      if (this.curMode == 'year') this.year = elem.data;

      if (this.curMode == 'month') this.curMode = 'day';
      if (this.curMode == 'year') this.curMode = 'month';
    },
  },
  computed: {
    label() {
      return this.params.label;
    },
    base_day() {
      return this.params.base_day;
    },
    base_month() {
      return this.params.base_month;
    },
    base_year() {
      return this.params.base_year;
    },
    useAdditionalDateField() {
      return !this.params.hide_additional_date_field;
    },
    useFixedHeight() {
      return this.params.use_fixed_height;
    },
    extDay() {
      return Math.max(1, Math.min(this.maxDay, this.base_day == null ? 1 : this.base_day));
    },
    extMonth() {
      return Math.max(0, Math.min(11, this.base_month == null ? 0 : this.base_month-1));
    },
    extYear() {
      return this.base_year == null ? (new Date()).getFullYear() : this.base_year;
    },
    maxDay() {
      if (this.month == null) return 31;
      const year = this.year == null || this.year == 0 ? 1 : this.year;
      const ans = new Date(year, this.month+1, 0).getDate();
      return ans;
    },
    canBack() {
      return true;
    },
    canForward() {
      return true;
    },
    headerText() {
      if (this.curMode == 'day') return this.curMonthName+' '+ String(this.year).padStart('4', '0');
      if (this.curMode == 'month') return String(this.year).padStart('4', '0');
      if (this.curMode == 'year') return String(this.year - 4).padStart('4', '0') + ' - ' + String(this.year - 4 + 11).padStart('4', '0');

      return '-';
    },
    curMonthName() {
      const curDate = new Date(1, this.month, 1);
      const formatter = new Intl.DateTimeFormat(this.calendarRegion, {month: 'long'});
      return formatter.format(curDate);
    },
    calendarRegion() {
      return 'ru';
    },
    weekNames() {
      const locale = this.calendarRegion;
      const weekStartDay = getWeekStart(locale);
      const weekdayDateMap = [
        new Date('2020-01-05T00:00:00.000Z'),
        new Date('2020-01-06T00:00:00.000Z'),
        new Date('2020-01-07T00:00:00.000Z'),
        new Date('2020-01-08T00:00:00.000Z'),
        new Date('2020-01-09T00:00:00.000Z'),
        new Date('2020-01-10T00:00:00.000Z'),
        new Date('2020-01-11T00:00:00.000Z'),
      ];
      const weekdayNames = weekdayDateMap.map((item, index) => ({
        id: index,
        narrow: new Intl.DateTimeFormat(locale, {weekday: 'narrow'}).format(item),
        short: new Intl.DateTimeFormat(locale, {weekday: 'short'}).format(item),
        long: new Intl.DateTimeFormat(locale, {weekday: 'long'}).format(item),
      }));
      weekdayNames.sort((itemA, itemB) => {
        const idA = (itemA.id < weekStartDay) ? itemA.id + 10 : itemA.id;
        const idB = (itemB.id < weekStartDay) ? itemB.id + 10 : itemB.id;
        if (idA < idB) return -1;
        else if (idB < idA) return 1;
        else return 0;
      });

      return weekdayNames;
    },
    curMonthTable() {
      const weekInfo = this.weekNames;
      const weekStartDay = weekInfo[0].id;
      const monthStartDay = new Date(this.year, this.month, 1).getDay();

      const prevMonthDays = new Date(this.year, this.month, 0).getDate();
      const curMonthDays = new Date(this.year, this.month + 1, 0).getDate();

      // Сколько дней нужно подкачать спереди
      let prevDaysCount = monthStartDay - weekStartDay;
      // Если наш месяц начинался раньше чем начало недели, будет отрицательное число. Прибавляем 7 дней
      if (prevDaysCount < 0) prevDaysCount += 7;

      const ans = [[]];

      while (prevDaysCount > 0) {
        ans[0].push({
          day: prevMonthDays-(prevDaysCount-1),
          selected: false, // compareDates(curDate, this.select_date),
          no_active: true,
          data: prevMonthDays-(prevDaysCount-1),
          month_data: this.month - 1 < 0 ? 11 : this.month - 1,
          year_data: this.month - 1 < 0 ? this.year - 1 : this.year,
        });
        prevDaysCount--;
      }

      let curWeekNum = 0;
      let curMonthCounter = 1;

      while (curMonthCounter <= curMonthDays) {
        if (ans[curWeekNum].length > 6) {
          curWeekNum++;
          ans.push([]);
        }
        ans[curWeekNum].push({
          day: curMonthCounter,
          selected: this.day == curMonthCounter,
          no_active: false,
          data: curMonthCounter,
          month_data: this.month,
          year_data: this.year,
        });
        curMonthCounter++;
      }

      let nextMonthCounter = 1;

      while (ans[curWeekNum].length < 7) {
        ans[curWeekNum].push({
          day: nextMonthCounter,
          selected: false, // compareDates(curDate, this.select_date),
          no_active: true,
          data: nextMonthCounter,
          month_data: this.month + 1 > 11 ? 0 : this.month + 1,
          year_data: this.month + 1 > 11 ? this.year + 1 : this.year,
        });
        nextMonthCounter++;
      }

      if (ans.length < 6) {
        ans.push([]);
        curWeekNum++;
        while (ans[curWeekNum].length < 7) {
          ans[curWeekNum].push({
            day: nextMonthCounter,
            selected: false, // compareDates(curDate, this.select_date),
            no_active: true,
            data: nextMonthCounter,
            month_data: this.month + 1 > 11 ? 0 : this.month + 1,
            year_data: this.month + 1 > 11 ? this.year + 1 : this.year,
          });
          nextMonthCounter++;
        }
      }

      return ans;
    },
    curYearTable() {
      let curMonths = getUTCMonthsForLocale(this.calendarRegion);

      curMonths = curMonths.map((item, idx) => {
        return {
          text: item,
          selected: this.month == idx,
          data: idx,
        };
      });

      return [
        [curMonths[0], curMonths[1], curMonths[2]],
        [curMonths[3], curMonths[4], curMonths[5]],
        [curMonths[6], curMonths[7], curMonths[8]],
        [curMonths[9], curMonths[10], curMonths[11]]];
    },
    getDozen() {
      const years = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((idx) => {
        const curYear = this.year - 4 + idx;
        return {
          selected: this.year == curYear,
          text: String(curYear).padStart(4, '0'),
          data: curYear,
        };
      });

      return [
        [years[0], years[1], years[2]],
        [years[3], years[4], years[5]],
        [years[6], years[7], years[8]],
        [years[9], years[10], years[11]]];
    },
  },
};
</script>

<style lang="less">
.date-constructor {
  min-width: 200px;
  width: 200px;
  max-width: 200px;

  .header-label {
    margin-bottom: @base-gap;
  }

  .header {
    .flex(row, flex-start, center);
    margin: 0 16px 5px;

    &-label-wrapper {
      margin: auto;
      color: @base-accent;
      cursor: pointer;
    }

    .arrow {
      &-inactive {
        color: @no-accent-text-color;
        background-color: @button-back-hover-color;
        cursor: default;
      }
      border-radius: 24px;
      background-color: @button-back-color;
      min-width: 24px;
      min-height: 24px;
      .flex(column, center, center);
      cursor: pointer
    }

    .is-dozen-header {
      cursor: default;
    }
  }

  .picker-h-fix {
    min-height: 200px;
    height: 200px;
    max-height: 200px;
  }

  .picker-table {
    width: 100%;
  }

  .week-names {
    color: @no-accent-text-color;
    margin-bottom: @base-gap;
    text-align: center;
  }

  .days-row {
    .no-active-day {
      color: @no-accent-text-color;
    }

    .day-cell {
      align-content: center;
      text-align: center;
      width: 28px;
      height: 28px;
      cursor: pointer;

      &:hover {
        background-color: @button-back-hover-color;
        fill: @base-accent;
        stroke: @base-accent;
        color: @base-accent;
        border-radius: 50%;
      }
    }

    .selected {
      background-color: @button-back-hover-color;
      border-radius: 50%;
    }
  }

  .months-row {
    height: 28px;

    .month-cell {
      cursor: pointer;
      text-align: center;
      border-radius: 5px;

      &:hover {
        background-color: @button-back-hover-color;
        color: @base-accent;
      }
    }

    .selected {
      background-color: @button-back-hover-color;
      border-radius: 5px;
    }
  }

  .years-row {
    height: 28px;

    .year-cell {
      cursor: pointer;
      text-align: center;
      border-radius: 5px;

      &:hover {
        background-color: @button-back-hover-color;
        color: @base-accent;
      }
    }

    .selected {
      background-color: @button-back-hover-color;
      border-radius: 5px;
    }
  }
}
</style>
