<template>
  <div class="page-editor-wraper flex-column">
    <LabelComponent v-if="this.savingError != null" :label_text="this.savingError" style="color: red;"/>
    <ChangeObjectForm
                      :raw_fields="this.drawFields"
                      :raw_actions="this.drawActions"
                      @completeFormAction="this.savePage"
                    />

    <ButtonComponent
                      v-if="this.isAccesable"
                      label="Редактировать доступы"
                      elementType="default"
                      @click="this.openAccessEditor"
                      />
  </div>
</template>

<script type="text/javascript">
import ChangeObjectForm from '@/ioLayout/ChangeObjectForm.vue';
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

import reportIconList from '@/assets/reportIconList.js';
import pageAddOrChange from '@/assets/dataSources/navigation/pageAddOrChange.js';
import getWebSiteStructure from '@/assets/dataSources/getWebSiteStructure';

import PageAccessEditor from '@/subscriptionLayout/PageAccessEdtor.vue';

export default {
  components: {
    ChangeObjectForm,
    LabelComponent,
    ButtonComponent,
  },
  data: () => ({
    savingError: null,
    isSaving: false,
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    savePage(saveForm) {
      if (this.isSaving) return;

      this.savingError = null;
      this.isSaving = true;
      // console.log('saveForm', saveForm);
      // saveForm.entries().forEach((pair) => console.log(pair[0]+ ', ' + pair[1]));
      pageAddOrChange(this.page_uid == null, saveForm, (result) => {
        this.isSaving = false;

        if (result._error == true) {
          if (result.status == null) this.savingError = `${result.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          else this.savingError = `${result.text} Статус: ${result.status}`;
          return;
        }

        getWebSiteStructure(async (result) => {
          this.$store.commit('web_site_struct_mut', result);

          const parentPage = this.$store.state.web_site_struct[saveForm.get('parent_page_uid')];

          await window.openAcceptWindow('Панель навигации обновлена!');

          this.$router.replace(parentPage == null ? '/' : parentPage.fullUrl);

          this.$emit('result');
        });
      });
    },
    openAccessEditor() {
      window.openWindow({
        caption: 'Редактирование доступов',
        component: PageAccessEditor,
        componentProps: {
          page_uid: this.page_uid,
        },
      });
    },
  },
  computed: {
    isAccesable() {
      return this.page_uid != null && (this.page_type != 'group');
    },
    page_uid() {
      return this.params.page_uid;
    },
    page_type() {
      return this.params.page_type;
    },
    initial_name() {
      return this.params.initial_name == null ? '' : this.params.initial_name;
    },
    initial_icon() {
      return this.params.initial_icon;
    },
    initial_parent_id() {
      return this.params.initial_parent_id;
    },
    data_source_uid() {
      return this.params.data_source_uid;
    },
    initial_object_uid() {
      return this.params.initial_object_uid;
    },
    initial_is_object_list() {
      return this.params.initial_is_object_list;
    },
    initial_open_new_tab() {
      return this.params.initial_open_new_tab;
    },
    initial_enable_diagram_preview() {
      return this.params.initial_enable_diagram_preview !== false;
    },
    initial_can_edit_storage() {
      return this.params.initial_can_edit_storage !== false;
    },
    initial_in_fast_panel() {
      return this.params.initial_in_fast_panel !== false;
    },
    initial_url() {
      return this.params.initial_url;
    },

    getNotNullIcon() {
      if (this.initial_icon != null) return this.initial_icon;

      return '/icons/reports/' + reportIconList[Math.floor(Math.random()*reportIconList.length)];
    },
    drawFields() {
      return [
        {
          'type': 'text',
          'view': 'page_uid',
          'system_name': 'page_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true,
          'value': this.page_uid,
        },
        {
          'type': 'text',
          'view': 'page_type',
          'system_name': 'page_type',
          'nullable': false,
          'readonly': false,
          'is_hidden': this.page_type != null,
          'value': this.page_type,
        },
        {
          'type': 'text',
          'view': 'Наименование',
          'system_name': 'name',
          'nullable': false,
          'readonly': false,
          'is_hidden': false,
          'value': this.initial_name,
        },
        {
          'type': 'boolean',
          'view': 'Добавить в панель быстрого доступа',
          'system_name': 'in_fast_panel',
          'nullable': true,
          'readonly': false,
          'is_hidden': false,
          'value': this.initial_in_fast_panel,
        },
        {
          'type': 'boolean',
          'view': 'Открывать в новой вкладке',
          'system_name': 'open_new_tab',
          'nullable': true,
          'readonly': false,
          'is_hidden': false,
          'value': this.initial_open_new_tab,
        },
        {
          'type': 'boolean',
          'view': 'Выводить диаграмму на предпросмотр (при наличии)',
          'system_name': 'enable_diagram_preview',
          'nullable': true,
          'readonly': false,
          'is_hidden': this.page_type != 'report',
          'value': this.initial_enable_diagram_preview,
        },
        {
          'type': 'boolean',
          'view': 'Разрешено редактировать структуру',
          'system_name': 'can_edit_storage',
          'nullable': true,
          'readonly': false,
          'is_hidden': this.page_type != 'storage',
          'value': this.initial_can_edit_storage,
        },
        {
          'type': 'icon',
          'view': 'Иконка страницы',
          'system_name': 'icon_path',
          'nullable': true,
          'readonly': false,
          'is_hidden': false,
          'value': this.getNotNullIcon,
        },
        {
          'type': 'navelem',
          'view': 'Папка',
          'system_name': 'parent_page_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': false,
          'exclude_uids': this.page_uid == null ? [] : [this.page_uid],
          'value': this.initial_parent_id,
        },

        {
          'type': 'text',
          'view': 'DataSource',
          'system_name': 'source_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true,
          'value': this.data_source_uid,
        },
        {
          'type': 'text',
          'view': 'ObjectUid',
          'system_name': 'object_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true,
          'value': this.initial_object_uid,
        },

        {
          'type': 'text',
          'view': 'DataSourceReport',
          'system_name': 'report_source_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true, // this.data_source_uid != null || this.page_type != 'report',
          'value': this.data_source_uid,
        },
        {
          'type': 'text',
          'view': 'DataSourceEntity',
          'system_name': 'entity_source_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true, // this.data_source_uid != null || this.page_type != 'entity',
          'value': this.data_source_uid,
        },
        {
          'type': 'text',
          'view': 'ReportUid',
          'system_name': 'report_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true, // this.initial_object_uid != null || this.page_type != 'report',
          'value': this.initial_object_uid,
        },
        {
          'type': 'text',
          'view': 'EntityUid',
          'system_name': 'entity_uid',
          'nullable': true,
          'readonly': false,
          'is_hidden': true, // this.initial_object_uid != null || this.page_type != 'entity',
          'value': this.initial_object_uid,
        },
        {
          'type': 'boolean',
          'view': 'is_entity_list',
          'system_name': 'is_entity_list',
          'nullable': true,
          'readonly': false,
          'is_hidden': this.page_type != 'entity',
          'value': this.initial_is_object_list,
        },
        {
          'type': 'text',
          'view': 'Ссылка',
          'system_name': 'url',
          'nullable': this.page_type != 'ext_link',
          'readonly': false,
          'is_hidden': this.page_type != 'ext_link',
          'value': this.initial_url,
        },
      ];
    },
    drawActions() {
      return [
        {
          system_name: 'save',
          view: 'Сохранить',
          is_loading: this.isSaving,
        },
      ];
    },
  },
};
</script>
