import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/subscription/set_page_access/?page_uid=';

function setPageAccess(handler, pageUid, allowUsers, allowGroups, denyUsers, denyGroups) {
  const form = new FormData();

  allowUsers.forEach((elem) => form.append('allow_users', elem.uid));
  allowGroups.forEach((elem) => form.append('allow_groups', elem.uid));
  denyUsers.forEach((elem) => form.append('deny_users', elem.uid));
  denyGroups.forEach((elem) => form.append('deny_groups', elem.uid));

  fetch(ROOT_URL + REQUEST_LINK + pageUid, {
    method: 'POST',
    credentials: 'include',
    body: form,
  }).then(async (response) => {
    if (response.status == 200) {
      handler('ok');
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка получения цены. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default setPageAccess;
