import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
import store from '@/store';

const FOR_REPORT_REQUEST_LINK = '/iapi_report/get_report_page_form_entities/?page_uid=';
const STD_REQUEST_LINK = '/iapi_report/get_preview_entity_list/?entity=';

function getObjectList(dsUid, typeLink, handler) {
  const REQUEST_LINK = (
    store.state.current_page_info &&
    store.state.current_page_info.page_type == 'report'
  ) ? FOR_REPORT_REQUEST_LINK + store.state.current_page_info.uid + '&field_name=' :
  STD_REQUEST_LINK;

  fetch(ROOT_URL + REQUEST_LINK + typeLink + '&ds_uid=' + dsUid, {
    method: 'GET',
    credentials: 'include',
  }).then(async function(response) {
    // const objectTypeData = await response.json();
    // // store.commit('set_full_screen_loading', false);
    // handler(objectTypeData);

    if (response.status == 200) {
      handler(await response.json());
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка работы получения списка объектов. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default getObjectList;
