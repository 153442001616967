<template>
  <div class = "profile-frame" ref = "profile_frame">
    <div class = "profile-frame__preview">
      <IconComponent :url="'/icons/system/human.svg'" v-if="this.get_is_mobile_info" color="black" size="28" class="icon-over-transparency-color"/>
      <LabelComponent v-if = "!this.get_is_mobile_info" class = "profile-frame__username"
                      label_type = 'button/large/medium 14'
                      :label_text = "get_user_full_name"/>

      <IconComponent v-if = "!this.get_is_mobile_info"
                     class="icon-over-transparency-color"
                     type = 'svg' url = "/icons/system/caret-circle-down.svg" size = "16"
                     :class = "{'profile-frame__icon':true, 'profile-frame__icon-opened':this.dropped}"/>
    </div>
    <div class = "profile-frame__user-menu" v-if = "this.dropped">
      <span ref = "dropdown">
        <ProfileUserMenu :menuItems = "menuItems" @select_elem = "select_elem"/>
        <ProfileUserMenuMobile :menuItems = "menuItems"
                               @close_modal = "dropped=false" @select_elem = "select_elem"/>
      </span>
    </div>
  </div>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';
import ProfileUserMenu from './ProfileUserMenu';
import ProfileUserMenuMobile from './ProfileUserMenuMobile';
import logoutUser from '@/assets/dataSources/auth/logoutUser';
import isMobileMixin from '@/assets/mixins/isMobileMixin';


export default {
  name: 'ProfileFrame',

  data: () => ({
    dropped: false,
  }),
  mixins: [isMobileMixin],
  components: {
    IconComponent,
    LabelComponent,
    ProfileUserMenu,
    ProfileUserMenuMobile,
  },
  computed: {
    get_user_info() {
      return this.$store.state.user_obj;
    },
    get_user_full_name() {
      if (!this.get_user_info) return 'Anon';
      if (!this.get_user_info['first_name'] && !this.get_user_info['last_name']) return 'Неизвестное имя';
      return [this.get_user_info['first_name'], this.get_user_info['last_name']].join(' ');
    },
    menuItems() {
      if (!this.get_user_info || this.get_user_info.is_anonymous) {
        return [
          {
            name: 'Cайт компании',
            icon: '/icons/system/globe.svg',
            callFunc: () => {
              location.href = 'https://zerofactor.ru/';
            },
          },
          {
            name: 'Документация',
            icon: '/icons/system/help_round.svg',
            separatorTop: true,
            callFunc: () => {
              location.href = 'https://docs.zerofactor.ru/';
            },
          },
          {
            name: 'Вход',
            separatorTop: true,
            icon: '/icons/system/sign-out.svg',
            callFunc: () => {
              location.href = '/login';
            },
          },
        ];
      }

      return [
        {
          name: 'Профиль',
          separatorBottom: true,
          icon: '/icons/system/badge-002.svg',
          callFunc: () => {
            this.$router.push('/settings/profile/');
          },
        },
        {
          name: 'Управление подпиской',
          separatorBottom: true,
          icon: '/icons/system/finres_icon.svg',
          callFunc: () => {
            this.$router.push('/settings/subscription/');
          },
        },
        {
          name: 'Cайт компании',
          icon: '/icons/system/globe.svg',
          separatorBottom: true,
          callFunc: () => {
            location.href = 'https://zerofactor.ru/';
          },
        },
        {
          name: 'Документация',
          icon: '/icons/system/help_round.svg',
          callFunc: () => {
            location.href = 'https://docs.zerofactor.ru/';
          },
        },
        {
          name: 'Выход',
          separatorTop: true,
          icon: '/icons/system/sign-out.svg',
          callFunc: () => {
            logoutUser(() => {
              window.location.href = '/login/?next=' + this.$route.fullPath;
            });
          },
        },
      ];
    },
  },

  props: ['user_info'],
  created() {
    this.menuItems.map((menuItem, idx) => {
      menuItem.id = idx;
    });
    document.addEventListener(
        'click',
        this.clickOutHandler,
    );
  },
  methods: {
    select_elem(id) {
      this.dropped = false;
      this.menuItems[id].callFunc();
    },
    to_exit() {
      document.cookie = 'sessionKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/';
      document.cookie = 'sessionKey= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=' + window.location.pathname;
      location.reload();
    },
    clickOutHandler(event) {
      let container = this.$refs.profile_frame;
      if (container && !container.contains(event.target)) {
        this.dropped = false;
      } else {
        container = this.$refs.dropdown;
        if (!container || !container.contains(event.target)) {
          this.dropped = !this.dropped;
        }
      }
    },
  },
  beforeUnmount() {
    document.removeEventListener(
        'click',
        this.clickOutHandler,
    );
  },
};

</script>

<style lang="less">
// @profile__username__text__color: @dark;
// @profile-frame__preview__text__color: @dark;
// @profile-frame__preview__icon__color: @light-gray;

.profile-frame {
  .flex(column, flex-start, flex-start);

  &__preview {
    .flex(row, space-between, center);
    position: relative;
    // color: @profile-frame__preview__text__color;
    user-select: none;
    // .set_icon_color(@profile-frame__preview__icon__color);

    &:hover {
      cursor: pointer
    }
  }

  &__username {
    margin-left: 5px;
    color: @over-transparency-text-color;
    // text-shadow: black 0px 0px 7px;
  }

  &__user-menu {
    width: 100%;
    overflow: visible;
    position: relative;
  }

  &__icon {
    margin-left: 10px;
    transition: transform 0.2s;

    &-opened {
      transform: rotate(-180deg);
    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  .profile-frame {
    &__username {
      display: none
    }

    &__icon {
      display: none
    }
  }
}
</style>
