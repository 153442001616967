<!-- eslint-disable no-irregular-whitespace -->
<template>
    <div ref="root" class="custom-date-input flex-row">
      <input type="number" class="ruler_input" ref="ruler_input" @input="this.changeInput" @keydown="this.keyDownInput" @keyup="this.keyUpInput"/>
      <LabelComponent class='date-elem' ref="day" :class="{'bg-select': this.editMode == 'day'}" :label_text="this.dayLabel" @click="this.selectPart('day')" />
      <LabelComponent label_text=" . " />
      <LabelComponent class='date-elem' ref="month" :class="{'bg-select': this.editMode == 'month'}" :label_text="this.monthLabel" @click="this.selectPart('month')" />
      <LabelComponent label_text=" . " />
      <LabelComponent class='date-elem' ref="year" :class="{'bg-select': this.editMode == 'year'}" :label_text="this.yearLabel" @click="this.selectPart('year')" />
    </div>
</template>

<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';

export default {
  components: {
    LabelComponent,
  },
  emits: ['dateChange', 'focusChange', 'elementChange'],
  data: () => ({
    editMode: null,
    curInputValue: undefined,
    day: null,
    month: null,
    year: null,
    upDownHolding: false,
    rootHeight: 0,
  }),
  watch: {
    curInputValue() {
      if (this.$refs.ruler_input == null) return;
      this.$refs.ruler_input.value = this.curInputValue == null ? '' : this.curInputValue;

      if (this.editMode == null || this.curInputValue === undefined) return;

      this[this.editMode + 'Normaliser'](
        this.curInputValue == null || this.curInputValue == '' ? null : parseInt(this.curInputValue),
        this.curInputValue == null ? 0 : this.curInputValue.length,
      );
    },
    editMode() {
      if (this.editMode == null) {
        this.upDownHolding = false;

        if (this.extYear != null) this.year = this.extYear;
        if (this.extMonth != null) this.month = this.extMonth;
        if (this.extDay != null) this.day = this.extDay;
      }
    },
    day() {
      this.callNewData();
    },
    month() {
      if (this.day != null && this.day != 0) this.day = Math.min(this.maxDay, this.day);
      this.callNewData();
    },
    year() {
      if (this.day != null && this.day != 0) this.day = Math.min(this.maxDay, this.day);
      this.callNewData();
    },
    base_day() {
      this.day = this.base_day;
      // this.day = this.extDay;
    },
    base_month() {
      this.month = this.extMonth;
    },
    base_year() {
      this.year = this.extYear;
    },
  },
  props: {
    base_day: {
      default: null,
      required: false,
    },
    base_month: {
      default: null,
      required: false,
    },
    base_year: {
      default: null,
      required: false,
    },
  },
  created() {
    this.day = this.extDay;
    this.month = this.extMonth;
    this.year = this.extYear;

    document.addEventListener('click', this.checkFocusOut);
    window.escapeableElements.push(this.escKey);
  },
  mounted() {
    this.recalculateRootHeight();
  },
  computed: {
    extDay() {
      return this.base_day == null ? null : Math.max(1, Math.min(this.maxDay, this.base_day));
    },
    extMonth() {
      return this.base_month == null ? null : Math.max(1, Math.min(12, this.base_month));
    },
    extYear() {
      return this.base_year == null ? null : this.base_year;
    },
    isBadState() {
      return (
        this.day == null ||
        this.day == 0 ||
        this.month == null ||
        this.month == 0 ||
        this.year == null
      );
    },
    dayLabel() {
      if (this.day == null) return 'дд';
      return String(this.day).padStart(2, '0');
    },
    monthLabel() {
      if (this.month == null) return 'мм';
      return String(this.month).padStart(2, '0');
    },
    yearLabel() {
      if (this.year == null) return 'гггг';
      return String(this.year).padStart(4, '0');
    },
    maxDay() {
      if (this.month == null || this.month == 0) return 31;
      const year = this.year == null || this.year == 0 ? 1 : this.year;
      const ans = new Date(year, this.month, 0).getDate();
      return ans;
    },
  },
  unmounted() {
    document.removeEventListener('click', this.checkFocusOut);

    const index = window.escapeableElements.indexOf(this.escKey);
    if (index > -1) {
      window.escapeableElements.splice(index, 1);
    }
  },
  methods: {
    callNewData() {
      this.$emit('elementChange', {
        day: this.day == 0 ? null : this.day,
        month: this.month == 0 ? null : this.month,
        year: this.year,
      });

      if (this.isBadState) {
        this.$emit('dateChange', null);
        return;
      }

      this.$emit('dateChange', {
        day: this.day,
        month: this.month,
        year: this.year,
      });
    },
    dropdownSelect(e, final=false) {
      this.day = e.day;
      this.month = e.month;
      this.year = e.year;

      if (final) {
        this.escKey();
      }
    },
    recalculateRootHeight() {
      if (this.$refs.root == null) return;
      this.rootHeight = this.$refs.root.getBoundingClientRect().height;
    },
    keyDownInput(event) {
      if (this.editMode == null) return;

      const key = event.keyCode || event.charCode;

      if (key == 37 || key == 39 || key == 46 || key == 8 ||
      event.key == 'ArrowLeft' || event.key == 'ArrowRight' || event.key == 'Delete' || event.key == 'Backspace') {
        event.preventDefault();
        if (key == 46 || event.key == 'Delete' || key == 8 || event.key == 'Backspace') this.delKey();
        if (key == 37 || event.key == 'ArrowLeft') this.leftKey();
        if (key == 39 || event.key == 'ArrowRight') this.rightKey();
      }

      if (key == 38 || key == 40 || event.key == 'ArrowUp' || event.key == 'ArrowDown') {
        this.curInputValue = this[this.editMode];
        this.upDownHolding = true;
      }
    },
    keyUpInput(event) {
      if (this.editMode == null) return;

      const key = event.keyCode || event.charCode;

      if (key == 38 || key == 40) {
        this.upDownHolding = false;
      }
    },
    delKey() {
      this.curInputValue = null;
    },
    leftKey() {
      if (this.editMode == 'month') this.selectPart('day');
      if (this.editMode == 'year') this.selectPart('month');
    },
    rightKey() {
      if (this.editMode == 'month') this.selectPart('year');
      if (this.editMode == 'day') this.selectPart('month');
    },
    escKey() {
      this.selectPart(null);
      // this.droppedCalendar = false;
    },
    changeInput(event) {
      this.curInputValue = String(event.target.value).replace(/\D/g, '');
      this.$refs.ruler_input.value = this.curInputValue;
    },
    checkFocusOut(event) {
      if (this.editMode == null /* && !this.droppedCalendar */) return;

      // закрытие режима редактирования
      const rootElem = this.$refs.root;
      if (rootElem != null && !rootElem.contains(event.target) && document.body.contains(event.target)) {
        this.selectPart(null);
      }
    },
    selectPart(partName) {
      this.editMode = partName;

      if (this.editMode != null) {
        this.curInputValue = undefined;
        this.$refs.ruler_input.focus();
        this.$emit('focusChange', true);
      } else {
        this.curInputValue = undefined;
        this.$emit('focusChange', false);
      }
    },
    dayNormaliser(newVal, newValLen) {
      if (newVal != null) {
        newVal = this.upDownHolding && newVal == 0 ? this.maxDay : newVal;
        newVal = newVal % (this.maxDay + 1);
        if (this.upDownHolding && newVal == 0) newVal = 1;
        this.curInputValue = String(newVal);
      }
      this.day = newVal;

      if (!this.upDownHolding && this.day != null && (newVal * 10 > this.maxDay || newValLen > 1)) this.selectPart('month');
    },
    monthNormaliser(newVal, newValLen) {
      if (newVal != null) {
        newVal = this.upDownHolding && newVal == 0 ? 12 : newVal;
        newVal = newVal % 13;
        if (this.upDownHolding && newVal == 0) newVal = 1;
        this.curInputValue = String(newVal);
      }
      this.month = newVal;

      if (!this.upDownHolding && this.month != null && (newVal * 10 > 12 || newValLen > 1)) this.selectPart('year');
    },
    yearNormaliser(newVal, newValLen) {
      this.year = newVal;
    },
  },
};
</script>

<style lang="less">
.custom-date-input {
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: fit-content;

  .ruler_input {
    width: 0;
    border: 0;
  }

  & > * {
    margin-right: 0 !important;
  }

  .bg-select {
    background: rgb(0, 120, 215);
  }

  .date-elem {
    cursor: text;
  }

  .date-picker-wrapper {
    position: relative;
  }

  .date-pick-area {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
