import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/subscription/set_user_group_list/?user_id=';

function setNewUserGroups(handler, userId, groups) {
  const form = new FormData();
  groups.forEach((group) => form.append('selected_groups', group.uid));

  fetch(ROOT_URL + REQUEST_LINK + userId, {
    method: 'POST',
    credentials: 'include',
    body: form,
  }).then(async function(response) {
    if (response.status == 200) {
      handler('ok');
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка назначения групп пользователю. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default setNewUserGroups;
