<template>
  <DropDownInput
                  :isFilterable="true"
                  :isMultiSelect="this.isMultiSelect"
                  :isRequired="false"

                  :itemList="this.paginator"
                  :selectedItems="this.selectedItems"

                  :labelText="this.labelText"
                  :fieldStatus="this.inputFieldStatus"
                  :errorText="this.inputErrorText"
                  :favoritableIdx="this.favoritableIdx"

                  @inputChange="(data) => this.$emit('inputChange', data)"
  />
</template>

<script>
import DropDownInput from '@/units/forms/DropDownInput.vue';

import getObjectList from '@/assets/dataSources/admin/getObjectList';

export default {
  components: {
    DropDownInput,
  },
  data: () => ({
    cachedValues: null,
  }),
  emits: {
    inputChange: null,
  },
  props: {
    isMultiSelect: {
      type: Boolean,
      default: false,
    }, // Возможность выбрать несколько элементов
    labelText: { // Заголовок
      type: String,
      default: 'Заголовок по умолчанию',
    },
    selectedItems: { // Изначально выбранные объекты
      required: false,
      default: [],
    },
    objectType: {
      type: String,
      default: null,
    },
    objectTypeView: {
      type: String,
      default: null,
    },
    fieldName: {
      type: String,
      default: null,
    },
    inputFieldStatus: {
      validator: (prop) => ['error', 'disabled'].includes(prop),
      required: false,
    },
    inputErrorText: {default: null, type: String, required: false},
    favoritableIdx: {
      type: String,
      default: null,
    },
    dsUid: {
      default: null,
    },
  },
  methods: {
    async paginator(pageNum, pageSize, searchViews, searchIds) {
      if (this.cachedValues == null) await this.updateCache();

      if (this.cachedValues == null) return [];

      const filteredList = this.cachedValues.filter(
          (elem) => (
            (searchViews == null && searchIds == null) ||
            (searchViews == null ? false : searchViews.filter((searchElem) => elem.view.toUpperCase().includes(searchElem.toUpperCase())).length > 0) ||
            (searchIds == null ? false : searchIds.filter((searchElem) => elem.uid == searchElem).length > 0)
          ),
      ).sort((a, b) => {
        if (searchViews != null) {
          const isAstarts = searchViews.filter((startView) => a.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;
          const isBstarts = searchViews.filter((startView) => b.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;

          if (isAstarts && !isBstarts) return -1;
          if (!isAstarts && isBstarts) return 1;
        }

        if (a.view < b.view) return -1;
        if (a.view > b.view) return 1;
        return 0;
      });

      return filteredList.slice(pageNum * pageSize, pageNum * pageSize + pageSize);
    },
    updateCache() {
      const curPage = this.$store.state.current_page_info;
      const requestName = (curPage && curPage.page_type == 'report') ? this.fieldName : this.objectType;

      const won = this.fieldName;

      return new Promise((resolver) => {
        getObjectList(this.dsUid, requestName, (data) => {
          resolver();
          if (this.fieldName != won) return;
          if (data._error) return;

          this.cachedValues = data;
        });
      });
    },
  },
};
</script>
