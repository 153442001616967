import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/subscription/set_politic/?set_is_deny=';

function setPolitic(isDeny, handler) {
  isDeny = isDeny ? '1' : '0';

  fetch(ROOT_URL + REQUEST_LINK + isDeny, {
    method: 'GET',
    credentials: 'include',
  }).then(async (response) => {
    if (response.status == 200) {
      handler({
        status: response.status,
        text: 'ok',
      });
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка установки политики. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default setPolitic;
