import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/subscription/add_subscription_group/';

function addNewSubscriptionGroup(handler, form) {
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: form,
  }).then(async function(response) {
    if (response.status == 200) {
      handler('ok');
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка сохранения источника. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default addNewSubscriptionGroup;
