import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';

const REQUEST_LINK = '/user_pages/delete_page/';

function deletePage(pageUid, handler) {
  const form = new FormData();
  form.append('page_uid', pageUid);

  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'POST',
    credentials: 'include',
    body: form,
  }).then(async (response) => {
    if (response.status == 200) {
      handler('ok');
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка работы со страницей. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default deletePage;
