<template>
  <div class="user-list-wraper flex-column">
    <SpinnerComponent v-if="this.isLoading" :width="7"/>
    <template v-else>
      <LabelComponent v-if="this.inviteLink != null"
                      :label_text="'Ссылка для приглашения в подписку: ' + this.inviteLink"
                    />

      <LabelComponent label_text="Подключенные к подписке пользователи:" />

      <div class="user-list content-box" v-for="elem in this.activeUsers" :key="elem">
        <div class="user-card flex-column">
          <!-- {{ elem }} -->
          <LabelComponent
                          :label_text="elem.user_view + (this.selfInfo.uid == elem.user_id ? ' (это вы)' : '')"
                          label_type="h3"
                          />

          <template v-if="this.isSubscriptionEditor && this.selfInfo.uid != elem.user_id">
            <ButtonComponent
              elementType="default"
              iconUrl="/icons/system/gear.svg"
              :label="(elem.is_owner ? 'Отозвать' : 'Выдать') + ' полномочия владельца'"
              @click="this.setIsOwner(elem.user_id, !elem.is_owner)"
            />

            <ButtonComponent
              elementType="default"
              iconUrl="/icons/system/trash.svg"
              elementStatusModifier="danger"
              label="Отключить от подписки"
              @click="this.disconnectUser(elem.user_id)"
            />
          </template>

          <DropDownInput v-if="this.isSubscriptionEditor"
                         :isMultiSelect="true"
                         :itemList="this.groupListPaginator"
                         :selectedItems="elem.groups"
                         labelText="Группы пользователя"
                         :isRequired="false"
                         :isFilterable="true"
                         favoritableIdx="sub_groups"
                         @inputChange="(range) => this.newUserGroups(elem, range)"/>
        </div>
      </div>

      <template v-if="this.isSubscriptionEditor">
        <LabelComponent label_text="Пользователи, подавшие заявку на подключение:" />

        <div class="user-list" v-for="elem in this.inactiveUsers" :key="elem">
          <div class="user-card">
            <LabelComponent
                          :label_text="elem.user_view + (this.selfInfo.uid == elem.user_id ? ' (это вы)' : '')"
                          label_type="h3"
                          />

            <ButtonComponent v-if="this.connectionUserPrice != null"
              elementType="default"
              iconUrl="/icons/system/gear.svg"
              :label="'Включить в подписку' + (this.connectionUserPrice != 0 ? ` (цена: ${(this.connectionUserPrice / 100).toFixed(2)} монет)` : '')"
              @click="this.connectUser(elem.user_id)"
            />
            <ButtonComponent
              elementType="default"
              iconUrl="/icons/system/trash.svg"
              elementStatusModifier="danger"
              label="Скрыть"
              @click="this.hideUser(elem.user_id)"
            />
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';
import LabelComponent from '@/units/RichLabel.vue';
import SpinnerComponent from '@/units/logo/StdSpinner.vue';

import connectUserPrice from '@/assets/dataSources/subscription/connectUserPrice.js';

import getConnectedUsers from '@/assets/dataSources/subscription/getConnectedUsers.js';

import subUserConnector from '@/assets/dataSources/subscription/subUserConnector.js';

import disconnectUser from '@/assets/dataSources/subscription/disconnectUser.js';
import hideUser from '@/assets/dataSources/subscription/hideUser.js';
import setUserOwner from '@/assets/dataSources/subscription/setUserOwner.js';

import DropDownInput from '@/units/forms/DropDownInput';

import getSubscriptionGroups from '@/assets/dataSources/subscription/getSubscriptionGroups.js';
import setNewUserGroups from '@/assets/dataSources/subscription/setNewUserGroups.js';


export default ({
  components: {
    ButtonComponent,
    LabelComponent,
    SpinnerComponent,
    DropDownInput,
  },
  data: ()=> ({
    isLoading: true,
    userList: [],
    connectionUserPrice: null,
    cachedGroupList: null,
  }),
  props: {
    subscriptionCode: {
      default: null,
      required: false,
    },
    isSubscriptionEditor: {
      default: null,
      required: false,
    },
  },
  watch: {
    selfInfo() {
      if (!this.isSubscriptionEditor) return;

      this.updateAddUserPrice();
    },
  },
  mounted() {
    this.reloadConnectedUsers();

    if (this.isSubscriptionEditor) {
      this.updateAddUserPrice();
    }
  },
  computed: {
    inviteLink() {
      if (this.subscriptionCode == null) return null;

      const curUrl = new URL(window.location.href);
      return `${curUrl.origin}/connect_subscription/${this.subscriptionCode}/`;
    },
    activeUsers() {
      return this.userList.filter((elem) => elem.is_active == true);
    },
    inactiveUsers() {
      return this.userList.filter((elem) => elem.is_active == false);
    },
    selfInfo() {
      return this.$store.state.user_obj;
    },
  },
  methods: {
    newUserGroups(user, groups) {
      setNewUserGroups((result) => {}, user.user_id, groups);
    },
    async groupListPaginator(pageNum, pageSize, searchViews, searchIds) {
      if (this.cachedGroupList == null) await this.updateGroupListCache();

      if (this.cachedGroupList == null) return [];

      const filteredList = this.cachedGroupList.filter(
          (elem) => (
            (searchViews == null && searchIds == null) ||
            (searchViews == null ? false : searchViews.filter((searchElem) => elem.view.toUpperCase().includes(searchElem.toUpperCase())).length > 0) ||
            (searchIds == null ? false : searchIds.filter((searchElem) => elem.uid == searchElem).length > 0)
          ),
      ).sort((a, b) => {
        if (searchViews != null) {
          const isAstarts = searchViews.filter((startView) => a.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;
          const isBstarts = searchViews.filter((startView) => b.view.toUpperCase().startsWith(startView.toUpperCase())).length > 0;

          if (isAstarts && !isBstarts) return -1;
          if (!isAstarts && isBstarts) return 1;
        }

        if (a.view < b.view) return -1;
        if (a.view > b.view) return 1;
        return 0;
      });

      return filteredList.slice(pageNum * pageSize, pageNum * pageSize + pageSize);
    },
    updateGroupListCache() {
      return new Promise((resolver) => {
        getSubscriptionGroups((data) => {
          resolver();
          if (data._error) return;

          this.cachedGroupList = data;
        });
      });
    },
    reloadConnectedUsers() {
      this.isLoading = true;
      this.userList = [];
      getConnectedUsers((usersData) => {
        this.isLoading = false;
        if (usersData === false) {
          this.userList = [];
          return;
        }

        this.userList = usersData;
      });
    },
    updateAddUserPrice() {
      connectUserPrice((priceData) => {
        if (priceData._error) return;

        this.connectionUserPrice = priceData.amount;
      });
    },
    connectUser(userId) {
      subUserConnector(userId, (connectResult) => {
        if (connectResult._error) {
          if (connectResult.status == 403) {
            window.openAcceptWindow('На подписке недостаточно монет для добавления пользователя!', 'Ошибка');
            return;
          }

          window.openAcceptWindow(connectResult.text, 'Ошибка');
          return;
        }

        window.openAcceptWindow('Пользователь успешно подключен к подписке!');

        this.reloadConnectedUsers();
      });
    },
    disconnectUser(userId) {
      disconnectUser(userId, (connectResult) => {
        if (connectResult._error) {
          window.openAcceptWindow(connectResult.text, 'Ошибка');
          return;
        }

        window.openAcceptWindow('Пользователь успешно отключен от подписки!');
        this.reloadConnectedUsers();
      });
    },
    hideUser(userId) {
      hideUser(userId, (connectResult) => {
        if (connectResult._error) {
          window.openAcceptWindow(connectResult.text, 'Ошибка');
          return;
        }

        window.openAcceptWindow('Пользователь успешно скрыт!');
        this.reloadConnectedUsers();
      });
    },
    setIsOwner(userId, isOwner) {
      setUserOwner(userId, isOwner, (connectResult) => {
        if (connectResult._error) {
          window.openAcceptWindow(connectResult.text, 'Ошибка');
          return;
        }

        window.openAcceptWindow('Права обновлены успешно!');
        this.reloadConnectedUsers();
      });
    },
  },
});
</script>
