import {ROOT_URL, MAX_ERROR_TEXT_LEN} from '@/store';
// import store from '@/store';

const REQUEST_LINK = '/iapi_report/build_preview_report/?uid=';

function buildPreviewReport(reportUid, sourceInfo, formData, handler) {
  // store.commit('set_full_screen_loading', true);

  // const formData = new FormData();
  // Object.keys(filters).forEach((key) => formData.append(key, filters[key]));

  fetch(ROOT_URL + REQUEST_LINK + reportUid + '&source=' + sourceInfo.uid, {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(formData),
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.json());
      return;
    }

    if (response.status == 401) {
      const dsName = await response.text();

      const authResult = await window.openExtAuthWindow(sourceInfo.uid, dsName);
      if (authResult == 'ok') {
        buildPreviewReport(reportUid, sourceInfo, formData, handler);
        return;
      }

      handler({
        _error: true,
        status: response.status,
        text: 'Ошибка доступа к источнику. Неверный логин или пароль.',
      });
      return;
    }

    handler({
      _error: true,
      status: response.status,
      text: `Ошибка формирования отчета. ${(await response.text()).trimLen(MAX_ERROR_TEXT_LEN)}`,
    });
  }).catch((errData) => {
    console.log('errData', errData);
    handler({
      _error: true,
      status: null,
      text: 'Ошибка подключения к серверу',
    });
  });
}

export default buildPreviewReport;
