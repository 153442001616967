<template>
  <component :is="this.get_root_element_type" class="button-root"
             :to="{path: this.href}"
             :href="this.href"
             :target="(this.get_root_element_type === 'a') ? '_blank' : undefined">

    <div :class="get_classes"
         @click="this.click_button">

      <IconComponent class="icon" v-if="iconUrl && !elementInLoading"
                     :class="this.iconClass"
                     :type="this.iconType"
                     :size="this.iconSize"
                     :url="this.iconUrl"
                     :color="this.iconColor"
                     :style="{
                       margin: iconMargin,
                       transform: `rotate(${iconRotate}deg)`
                     }" />
      <IconComponent class="icon, icon-loading" v-if="elementInLoading"
                     :size="iconSize"
                     url="/icons/system/loading.svg"
                     :style="`margin:${iconMargin}`" />

      <LabelComponent class="button-label"
                      v-show="elementStatus!=='button-loading' && label"
                      :label_type="get_label_type"
                      :label_text="label"/>
    </div>

  </component>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import IconComponent from '@/units/RichIcon.vue';


export default ({
  components: {
    IconComponent,
    LabelComponent,
  },
  computed: {
    get_root_element_type() {
      if (!this.href) return 'div';
      else if (this.href[0] === '/') return 'routerLink';
      else return 'a';
    },
    get_classes() {
      const elemBase = 'button';
      const classes = [
        `${elemBase}`,
        `${elemBase}-type-${this.elementType}`,
        `${elemBase}-size-${this.elementSize}`,
        `${elemBase}-status-${this.elementStatus}`,
      ];

      if (this.iconUrl != null) {
        classes.push(`${elemBase}-icon-position-${this.iconPosition}`);
      }

      if (this.elementStatusModifier !== undefined) {
        classes.push(`${elemBase}-status-modifier-${this.elementStatusModifier}`);
      }

      return classes;
    },
    get_label_type() {
      if (this.labelType !== undefined) return this.labelType;

      let labelType = '';
      switch (this.elementSize) {
        case 'big':
          labelType = 'caption/big/bold 16';
          break;
        case 'regular':
          labelType = 'caption/small/regular 10';
          break;
        case 'medium':
          labelType = 'caption/small/medium 10';
          break;
        case 'default':
        default:
          labelType = 'button/small/medium 12';
          break;
      }

      return labelType;
    },
  },
  emits: {'click_to_button': null},
  props: {
    label: {
      default: undefined,
    },
    elementType: {
      default: 'default-bg',
      validator: (prop) => ['default', 'default-bg', 'hover', 'hover-bg', 'hover-bg-additional'].includes(prop),
    },
    elementSize: {
      default: 'default',
      validator: (prop) => ['big', 'regular', 'medium', 'default'].includes(prop),
    },
    elementStatus: {
      default: 'enabled',
      validator: (prop) => ['enabled', 'selected'].includes(prop),
    },
    elementStatusModifier: {
      default: undefined,
      validator: (prop) => ['disabled', 'danger'].includes(prop),
    },
    elementInLoading: {
      default: false,
      validator: (prop) => [true, false].includes(prop),
    },
    iconMargin: {default: '3px'},
    iconPosition: {
      default: 'left',
      validator: (prop) => ['top', 'right', 'bottom', 'left'].includes(prop),
    },
    iconSize: {
      default: '16',
      type: String,
    },
    iconType: {default: 'svg'},
    iconUrl: {default: undefined},
    iconRotate: {default: 0},
    iconColor: {default: undefined},
    iconClass: {default: undefined},
    labelType: {default: undefined},
    href: {required: false},
  },
  methods: {
    click_button() {
      this.$emit('click_to_button');
    },
  },
});
</script>

<style lang="less">
@button__default__icon__color: @white;

// default-type
@button__default-type__elements__color: @green;
@button__default-type__elements__hover__color: @hover-green;

@button__default-type__selected__elements__color: @dark;
@button__default-type__selected__elements__hover__color: @hover-dark;

@button__default-type__disabled__elements__color: @super-light;
@button__default-type__danger__elements__color: @error;
//__________________________

// default-bg-type
@button__default-bg-type__elements__color: @button-front-color;
@button__default-bg-type__background-color: @button-back-color;
@button__default-bg-type__hover__background-color: @button-back-hover-color;

@button__default-bg-type__selected__background-color: @dark;
@button__default-bg-type__selected__hover__background-color: @hover-dark;

@button__default-bg-type__disabled__background-color: @super-light;
@button__default-bg-type__danger__background-color: @error;
//__________________________

// hover-type
@button__hover-type__elements__color: @no-accent-text-color;
@button__hover-type__elements__hover__color: @gray;

@button__hover-type__selected__elements__color: @green;
@button__hover-type__selected__elements__hover__color: @hover-green;
//__________________________

// hover-bg-type
@button__hover-bg-type__elements__color: @gray;
@button__hover-bg-type__border__color: @super-light;
@button__hover-bg-type__border__hover__color: @gray;

@button__hover-bg-type__selected__elements__color: @green;
@button__hover-bg-type__selected__background-color: @mint;
@button__hover-bg-type__border__color: @mint;
@button__hover-bg-type__selected__hover__border-color: @green;
//__________________________

// hover-bg-additional-type
@button__hover-bg-additional-type__elements__color: @gray;
@button__hover-bg-additional-type__border__color: @super-light;
@button__hover-bg-additional-type__border__hover__color: @gray;

@button__hover-bg-additional-type__selected__elements__color: @green;
@button__hover-bg-additional-type__selected__background-color: @white;
@button__hover-bg-additional-type__selected__border__color: @green;
@button__hover-bg-additional-type__selected__hover__border-color: @green;
//__________________________

@button__default__disabled__elements__color: @light-gray;

.button-root {
  position: relative;
  max-width: fit-content;
}

.button {
  //transition-property: background-color, color;
  // иконка и текстовое поле меняют цвет не синхронно
  //transition-duration: 200ms;
  //transition-timing-function: linear;

  &-label {
    text-align: center;
  }

  .flex(row, center, center);
  .set_icon_color(@button__default__icon__color);
  user-select: none;
  pointer-events: none;
  transition: all 200ms;

  &-type {
    &-default {
      &.button-status{
        &-enabled{
          .set_color(@button__default-type__elements__color);
          &:hover { .set_color(@button__default-type__elements__hover__color); }
        }
        &-selected  {
          .set_color(@button__default-type__selected__elements__color);
          &:hover { .set_color(@button__default-type__selected__elements__hover__color); }
        }

        &-modifier {
          &-disabled {
            .set_color(@button__default-type__disabled__elements__color);
            pointer-events: none;
          }
          &-danger {  .set_color(@button__default-type__danger__elements__color); }
        }
      }
    }

    &-default-bg {
      //min-width: 140px;
      min-height: 40px;
      border-radius: 48px;
      padding: 0 20px;
      .set_color(@button__default-bg-type__elements__color);

      &.button-status{
        &-enabled{
          background-color: @button__default-bg-type__background-color;
          &:hover   { background-color: @button__default-bg-type__hover__background-color; }
        }
        &-selected  {
          background-color: @button__default-bg-type__selected__background-color;
          &:hover   { background-color: @button__default-bg-type__selected__hover__background-color; }
        }

        &-modifier {
          &-disabled {
            background-color: @button__default-bg-type__disabled__background-color;
            pointer-events: none;
          }
          &-danger {
            background-color: @button__default-bg-type__danger__background-color;
            &:hover {
              background-color: @hover-error;
            }
          }
        }
      }
    }

    &-hover {
      &.button-status{
        &-enabled{
          .set_color(@button__hover-type__elements__color);

          &:hover   {
            .set_color(@button__hover-type__elements__hover__color);
          }
        }
        &-selected  {
          .set_color(@button__hover-type__selected__elements__color);
          &:hover   {
            .set_color(@button__hover-type__selected__elements__hover__color);
          }
        }
      }
    }

    &-hover-bg {
      //min-width: 121px;
      min-height: 40px;
      border-radius: 38px;
      padding: 0 15px;

      &.button-status{
        &-enabled{
          .set_color(@button__hover-bg-type__elements__color);
          border: 1px solid @button__hover-bg-type__border__color;
          &:hover   { border-color: @button__hover-bg-type__border__hover__color}
        }
        &-selected  {
          background-color: @button__hover-bg-type__selected__background-color;
          .set_color(@button__hover-bg-type__selected__elements__color);
          border: 1px solid @button__hover-bg-type__border__color;
          &:hover   { border-color: @button__hover-bg-type__selected__hover__border-color}
          }
      }
    }

    &-hover-bg-additional {
      //min-width: 121px;
      min-height: 40px;
      border-radius: 38px;
      padding: 0 15px;

      &.button-status{
        &-enabled{
          .set_color(@button__hover-bg-additional-type__elements__color);
          border: 1px solid @button__hover-bg-additional-type__border__color;
          &:hover   { border-color: @button__hover-bg-additional-type__border__hover__color}
        }
        &-selected  {
          background-color: @button__hover-bg-additional-type__selected__background-color;
          .set_color(@button__hover-bg-additional-type__selected__elements__color);
          border: 1px solid @button__hover-bg-additional-type__selected__border__color;
          &:hover   { border-color: @button__hover-bg-additional-type__selected__hover__border-color}
        }
      }
    }
  }

  &-status{
    &-enabled,
    &-selected{
      pointer-events: auto;
      &:hover { cursor: pointer; }
    }
    &-disabled {
      .set_color(@button__default__disabled__elements__color);
    }
  }

  &-icon-position{
    &-top, &-bottom { .flex(column, center, center); }
    &-right   { flex-direction: row-reverse; }
    &-bottom  { flex-direction: column-reverse; }
  }

  .icon-loading {
    animation: rotate 2s linear infinite;
  }
}


</style>
