<template>
  <template v-for="window, uid in this.openedWindows" :key="uid">
    <!-- <Teleport to="#content-modal" v-if="window.type == 'bottom'"> -->
      <BaseBottomWindow v-if="window.type == 'bottom'" :params="window"/>
    <!-- </Teleport> -->
    <BaseWindow :params="window" v-else/>
  </template>
</template>

<script>
import BaseWindow from './BaseWindow.vue';
import BaseBottomWindow from './BaseBottomWindow.vue';
import {markRaw} from '@vue/reactivity';

import ExtAuth from '@/baseLayout/auth/ExtAuth.vue';
import AcceptWindow from './AcceptWindow.vue';
import YNWindow from './YNWindow.vue';

export default {
  mounted() {
    window.openWindow = this.openWindow;
    window.openExtAuthWindow = this.openExtAuthWindow;
    window.openAcceptWindow = this.openAcceptWindow;
    window.openYNWindow = this.openYNWindow;
  },
  data: () => ({
    openedWindows: {},
    winCounter: 0,
  }),
  watch: {
    // $route(to, from) {

    // },
  },
  computed: {

  },
  components: {
    BaseWindow,
    BaseBottomWindow,
  },
  methods: {
    async openWindow(winData) {
      winData.component = markRaw(winData.component);

      this.winCounter += 1;
      const winNumber = this.winCounter;

      const winResult = await (new Promise((resolver) => {
        winData.winHandler = resolver;
        this.openedWindows[winNumber] = winData;
      }));

      delete this.openedWindows[winNumber];

      return winResult;
    },
    openExtAuthWindow(dsUid, dsView) {
      return window.openWindow({
        caption: `Авторизация в источнике: ${dsView}`,
        component: ExtAuth,
        componentProps: {
          extSystemView: dsView,
          extSystemUid: dsUid,
        },
      });
    },
    openAcceptWindow(acceptText, caption, buttonText) {
      caption = caption == null ? 'Успех!' : caption;
      buttonText = buttonText == null ? 'Хорошо' : buttonText;
      acceptText = acceptText == null ? 'Действие выполнено!' : acceptText;

      return window.openWindow({
        caption: caption,
        component: AcceptWindow,
        componentProps: {
          text: acceptText,
          acceptButtonText: buttonText,
        },
      });
    },
    openYNWindow(ynText, caption, yesText, noText, cancelText) {
      caption = caption == null ? 'Вы уверены?' : caption;
      ynText = ynText == null ? 'Вы подтверждаете действие?' : ynText;
      yesText = yesText == null ? 'Да' : yesText;
      noText = noText == null ? 'Нет' : noText;
      cancelText = cancelText == null ? 'Отмена' : cancelText;

      return window.openWindow({
        caption: caption,
        component: YNWindow,
        componentProps: {
          text: ynText,
          yesButtonText: yesText,
          noButtonText: noText,
          cancelButtonText: cancelText,
        },
      });
    },
  },
};

</script>
