<template>
  <div class="manual-date-range flex-column">
    <ButtonComponent class="submit-button"
                     :label="'Применить'"
                     @click_to_button="this.$emit('result', this.curValue)"/>

    <DateTimeInputField
                        title="Начало"
                        :set_values="this.curValue.from"
                        @input_change="(val) => { this.newDate(val, true); }"
                        />
    <DateTimeInputField
                        title="Конец"
                        :set_values="this.curValue.to"
                        @input_change="(val) => { this.newDate(val, false); }"
                        />
  </div>
</template>

<script>
import DateTimeInputField from '@/units/InputFields/DateTimeInputField.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    ButtonComponent,
    DateTimeInputField,
  },
  data: () => ({
    curValue: {},
  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  watch: {
    baseSelectorPeriod() {
      this.initValue();
    },
  },
  mounted() {
    this.initValue();
  },
  methods: {
    newDate(val, isFrom) {
      if (isFrom) {
        this.curValue.from = val;
      } else {
        this.curValue.to = val;
      }
    },
    initValue() {
      if (this.baseSelectorPeriod == null) {
        this.curValue = {};
        return;
      }

      this.curValue = {
        from: this.baseSelectorPeriod.from,
        to: this.baseSelectorPeriod.to,
      };
    },
  },
  computed: {
    baseSelectorPeriod() {
      return this.params.initSelectorPeriod;
    },
  },
};
</script>
