<template>
  <NavElemNode :levelData="[{'name': '<Корень>', 'page_type': 'group'}]"
               :currentDepth="0"
               @select_elem="this.selectElem"
  />

  <NavElemNode :levelData="this.getPagesStruct"
               :currentDepth="0"
               :exclude_uid_list="this.exclude_uid_list"
               @select_elem="this.selectElem"
  />
</template>

<script type="text/javascript">
import NavElemNode from './NavElemNode.vue';

export default {
  components: {
    NavElemNode,
  },
  data: () => ({

  }),
  emits: ['result'],
  props: {
    params: {
      default: {},
      required: false,
    },
  },
  methods: {
    selectElem(elem) {
      this.$emit('result', elem);
    },
  },
  computed: {
    getPagesStruct() {
      return this.$store.getters.webSitePagesRoot;
    },
    exclude_uid_list() {
      return this.params.exclude_uid_list;
    },
  },
};
</script>
