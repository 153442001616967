<template>
  <div class="found-card" lang="ru">
    <linkTo
            :href="this.cardLink" class="found-card-content"
            :class="`found-card-type-${this.typeClass}`">
      <div class="found-card-header">
        <LabelComponent
                        class="card-type"
                        label_type="button/large/medium 14"
                        :label_text="this.typeName"
                        :style="this.cardData.type == 'error' ? 'color: red;' : null"
                      />
        <LabelComponent class="card-ds-name" label_type="button/large/medium 16" :label_text="this.dataSourceName"/>
      </div>
      <LabelComponent class="card-main-name" :label_text="this.mainView"/>
    </linkTo>
  </div>
</template>


<script type="text/javascript">
import LabelComponent from '@/units/RichLabel.vue';
// import IconComponent from '@/units/RichIcon.vue';
// import builder from '@/assets/v1/js/builder';
import linkTo from '@/units/linkTo.vue';

export default {
  name: 'StructNode',
  components: {
    LabelComponent,
    // IconComponent,
    linkTo,
  },
  data: ()=> ({

  }),
  mounted() {

  },
  watch: {

  },
  props: {
    cardData: null,
  },
  computed: {
    cardLink() {
      if (this.cardData.type == 'report') {
        return `/report_preview/${this.cardData.dsUid}/${this.cardData.uid}`;
      }
      if (this.cardData.type == 'entity') {
        return `/entity_preview/${this.cardData.dsUid}/${this.cardData.uid}`;
      }
      if (this.cardData.type == 'storage') {
        return `/storage_preview/${this.cardData.dsUid}/${this.cardData.uid}`;
      }

      return undefined;
    },
    dataSourceName() {
      return this.cardData.dsView;
    },
    typeName() {
      if (this.cardData.type == 'error') {
        return 'Ошибка';
      }
      if (this.cardData.type == 'report') {
        return 'Отчет';
      }
      if (this.cardData.type == 'entity') {
        return 'Объект';
      }
      if (this.cardData.type == 'storage') {
        return 'Хранилище';
      }
      return '?';
    },
    mainView() {
      return this.cardData.view;
    },
    typeClass() {
      if (this.cardData.type == 'error') {
        return 'error';
      }
      if (['report', 'entity', 'storage'].includes(this.cardData.type)) {
        return 'normal';
      }
      return 'unexpected';
    },
  },
  methods: {

  },
};
</script>

<style lang="less">
.found-card{
  // .flex(column, flex-start, flex-start);
  // position: relative;
  padding-top: 7px;

  .found-card-content{
    .flex(column,  space-between, center);
    transition: color 0.2s;
    // background: @content-background-color;
    text-decoration: none;
    color: @no-accent-text-color;

    overflow-wrap: normal !important;
    word-wrap:  break-word !important;
    word-break: normal !important;
    line-break: auto !important;
    -webkit-hyphens: auto !important;
    -ms-hyphens: auto !important;
    hyphens: auto !important;

    padding: 12px 12px;

    width: 100%;
    height: 100%;
    flex-grow: 1;
    border-radius: 10px;
    box-shadow: 0 4px 14px rgba(46, 50, 45, 0.03);
    //margin-top: 10px;

    &:hover{
      color: @light-green;
    }

    & > * {
      width: 100%;
      margin-bottom: 9px;
    }

    & > *:last-child{
      margin-bottom: 0;
    }

    .found-card-header {
      // .flex(row, space-between, start);
    }

    .card-ds-name {
      color: @base-text-color;
    }

    .card-type {
      // color: red;
      text-align: end;
      text-decoration: underline;
    }
  }

  .found-card-type {
    &-error {
      background: linear-gradient(90deg, @error 0 9px, @content-background-color 0);
    }
    &-normal {
      background: linear-gradient(90deg, @green 0 9px, @content-background-color 0);
    }
    &-unexpected {
      background: linear-gradient(90deg, @warning 0 9px, @content-background-color 0);
    }
  }
}
</style>
