const LAST_VERSION_LINK = 'last_version.json';
const CACHED_VERSION_LINK = 'cache_version.json';

async function checkAutoUpdate() {
  if (!window.WORK_IN_APPLICATION) {
    return;
  }

  window.sendMessageInApplication({
    type: 'save_string',
    key: 'APP__JS_RUNNING',
    value: '1',
  });

  let lastVersion = fetch(LAST_VERSION_LINK, {
    method: 'GET',
  }).then(async (result) => (await result.json()).version).catch((err) => console.log('LAST_VERSION_LINK read error', err));

  let cachedVersion = fetch(CACHED_VERSION_LINK, {
    method: 'GET',
  }).then(async (result) => (await result.json()).version).catch((err) => console.log('CACHED_VERSION_LINK read error', err));

  lastVersion = await lastVersion;
  cachedVersion = await cachedVersion;

  if (lastVersion != cachedVersion) {
    window.sendMessageInApplication({
      type: 'clear_cache',
      callback: 'window.applicationCallback.cacheCleared',
    });
  }
}

export default checkAutoUpdate;
