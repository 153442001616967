import {ROOT_URL} from '@/store';

const REQUEST_LINK = '/subscription/get_subscription_users/';

function getConnectedUsers(handler) {
  fetch(ROOT_URL + REQUEST_LINK, {
    method: 'GET',
    credentials: 'include',
  }).then(async function(response) {
    if (response.status == 200) {
      handler(await response.json());
    }
  }).catch((errData) => {

  });
}

export default getConnectedUsers;
