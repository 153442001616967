<template>
  <div id="base_app">
    <div class="left-column"
         :opened="this.leftColumnOpened">

      <div class="left-column__background fixed-full-screen-block"
           @click="this.close_left_column()"/>

      <div class="page-logo-wraper">
        <MainLogo class="page-logo"
                  branch=""
                  colorStyle="white-green"
                  width="300"
                  @click_to_logo="this.toLogoClick"/>
      </div>
      <LeftMenu />
    </div>
    <div class="right-column"
         id="content-modal"
         ref="frameClickMouseRightColumn">
      <div class="top-panel">
        <TopPanel @left-menu="this.open_left_column"/>
      </div>
      <div class="content-panel">
        <BaseContentWrapper
            :name="this.name"
            :is_with_background="this.is_with_background"
            >
        </BaseContentWrapper>
      </div>
      <div class="bottom-panel">
        <BottomPanel/>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import TopPanel from './topPanel/TopPanel';
import LeftMenu from './navigationPanel/LeftMenu';
import BottomPanel from './bottomPanel/BottomPanel.vue';
import BaseContentWrapper from '@/layoutTools/BaseContentWrapper.vue';
import MainLogo from '@/units/logo/MainLogo.vue';

export default {
  components: {
    BaseContentWrapper,
    LeftMenu,
    MainLogo,
    TopPanel,
    BottomPanel,
  },
  watch: {
    $route(to, from) {
      if (String.comparePaths(to.path, from.path)) return;
      this.close_left_column();
    },
  },
  data: ()=>({
    leftColumnOpened: false,
    scroll_position_freeze: undefined,
  }),
  computed: {
  },
  methods: {
    toLogoClick() {
      this.$router.push('/');
    },
    open_left_column() {
      this.leftColumnOpened = true;
    },
    close_left_column() {
      this.leftColumnOpened = false;
    },
  },
  computed: {
    name() {
      return this.ext_page_name == null ? this.$route.meta.viewName : this.ext_page_name;
    },
    is_with_background() {
      return this.$route.meta.witoutBackground != true;
    },
  },
  props: {
    ext_page_name: null,
  },
};

</script>

<style lang="less">

@global__background-color: @background;
@logo__text__color: @white;

.page-logo-wraper {
  width: 100%;
}

#base_app {
  .flex(row, flex-start, flex-start);
  height: 100%;
  z-index: 1;
  overflow: hidden;
  .left-column {
    .flex(column, flex-start, flex-start);
    height: 100%;
    min-width: 260px;
    max-width: 260px;
    z-index: 0;
    background: @mobile-left-column-background;

    &__background{
      backdrop-filter: blur(4px);
      background-color: transparent;
      max-width: 0;
    }
  }
  .right-column{
    .flex(column, flex-start, flex-start);
    width: 100%;
    height: 100%;
    position: relative;
    .top-panel{
      .flex(column, center, center);
      min-height: 60px;
      max-height: 60px;
      width: 100%;
      z-index: 100;
      background: @top-panel-background;
    }
    .content-panel{
      .flex(column, flex-start, flex-start);
      flex-grow: 1;
      width: 100%;
      // width: 100%;
      height: 1px;
      border-radius: 10px;
      padding: 7px;
      // padding-top: 60px;
    }
    .bottom-panel {
      display: none;
    }
  }
}

@media screen and (max-width: @transition-threshold-1) {
  #base_app {

    .left-column {
      position: fixed;
      top: 0;
      overflow: hidden;

      transition: min-width 100ms, max-width 100ms;
      z-index: 150;

      .left-column__background {
        transition: background @animation-speed, background-color @animation-speed;
      }

      .page-logo * {
        color: @base-text-color !important;
      }

      &[opened=true] {
        min-width: 260px;
        max-width: 260px;

        .left-column__background{
          background-color: @fade-content-background-color;
          max-width: 100%;
          z-index: -1;
        }
      }
      &[opened=false] {

        min-width: 0;
        max-width: 0;

        .left-column__background{
          background-color: transparent;
          pointer-events: none;
          // max-width: 0;
        }
      }
    }

    .right-column{
      .bottom-panel {
        display: block;
        width: 100%;
        .flex(row, flex-start, stretch);
      }
    }
  }
}

</style>
