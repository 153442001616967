export default () => {
  window.escapeableElements = [];
  window.acceptableElements = [];
  document.addEventListener('keydown', (e) => {
    const keyCode = e.keyCode || e.charCode;

    // Keycode for "Escape"
    if (keyCode === 27 || e.key == 'Escape') {
      if (window.escapeableElements.length > 0) {
        let counter = 1;

        // console.log('active esc', window.escapeableElements);

        while (
          counter <= window.escapeableElements.length &&
          window.escapeableElements[window.escapeableElements.length - counter]()
        ) {
          counter += 1;
        }
      }
    }
    // Keycode for "Return"
    if (keyCode == 13 || e.key == 'Enter') {
      if (window.acceptableElements.length > 0) {
        let counter = 1;

        while (
          counter <= window.acceptableElements.length &&
          window.acceptableElements[window.acceptableElements.length - counter]()
        ) {
          counter += 1;
        }
      }
    }
  });
};
