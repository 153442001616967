<template>
  <div  class="page-not-found flex-column">
    <div class="page-not-found__caption">
      <div v-if="logoff" class="page-not-found__message">
        Вы не авторизованы
      </div>
      <div class="page-not-found__message">
        Страница {{ this.get_domain }}{{ this.path }} не найдена
      </div>
    </div>
    <div class="page-not-found__buttons flex-row">
      <ButtonComponent
      :label="'Перейти на главную'"
      href="/"
      />
      <ButtonComponent v-if="logoff" :label="'Авторизация'" href="/login"/>
    </div>
  </div>
</template>

<script>
import ButtonComponent from '@/units/forms/RichButton.vue';

export default {
  components: {
    ButtonComponent,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  mounted() {

  },
  computed: {
    logoff() {
      return !this.$store.getters.isLoggedIn;
    },
    get_domain() {
      return window.location.host;
    },
  },
};
</script>
