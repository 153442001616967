<!-- eslint-disable no-irregular-whitespace -->
<!-- eslint-disable vue/no-v-for-template-key -->
<template>
  <AuthBase>
    <form class="register-frame content-box" autocomplete="off">
      <LabelComponent class="register-frame__header"
                      label_type="h1"
                      :label_text="'Регистрация в Системе'"/>
      <div class="register-frame__footer" >
        <LabelComponent label_type="button/large/regular 14"
                        label_text="Уже есть аккаунт?"/>

        <ButtonComponent label="ВОЙТИ"
                        elementSize = "default"
                        elementType="default"
                        @click="this.$router.replace({path: '/login/', query: this.$route.query});"/>
      </div>
      <LabelComponent class="register-frame__global-error"
                      v-show="this.global_error_text != null"
                      :label_text="this.global_error_text"/>
      <LabelComponent class="register-frame__global-success"
                      v-if="this.global_success_text != null"
                      :label_text="this.global_success_text"/>
      <div class="register-frame__fields-wraper" v-else>
        <template v-for="field, fieldName in this.form_data" :key="fieldName">
          <DropDownInput v-if="fieldName == 'country'" class="register-frame__login-field"
                         :itemList="this.ddCountries"
                         :selectedItems="[]"
                         :labelText="field.label"
                         :fieldStatus="this.getFieldStatus(fieldName)"
                         :errorText="field.error"
                         :isRequired="false"
                         @inputChange="(data) => this.setFormValue(fieldName, data[0] ? data[0].uid : null)"
                         />
          <InputField v-else-if="fieldName != 'privacy_policy_agreed' && fieldName != 'children'" class="register-frame__login-field"
                      :label_text="field.label"
                      :input_field_status="this.getFieldStatus(fieldName)"
                      :error_text="field.error"
                      autocomplete="off"
                      @input_change="(data) => this.setFormValue(fieldName, data)"
                      :type="field.itype ? field.itype : 'text'"
                      :check_pattern="field.pattern ? field.pattern : '.+'"
                      :pattern_invalid_message="this.pattern_invalid_message"
                      />
        </template>

        <div class="policy-field register-frame__login-field">
          <LabelComponent label_text="Я согласен с"/>
          <ButtonComponent label="Документами"
                           elementType="default"
                           elementSize = "default"
                           href="https://zerofactor.ru/doc1"/>
          <CheckBox
                    :check_box_field_status="this.getFieldStatus('privacy_policy_agreed')"
                    :error_text="this.form_data['privacy_policy_agreed'].error"
                    @input_check="(data) => this.setFormValue('privacy_policy_agreed', data ? 'on' : undefined)"
                    />
        </div>
      </div>
      <ButtonComponent class="register-frame__button1"
                      v-if="this.global_success_text == null"
                      label_type="button/large/medium 18"
                      :elementInLoading="this.reg_requesting"
                      elementSize="default"
                      @click="this.registerUserInSystem()"
                      label="ЗАРЕГИСТРИРОВАТЬСЯ"/>
    </form>
  </AuthBase>
</template>

<script>
import LabelComponent from '@/units/RichLabel.vue';
import ButtonComponent from '@/units/forms/RichButton.vue';
import InputField from '@/units/forms/InputField';
import DropDownInput from '@/units/forms/DropDownInput';
import CheckBox from '@/units/forms/CheckBox';
import AuthBase from './AuthBase';

import getCountryList from '@/assets/dataSources/auth/getCountryList.js';
import registerRequest from '@/assets/dataSources/auth/registerRequest.js';

export default ({
  components: {
    LabelComponent,
    ButtonComponent,
    InputField,
    DropDownInput,
    AuthBase,
    CheckBox,
  },
  props: {

  },
  data: ()=> ({
    pattern_invalid_message: 'Можно вводить только буквы алфавита',
    reg_requesting: false,
    global_error_text: undefined,
    global_success_text: undefined,
    form_data: {
      last_name: {label: 'Фамилия', error: null, val: null, pattern: '[a-zA-Zа-яА-Я]+', required: true},
      first_name: {label: 'Имя', error: null, val: null, pattern: '[a-zA-Zа-яА-Я]+', required: true},
      country: {label: 'Город', error: null, val: [], required: false},
      email: {label: 'Email', error: null, val: null, itype: 'email', required: true},
      password: {label: 'Пароль', error: null, val: null, itype: 'password', required: true},
      password2: {label: 'Подтверждение пароля', error: null, val: null, itype: 'password', required: true},
      privacy_policy_agreed: {label: null, error: null, val: false, required: true},
    },
    countries: [],
  }),
  mounted() {
    window.acceptableElements.push(this.registerUserInSystem);

    this.updateCountries();
  },
  unmounted() {
    const index = window.acceptableElements.indexOf(this.registerUserInSystem);
    if (index > -1) {
      window.acceptableElements.splice(index, 1);
    }
  },
  computed: {
    ddCountries() {
      return this.countries.map((outVal) => ({
        view: outVal.view,
        uid: outVal.id,
      }));
    },
  },
  methods: {
    updateCountries() {
      getCountryList((newList) => {
        this.countries = newList;
      });
    },
    getFieldStatus(field) {
      return this.form_data[field].error != null ? 'error' : undefined;
    },
    setFormValue(field, value) {
      this.global_error_text = null;
      this.form_data[field].val = value;
      this.form_data[field].error = null;
    },
    registerUserInSystem() {
      if (this.reg_requesting) {
        return;
      }

      let isError = false;
      const fillCorrectText = 'Пожалуйста, заполните все поля корректно.';

      this.global_error_text = null;
      this.global_success_text = null;

      Object.keys(this.form_data).forEach((key) => {
        if (!this.form_data[key].required) return;
        if (this.form_data[key].val == null || this.form_data[key].val.length < 1) {
          this.form_data[key].error = 'Поле должно быть заполнено';
          isError = true;
        }
      });

      if (!this.form_data.privacy_policy_agreed.val) {
        this.form_data.privacy_policy_agreed.error = 'Необходимо согласиться с документами!';
        isError = true;
      }

      if (this.form_data.password.val != this.form_data.password2.val) {
        this.form_data.password.error = 'Введенные пароли не совпадают!';
        this.form_data.password2.error = this.form_data.password.error;
        isError = true;
      }

      if (isError) {
        this.global_error_text = fillCorrectText;
        return;
      }

      this.reg_requesting = true;

      registerRequest((data) => {
        this.reg_requesting = false;
        if (data._error) {
          if (data.status == null) {
            this.global_error_text = `${data.text} Нет подключения к интернету либо на сервере ведутся технические работы.`;
          } else if (data.status == 403) {
            this.global_error_text = `${fillCorrectText} ${data.text} Статус: ${data.status}`;
          } else {
            this.global_error_text = `${fillCorrectText} ${data.text} Статус: ${data.status}`;
          }

          return;
        }

        this.global_success_text = 'Письмо о подтверждении регистрации отправлено вам на почту! Подтвердите регистрацию и Авторизуйтесь для входа в систему.';
      }, {
        email: this.form_data.email.val,
        password: this.form_data.password.val,
        firstName: this.form_data.first_name.val,
        lastName: this.form_data.last_name.val,
        country: this.form_data.country.val,
      });

      // console.log('confirmed', this.form_data);

      // const postForm = new FormData();
      // // postForm.append('_lang', this.$store.state.base_lang);
      // Object.keys(this.form_data).forEach((key) => {
      //   if (this.form_data[key].itype == 'inline') {
      //     if (this.form_data[key].val == null) {
      //       return;
      //     }

      //     postForm.append(key + '-TOTAL_FORMS', this.form_data[key].val.length);
      //     this.form_data[key].val.forEach((elem, idx) => {
      //       Object.keys(elem).forEach((inlineKey) => {
      //         postForm.append(key + '-' + idx + '-' + inlineKey, elem[inlineKey]);
      //       });
      //     });
      //     return;
      //   }
      //   if (Array.isArray(this.form_data[key].val)) {
      //     this.form_data[key].val.forEach((elem) => postForm.append(key, elem));
      //     return;
      //   }
      //   postForm.append(key, this.form_data[key].val);
      // });

      // sendFormData(async (response) => {
      //   if (response.status == 200) {
      //     this.global_success_text = 'You have successfully registered in our Agency! Link to activate your account has been emailed to you.  If you haven’t received a registration confirmation email, you should check your spam.';
      //     return;
      //   }
      //   if (response.status == 400) {
      //     this.global_error_text = fillCorrectText;
      //     const errorPack = await response.json();
      //     Object.keys(errorPack).forEach((erField) => {
      //       this.form_data[erField].error = errorPack[erField].join(' ');
      //     });
      //     return;
      //   }
      // }, 'register', postForm);
    },
  },
});
</script>

<style lang="less">
.register-frame {
  // overflow: hidden;
  .flex(column, flex-start, center);
  // position: absolute;
  min-width: 260px;
  max-width: 390px;
  max-height: 100%;
  //border: 1px solid #000000;
  // border-radius: 15px;
  box-shadow: 0 0px 15px #505050;
  padding: 20px;

  &__header {
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
    font-size: 20px;
  }
  &__global-error {
    color: red;
    text-align: center;
  }
  &__global-success {
    color: green;
    text-align: center;
  }

  // .set_custom_scrollbar(6px, 10px, 0px, 20px, 20px);

  &__fields-wraper {
    height: 100%;
    // width: 330px;
    width: 85%;
    padding-right: 5px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__login-field {
    margin: 11px 0;
  }
  &__button1 {
    width: 75%;
    max-width: none;
    margin-top: 10px;
  }
  &__button2 {
    margin-top: 20px;
    color: hsl(0, 100%, 55%);
  }

  &__footer{
    .flex(row, center, center);
    flex-basis: 35px;
    flex-wrap: wrap;
    gap: 3px;
  }

  .policy-field {
    .flex(row, center, center);
    gap: 3px
  }
  .header_text_registration{
    .flex(row, center, flex-end);
    text-align: center;
    flex-basis: 0.4;
    vertical-align: middle;
    margin-bottom: 10px;
  }
}
</style>
